import { createAction, createReducer } from '@reduxjs/toolkit';
import { getCaseBySlug } from './actions';
import { CaseState } from './interfaces';

export const initialState: CaseState = {
  case: {
    id: null,
    slug: '',
    name: '',
    more: '',
    tags: [],
    img_title: '',
    blocks: [],
    url_to: '',
  },
  casesRandom: [],
};

export const clearCaseDetails = createAction('cases/clearCaseDetails');

export const caseDetailsReducer = createReducer(initialState, (builder) => {
  builder.addCase(clearCaseDetails, (state, action) => {
    return initialState;
  });
  builder.addCase(getCaseBySlug.fulfilled, (state, action) => {
    state.case = action.payload.case;
    state.casesRandom = action.payload.casesRandom;
  });
});
