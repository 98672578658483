import { createReducer } from "@reduxjs/toolkit";
import { reset, requestProject } from "./actions";
import { State } from "./interfaces";

const initialState: State = {
    loading: false,
    isSended: false
}

export const requestProjectReducer = createReducer(initialState, builder => {
    builder
        .addCase(reset, state => {
            state.loading = false
            state.isSended = false
        })
        .addCase(requestProject.pending, state => {
            state.loading = true
        })
        .addCase(requestProject.fulfilled, state => {
            state.isSended = true
            state.loading = false
        })
})