import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { CaseState, GetCaseBySlugResponse } from './interfaces';

export const getCaseBySlug = createAsyncThunk<CaseState, string>(
  'cases/getCaseBySlug',
  async (slug) => {
    const res: GetCaseBySlugResponse = await api.GET(`case/${slug}`);
    return res.data;
  }
);
