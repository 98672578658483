import { PopUp as GlobalPopUp } from "@/components";
import { useBlockScroll } from "@/hooks";
import clsx from "clsx";
import React, { FC, useContext, useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Team as TeamType } from "@/store/home/interfaces";
import arrow from "@/assets/icons/arrowRight1.svg";
import SwiperClass from "swiper/types/swiper-class";
import { Navigation, EffectFade } from "swiper";
import "../../../../node_modules/swiper/modules/effect-fade/effect-fade.min.css";

import close from "@/assets/icons/close.svg";

import styles from "./PopUp.module.scss";
import { RefsContext } from "@/providers";

interface Props {
  opened: boolean;
  initial: number;
  photos: TeamType[];
  onChange: (newValue: number) => void;
  onClose: () => void;
}

export const PopUp: FC<Props> = ({
  opened,
  initial,
  photos,
  onChange,
  onClose,
}) => {
  const isBlockScroll = useBlockScroll();

  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  const slideIndexRef = useRef(initial);

  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    initialSlide: initial,
    slidesPerView: 1,
    modules: [Navigation, EffectFade],
    navigation: {
      prevEl: prevRef.current,
      nextEl: nextRef.current,
    },
    loop: true,
    effect: "fade",
    spaceBetween: 30,
    speed: 500,
    onSwiper: setSwiper,
    onSlideChange: (swiper: SwiperClass) => {
      slideIndexRef.current = swiper.realIndex;
    },
    fadeEffect: {
      crossFade: true, // enables slides to cross fade
    },
    direction: "horizontal",
  };

  useEffect(() => {
    if (swiper) {
      // @ts-ignore
      swiper.params.navigation!.prevEl = prevRef.current;
      // @ts-ignore
      swiper.params.navigation!.nextEl = nextRef.current;

      swiper!.navigation.destroy();
      swiper!.navigation.init();
      swiper!.navigation.update();
    }
  }, [swiper]);

  const renderArrows = () => (
    <>
      <div
        className={clsx(styles["slider-arrow"], styles["arrow-prev"])}
        ref={prevRef}
      >
        <img alt="" src={arrow} />
      </div>
      <div
        className={clsx(styles["slider-arrow"], styles["arrow-next"])}
        ref={nextRef}
      >
        <img alt="" src={arrow} />
      </div>
    </>
  );

  const close = () => {
    onChange(slideIndexRef.current);
    onClose();
  };

  return (
    <>
      {!isBlockScroll && (
        <GlobalPopUp opened={opened} onClose={close}>
          <Swiper
            className={styles["popup-mobile__swiper"]}
            {...{ ...params, autoHeight: true }}
          >
            {photos.map((slide) => (
              <SwiperSlide key={slide.id}>
                <img
                  src={slide.files}
                  className={clsx(styles["popup-mobile__image"])}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {renderArrows()}
        </GlobalPopUp>
      )}

      {isBlockScroll && (
        <AnimatePresence>
          {opened && (
            <PopUpInner
              params={params}
              photos={photos}
              onRenderArrows={renderArrows}
              onClose={close}
            />
          )}
        </AnimatePresence>
      )}
    </>
  );
};

const PopUpInner: FC<{
  params: SwiperProps;
  photos: TeamType[];
  onRenderArrows: () => React.ReactNode;
  onClose: () => void;
}> = ({ onClose, onRenderArrows, photos, params }) => {
  const { teamImageRef, appRef } = useContext(RefsContext);

  const imageRect = teamImageRef.current!.getBoundingClientRect();

  return createPortal(
    <motion.div
      className={styles.popup}
      initial={{
        clipPath: `circle(${imageRect.width / 2}px at ${
          imageRect.x + imageRect.width / 2
        }px ${imageRect.y + imageRect.height / 2}px)`,
      }}
      animate={{
        clipPath: `circle(${appRef.current.clientHeight * 2}px at ${
          imageRect.x + imageRect.width / 2
        }px ${imageRect.y + imageRect.height / 2}px)`,
        transition: {
          type: "spring",
          stiffness: 10,
          restDelta: 1,
        },
      }}
      exit={{
        clipPath: `circle(${imageRect.width / 2}px at ${
          imageRect.x + imageRect.width / 2
        }px ${imageRect.y + imageRect.height / 2}px)`,
        transition: {
          type: "spring",
          bounce: 0,
          duration: 1,
        },
      }}
    >
      <div className={styles.popup__wrap}>
        <Swiper {...params} className={styles.popup__swiper}>
          {photos.map((slide) => (
            <SwiperSlide key={slide.id}>
              <motion.div
                style={{
                  backgroundImage: `url(${slide.files})`,
                }}
                initial={{ backgroundSize: "auto 100vh " }}
                exit={{
                  backgroundSize: "auto 70vh",
                  transition: {
                    ease: "linear",
                    delay: 0.44,
                    duration: 0.55,
                  },
                }}
                className={clsx(styles.popup__image)}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {onRenderArrows()}
        <div className={styles["popup__close"]} onClick={onClose}>
          <img alt="" src={close} />
        </div>
      </div>
    </motion.div>,
    document.querySelector("#modal")!
  );
};
