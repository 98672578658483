import clsx from "clsx";
import { FC, useContext, useRef, useEffect } from "react";
import close from "@/assets/icons/close.svg";
import styles from "./FormLayout.module.scss";
import layoutStyles from "@/layouts/MainLayout/Layout.module.scss";
import { Title } from "@/components/UI";
import { useBlockScroll, useDimensions, useMediaQuery } from "@/hooks";
import { RefsContext } from "@/providers";
import headerStyles from "@/layouts/MainLayout/components/Header/Header.module.scss";
import { motion } from "framer-motion";
import { disableScrollLock, enableScrollLock } from "@/utils";

interface Props {
  rect: DOMRect;
  children: React.ReactNode;
  title: string;
  opened: boolean;
  onClose: () => void;
}

export const FormLayout: FC<Props> = ({
  rect,
  children,
  opened,
  onClose,
  title,
}) => {
  const matchesTablet = useMediaQuery("tablet");
  const isBlockScroll = useBlockScroll();
  const ref = useRef<HTMLDivElement>(null);
  const { headerRef } = useContext(RefsContext);
  const { height } = useDimensions(ref);

  useEffect(() => {
    const headerTransparentAttr = "data-header-transparent-removed";

    if (opened) {
      enableScrollLock();

      if (
        headerRef.current!.classList.contains(headerStyles.header_transparent)
      ) {
        headerRef.current?.classList.remove(headerStyles.header_transparent);
        headerRef.current?.setAttribute(headerTransparentAttr, "true");
      }
    } else {
      if (!isBlockScroll) {
        disableScrollLock();
      }

      if (headerRef.current?.getAttribute(headerTransparentAttr) === "true") {
        headerRef.current?.classList.add(headerStyles.header_transparent);
        headerRef.current?.removeAttribute(headerTransparentAttr);
      }
    }
  }, [isBlockScroll, headerRef, opened]);

  const variants = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 3}px at 0px 0px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: `circle(0px at ${rect.x + rect.width / 2}px ${rect.y}px)`,
      transition: {
        type: "spring",
        stiffness: 250,
        damping: 40,
      },
    },
  };

  return (
    <>
      <motion.div
        className={styles["form-layout"]}
        initial={false}
        animate={opened ? "open" : "closed"}
        variants={variants}
        custom={height}
        ref={ref}
      ></motion.div>
      {opened && (
        <>
          <motion.div
            className={styles["form-layout"]}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 1,
                delay: matchesTablet ? 0.5 : 1,
              },
            }}
          >
            <div
              className={clsx(
                styles["form-layout__content"],
                styles.content,
                layoutStyles.container
              )}
              ref={ref}
            >
              <div className={styles.content__header}>
                <Title className={styles.content__title}>{title}</Title>
                <div
                  className={styles["content__header-close"]}
                  onClick={onClose}
                >
                  <img alt="" src={close} />
                </div>
              </div>
              <div className={styles.content__main}>{children}</div>
            </div>
          </motion.div>
        </>
      )}
    </>
  );
};
