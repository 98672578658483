import {
  motion,
  TargetAndTransition,
  useAnimationControls,
} from 'framer-motion';
import { FC, useEffect, useRef, Dispatch, SetStateAction } from 'react';

export type AnimationType = {
  step: number;
  substep: number;
};

interface Props {
  onlyBlock: boolean;
  animation: AnimationType;
  setAnimation: Dispatch<SetStateAction<AnimationType>>;
  clear: () => void;
}

const moveCircleTransition = (duration = 0.4, delay = 0.2) => ({
  ease: 'backOut',
  delay,
  duration,
});

const animations: {
  enterAnimations: {
    labels: string[];
    animation: TargetAndTransition;
  }[];
  exitAnimations?: {
    labels: string[];
    animation: TargetAndTransition;
  }[];
}[][] = [
  [
    {
      enterAnimations: [
        {
          labels: ['anim1_1'],
          animation: {
            y: '30px',
            transition: moveCircleTransition(),
          },
        },
      ],
      exitAnimations: [
        {
          labels: ['anim1_1'],
          animation: {
            scale: 0,
            opacity: 0,
            transition: { duration: 0.2 },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim1_2', 'anim1_3'],
          animation: {
            y: '30px',
            transition: moveCircleTransition(),
          },
        },
      ],
      exitAnimations: [
        {
          labels: ['anim1_2', 'anim1_3'],
          animation: {
            scale: 0,
            opacity: 0,
            transition: { duration: 0.2 },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim1_4', 'anim1_5'],
          animation: {
            y: '30px',
            transition: moveCircleTransition(),
          },
        },
      ],
      exitAnimations: [
        {
          labels: ['anim1_4', 'anim1_5'],
          animation: {
            scale: 0,
            opacity: 0,
            transition: { duration: 0.2 },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim1_6', 'anim1_7'],
          animation: {
            y: '30px',
            transition: moveCircleTransition(),
          },
        },
      ],
      exitAnimations: [
        {
          labels: ['anim1_6', 'anim1_7'],
          animation: {
            scale: 0,
            opacity: 0,
            transition: { duration: 0.2 },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim1_8'],
          animation: {
            x: -500,
            y: 500,
            transition: { duration: 0.3 },
          },
        },
        {
          labels: ['anim1_9'],
          animation: {
            x: 500,
            y: 500,
            transition: { duration: 0.3 },
          },
        },
      ],
      exitAnimations: [
        {
          labels: ['anim1_8', 'anim1_9'],
          animation: {
            opacity: 0,
            transition: { duration: 0.1 },
          },
        },
      ],
    },
  ],
  [
    {
      enterAnimations: [
        {
          labels: ['anim2_1'],
          animation: {
            cy: 111,
            transition: moveCircleTransition(undefined, 1.5),
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim2_2'],
          animation: {
            cx: 149,
            cy: 265,
            transition: moveCircleTransition(0.8),
          },
        },
        {
          labels: ['anim2_3'],
          animation: {
            cy: 34,
            transition: moveCircleTransition(0.8),
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim2_4'],
          animation: {
            cy: 111,
            cx: 380.5,
            opacity: 1,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_5'],
          animation: {
            cy: 187.5,
            cx: 110.5,
            opacity: 1,
            transition: moveCircleTransition(),
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim2_6'],
          animation: {
            cx: 419,
            cy: 187.5,
            opacity: 1,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_7'],
          animation: {
            cx: 73,
            cy: 111,
            opacity: 1,
            transition: moveCircleTransition(),
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim2_8'],
          animation: {
            cx: 457,
            cy: 265,
            opacity: 1,
            transition: moveCircleTransition(),
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim2_8'],
          animation: {
            cx: 496,
            cy: 187.5,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_1'],
          animation: {
            cy: 187.5,
            cx: 341.5,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_2'],
          animation: {
            cy: 187.5,
            cx: 188,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_7'],
          animation: {
            cx: 34,
            cy: 188,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_4'],
          animation: {
            cx: 419,
            cy: 34,
            transition: moveCircleTransition(),
          },
        },
        {
          labels: ['anim2_9'],
          animation: {
            cx: 265,
            cy: 342,
            transition: moveCircleTransition(),
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim2_3', 'anim2_4', 'anim2_9', 'anim2_10'],
          animation: {
            opacity: 0,
            transition: { duration: 0.4, ease: 'linear' },
          },
        },
      ],
    },
  ],
  [
    {
      enterAnimations: [
        {
          labels: ['anim3_1'], // 10
          animation: {
            cx: 303.5,
            cy: 111,
            transition: moveCircleTransition(0.8, 1.5),
          },
        },
        {
          labels: ['anim3_2'], // 11
          animation: {
            cx: 226,
            cy: 111,
            transition: moveCircleTransition(0.8, 1.5),
          },
        },
        {
          labels: ['anim3_3'], // 12
          animation: {
            cx: 188,
            cy: 34,
            transition: moveCircleTransition(0.8, 1.5),
          },
        },
        {
          labels: ['anim3_4'], // 17
          animation: {
            cx: 265,
            cy: 34,
            transition: moveCircleTransition(0.8, 1.5),
          },
        },
        {
          labels: ['anim3_5'], // 18
          animation: {
            cx: 341.5,
            cy: 34,
            transition: moveCircleTransition(0.8, 1.5),
          },
        },
        {
          labels: ['anim3_6'],
          animation: {
            opacity: 0,
            transition: {
              duration: 0.4,
              delay: 1.5,
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim3_7'],
          animation: {
            fill: '#000',
            stroke: '#80D207',
            transition: {
              delay: 0.2,
              duration: 0.6,
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim3_2'],
          animation: {
            cy: 265,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_1'],
          animation: {
            cy: 265,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_4'],
          animation: {
            cy: 187.5,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_5'],
          animation: {
            cy: 111,
            cx: 303.5,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_3'],
          animation: {
            cy: 111,
            cx: 226,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ['anim3_2'],
          animation: {
            cx: 188,
            cy: 342,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_1'],
          animation: {
            cy: 342,
            cx: 341.5,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_4'],
          animation: {
            cy: 342,
            cx: 265,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_5'],
          animation: {
            cy: 265,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
        {
          labels: ['anim3_3'],
          animation: {
            cy: 265,
            transition: { ease: 'easeOut', delay: 0.2, duration: 0.6 },
          },
        },
      ],
    },
  ],
];

export const Animation: FC<Props> = ({
  onlyBlock,
  animation,
  clear,
  setAnimation,
}) => {
  const initRef = useRef<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const controls = useAnimationControls();

  const runAnimation = async () => {
    const { step, substep } = animation;
    const substeps = animations[step];
    const { enterAnimations, exitAnimations = [] } = substeps[substep];

    // run enter animations of substep
    const enterAnimationsPromises = enterAnimations.map(
      (enterAnimation: {
        labels: string[];
        animation: Record<string, any>;
      }) => {
        const { labels, animation } = enterAnimation;

        return controls.start((custom) => {
          if (labels.includes(custom)) {
            return animation;
          }
          return {};
        });
      }
    );

    // waiting for enter animations is complete
    await Promise.all(enterAnimationsPromises);

    // run exit animations of substep
    if (exitAnimations) {
      exitAnimations.forEach(
        (exitAnimation: {
          labels: string[];
          animation: Record<string, any>;
        }) => {
          const { labels, animation } = exitAnimation;

          controls.start((custom) => {
            if (labels.includes(custom)) {
              return animation;
            }
            return {};
          });
        }
      );
    }

    // increment substep or step
    const isNextSupstep = substep < substeps.length - 1;

    if (isNextSupstep) {
      setAnimation((a: AnimationType) => ({ ...a, substep: a.substep + 1 }));
    } else if (!onlyBlock) {
      const isNextStep = step < animations.length - 1;

      if (isNextStep) {
        setAnimation((a: AnimationType) => {
          const newStep = a.step + 1;

          return {
            ...a,
            step: newStep,
            substep: 0,
          };
        });
      }
    }
  };

  useEffect(() => {
    if (initRef.current) {
      runAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  const deinit = () => {
    if (!initRef.current) {
      return;
    }

    initRef.current = false;

    controls.stop();
    clear();
  };

  const init = async () => {
    if (initRef.current) {
      return;
    }

    if (!onlyBlock) {
      await controls.start((custom) =>
        custom === 'init'
          ? {
              opacity: 1,
              transition: { delay: 2, duration: 0 },
            }
          : {}
      );
      await controls.start((custom) =>
        custom === 'init'
          ? {
              y: 0,
              transition: {
                duration: 0.6,
                ease: 'backOut',
              },
            }
          : {}
      );

      await controls.start((custom) =>
        custom === 'init'
          ? {
              opacity: 0,
              transition: {
                duration: 0.2,
              },
            }
          : {}
      );
    } else {
      const promise = new Promise((resolve) => {
        setTimeout(() => {
          resolve('ok');
        }, 500);
      });

      await promise;
    }

    initRef.current = true;
    runAnimation();
  };

  return (
    <div ref={wrapperRef}>
      <svg
        width="530"
        height="376"
        viewBox="0 0 530 376"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* BACKGROUND CIRCLES */}
        {/* ROW 1 */}
        <circle cx="110.5" cy="34" r="34" fill="#1D1D1D" />
        <motion.circle
          cx="188"
          cy="34"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <motion.circle
          cx="265"
          cy="34"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <motion.circle
          cx="341.5"
          cy="34"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <circle cx="419" cy="34" r="34" fill="#1D1D1D" />

        {/* ROW 2 */}
        <circle cx="73" cy="111" r="34" fill="#1D1D1D" />
        <circle cx="149.5" cy="111" r="34" fill="#1D1D1D" />
        <motion.circle
          cx="226"
          cy="111"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <motion.circle
          cx="303.5"
          cy="111"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <circle cx="380.5" cy="111" r="34" fill="#1D1D1D" />
        <circle cx="457" cy="111" r="34" fill="#1D1D1D" />

        {/* ROW 3 */}
        <circle cx="110.5" cy="187.5" r="34.5" fill="#1D1D1D" />
        <circle cx="34" cy="187.5" r="34" fill="#1D1D1D" />
        <circle cx="188" cy="187.5" r="34" fill="#1D1D1D" />
        <circle cx="265" cy="187.5" r="34" fill="#1D1D1D" />
        <circle cx="341.5" cy="187.5" r="34.5" fill="#1D1D1D" />
        <circle cx="419" cy="187.5" r="34" fill="#1D1D1D" />
        <circle cx="496" cy="187.5" r="34" fill="#1D1D1D" />

        {/* ROW 4 */}
        <circle cx="73" cy="265" r="34" fill="#1D1D1D" />
        <circle cx="149.5" cy="265" r="34" fill="#1D1D1D" />
        <motion.circle
          cx="226"
          cy="265"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <motion.circle
          cx="303.5"
          cy="265"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <circle cx="380.5" cy="265" r="34" fill="#1D1D1D" />
        <circle cx="457" cy="265" r="34" fill="#1D1D1D" />

        {/* ROW 5 */}
        <circle cx="110.5" cy="342" r="34" fill="#1D1D1D" />
        <motion.circle
          cx="188"
          cy="342"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <motion.circle
          cx="265"
          cy="342"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <motion.circle
          cx="341.5"
          cy="342"
          r="34"
          custom="anim3_7"
          initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
          animate={controls}
        />
        <circle cx="419" cy="342" r="34" fill="#1D1D1D" />

        {/* -------------- FIRST BLOCK ---------------*/}
        {animation.step === 0 && (
          <>
            {/* ROW 1 */}
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 265, cy: 34, opacity: 1 }}
              custom="anim1_1"
              animate={controls}
            />

            {/* ROW 2 */}
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 226, cy: 111, opacity: 1 }}
              custom="anim1_2"
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 303.5, cy: 111, opacity: 1 }}
              custom="anim1_3"
              animate={controls}
            />

            {/* ROW 3 */}
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 188, cy: 187.5, opacity: 1 }}
              custom="anim1_4"
              animate={controls}
            />
            <circle cx="265" cy="187.5" r="34" fill="#80D207" />
            <motion.circle
              r="34.5"
              fill="#80D207"
              initial={{ cx: 341.5, cy: 187.5, opacity: 1 }}
              custom="anim1_5"
              animate={controls}
            />

            {/* ROW 4 */}
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 149.5, cy: 265, opacity: 1 }}
              custom="anim1_6"
              animate={controls}
            />
            <circle cx="226" cy="265" r="34" fill="#80D207" />
            <circle cx="303.5" cy="265" r="34" fill="#80D207" />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 380.5, cy: 265, opacity: 1 }}
              custom="anim1_7"
              animate={controls}
            />

            {/* ROW 5 */}
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 110.5, cy: 342, opacity: 1 }}
              custom="anim1_8"
              animate={controls}
            />
            <circle cx="188" cy="342" r="34" fill="#80D207" />
            <circle cx="265" cy="342" r="34" fill="#80D207" />
            <circle cx="341.5" cy="342" r="34" fill="#80D207" />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 419, cy: 342, opacity: 1 }}
              custom="anim1_9"
              animate={controls}
            />
          </>
        )}

        {/* -------------- SECOND BLOCK ---------------*/}
        {animation.step === 1 && (
          <>
            {/* ROW 1 */}
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim2_4"
              initial={{ cx: 341.5, cy: 34, opacity: 0 }}
              animate={controls}
            />

            {/* ROW 2 */}
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim2_6"
              initial={{ cy: 111, cx: 380.5, opacity: 0 }}
              animate={controls}
            />

            {/* ROW 3 */}
            <circle cx="265" cy="187.5" r="34" fill="#80D207" />
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim2_7"
              initial={{ cx: 110.5, cy: 187.5, opacity: 0 }}
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim2_8"
              initial={{ cx: 419, cy: 187.5, opacity: 0 }}
              animate={controls}
            />

            {/* ROW 4 */}
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim2_5"
              initial={{ cx: 149.5, cy: 265, opacity: 0 }}
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 226, cy: 265, opacity: 1 }}
              custom="anim2_9"
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 303.5, cy: 265, opacity: 1 }}
              custom="anim2_1"
              animate={controls}
            />

            {/* ROW 5 */}
            <motion.circle
              cx="188"
              cy="342"
              r="34"
              fill="#80D207"
              custom="anim2_10"
              initial={{ cx: 188, cy: 342, opacity: 1 }}
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 265, cy: 342, opacity: 1 }}
              custom="anim2_2"
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 341.5, cy: 342, opacity: 1 }}
              custom="anim2_3"
              animate={controls}
            />
          </>
        )}

        {/* -------------- THIRD BLOCK ---------------*/}
        {animation.step === 2 && (
          <>
            {/* ROW 3 */}
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 34, cy: 187.5 }}
              custom="anim3_3"
              animate={controls}
            />
            <motion.circle
              r="34.5"
              fill="#80D207"
              initial={{ cx: 110.5, cy: 187.5 }}
              custom="anim3_4"
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              initial={{ cx: 188, cy: 187.5 }}
              custom="anim3_2"
              animate={controls}
            />
            <circle cx="265" cy="187.5" r="34" fill="#80D207" />
            <motion.circle
              r="34.5"
              fill="#80D207"
              custom="anim3_1"
              initial={{ cx: 341.5, cy: 187.5 }}
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim3_5"
              initial={{ cx: 419, cy: 187.5 }}
              animate={controls}
            />
            <motion.circle
              r="34"
              fill="#80D207"
              custom="anim3_6"
              initial={{ cx: 496, cy: 187.5, opacity: 1 }}
              animate={controls}
            />
          </>
        )}

        {/* INITIAL CIRCLE */}
        <motion.circle
          cx="265"
          cy="0"
          r="34"
          fill="#80D207"
          initial={{ y: -300, opacity: 0 }}
          animate={controls}
          viewport={{ once: true }}
          custom="init"
        />
      </svg>
      <motion.div
        onViewportEnter={init}
        onViewportLeave={deinit}
        viewport={{ margin: '25px' }}
      />
    </div>
  );
};
