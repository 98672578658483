import { RefsContext } from "@/providers";
import { FC, useContext } from "react";
import { ConditionalWrapper, AnimatedTitle, AnimatedBlock } from "@/components";
import evappsTeam from "@/assets/icons/evappsTeam.svg";
import teamImage from "@/assets/icons/team.svg";
import dye from "@/assets/icons/dye5.svg";
import arrow from "@/assets/icons/arrow.svg";

import styles from "./TeamImages.module.scss";
import { Carousel } from "./Carousel";
import { useSelector } from "@/store";

interface Props {
  animate?: boolean;
}

export const TeamImages: FC<Props> = ({ animate }) => {
  const { teamRef } = useContext(RefsContext);
  const { team: photos } = useSelector((store) => store.home.ru);

  return (
    <div className={styles.team} ref={teamRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={styles.team__title}>КОМАНДА</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <>
          <img
            alt=""
            src={evappsTeam}
            className={styles["team__evapps-team"]}
          />
          <img alt="" src={teamImage} className={styles["team__team"]} />
          <img alt="" src={dye} className={styles["team__dye"]} />
          <img alt="" src={arrow} className={styles["team__arrow"]} />

          {photos && photos.length ? <Carousel photos={photos} /> : null}
        </>
      </ConditionalWrapper>
    </div>
  );
};
