import clsx from 'clsx';
import styles from './ItOutstaffing.module.scss';
import { useContext } from 'react';
import { RefsContext } from '@/providers';
import { ReactComponent as TemporaryIcon } from './temporary.svg';
import { useSelector } from '@/store';
// import { useWindowSize } from '@/hooks';
// import { getVideoLink } from '@/utils';
// import { MainAnimation } from './MainAnimation';

export const ItOutstaffing: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { aboutRef } = useContext(RefsContext);
  const { home } = useSelector((store) => store.home.ru);
  // const sizes = useWindowSize();
  // const { showReal } = useSelector((state) => state.home.ru);

  return (
    <div className={clsx(styles['it-outstaffing'])} ref={aboutRef}>
      {/* <video className={clsx(styles['video'])} playsInline autoPlay loop muted src={getVideoLink(sizes.width, showReal)}></video> */}

      {/* <MainAnimation /> */}
      <TemporaryIcon className={styles['it-outstaffing__image']} />
      <h1 className={clsx(styles['it-outstaffing__title'])}>{home}</h1>
    </div>
  );
};
