import { createReducer } from '@reduxjs/toolkit';
import { getAllCases, getCasesBySlug } from './actions';
import { Cases } from './interfaces';

export const initialState: Cases = {
  tags: [],
  cases: [],
};

export const casesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAllCases.fulfilled, (state, action) => {
    state.cases = action.payload.cases;
    state.tags = action.payload.tags;
  });
  builder.addCase(getCasesBySlug.fulfilled, (state, action) => {
    state.cases = action.payload.cases;
    state.tags = action.payload.tags;
  });
});
