import { FC, useRef } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { SwiperWrapper } from '@/components/SwiperWrapper';
import { AccordionItem, AccordionItemType } from './AccordionItem';

import styles from './Accordion.module.scss';

interface Props {
  data: AccordionItemType[];
  config?: {
    swiper?: SwiperClass;
    setSwiper?: (swiper: SwiperClass) => void;
  };
}

export const AccordionSlider: FC<Props> = ({ config, data }) => {
  const { setSwiper } = config || {};
  const swiperNavPrevRef = useRef(null);
  const swiperNavNextRef = useRef(null);

  const params: SwiperProps = {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    navigation: {
      prevEl: swiperNavPrevRef.current,
      nextEl: swiperNavNextRef.current,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    // onInit: (swiper: any) => {
    //   swiper.params.navigation.prevEl = swiperNavPrevRef.current;
    //   swiper.params.navigation.nextEl = swiperNavNextRef.current;
    //   swiper.navigation.destroy();
    //   swiper.navigation.init();
    //   swiper.navigation.update();
    // },
  };

  return (
    <SwiperWrapper>
      <Swiper {...params} className={styles.swiper}>
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <AccordionItem
              icon={false}
              item={{ ...item, unopeneble: true }}
              opened
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};
