export enum BlockTypes {
  TEXT = 'blocksText',
  IMAGE = 'blocksMultimedia',
  QUOTE = 'blocksQuote',
  LIST = 'blocksDynamicList',
  TECHNOLOGY_LIST = 'blocksTechnologyList',
  TEXT_WITH_BG = 'blocksTextWithBackgroundImage',
  PERSONS = 'blocksPersonList',
}

export interface ITextBlock {
  type: BlockTypes.TEXT;
  item: {
    id: number;
    heading?: string;
    more: string;
  };
}

export interface IImageBlock {
  type: BlockTypes.IMAGE;
  item: {
    id: number;
    multimedia: string;
    more?: string;
  };
}

export interface IQuoteBlock {
  type: BlockTypes.QUOTE;
  item: {
    id: number;
    more: string;
  };
}

export interface IListBlock {
  type: BlockTypes.LIST;
  item: {
    id: number;
    heading: string;
    items: {
      heading: string;
      more: string;
    }[];
  };
}

export interface ITechnologyListBlock {
  type: BlockTypes.TECHNOLOGY_LIST;
  item: {
    id: number;
    heading: string;
    items: {
      id: number;
      name: string;
      logo: string;
    }[];
  };
}

export interface ITextWithBgBlock {
  type: BlockTypes.TEXT_WITH_BG;
  item: {
    id: number;
    heading: string;
    background_image: string;
    more: string;
  };
}

export interface IPersonsBlock {
  type: BlockTypes.PERSONS;
  item: {
    id: number;
    heading: string;
    items: {
      id: number;
      photo: string;
      name: string;
      post: string;
    }[];
  };
}

export type BlockType =
  | ITextBlock
  | IImageBlock
  | IQuoteBlock
  | IListBlock
  | ITextWithBgBlock
  | IPersonsBlock
  | ITechnologyListBlock;
