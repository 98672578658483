import { useBlockScroll } from "@/hooks";
import clsx from "clsx";
import { FC, useContext } from "react";
import arrow from "@/assets/icons/arrowRight1.svg";
import { SwiperInstancesContext } from "@/providers";

import styles from "./Top.module.scss";

interface Props {
  className?: string;
}

export const Top: FC<Props> = ({ className }) => {
  const isBlockScroll = useBlockScroll();
  const { scrollSwiper } = useContext(SwiperInstancesContext);

  return (
    <div
      className={clsx(styles["top"], className)}
      onClick={() => {
        if (isBlockScroll && scrollSwiper) {
          scrollSwiper.slideTo(0, 0);
        } else {
          window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
        }
      }}
    >
      <img alt="" src={arrow} />
    </div>
  );
};
