import React, { useState } from "react";
import styles from "./Cookies.module.scss";

export const Cookies: React.FC = () => {
  const isAgree: boolean = Boolean(localStorage.getItem("isAgree"));
  const [agree, setAgree] = useState(isAgree);

  const onAgree = () => {
    setAgree(true);
    localStorage.setItem("isAgree", "true");
  };

  return !agree ? (
    <div className={styles.cookies}>
      <span className={styles.cookies__text}>
        Этот сайт использует сервисы сбора технических данных посетителей
        (данные об IP-адресе, местоположении и др.) для обеспечения
        работоспособности и улучшения качества обслуживания. Продолжая
        использовать наш сайт, вы автоматически соглашаетесь с использованием
        данных технологий.
      </span>
      <div className={styles.cookies__buttons}>
        <button onClick={onAgree}>Согласиться</button>
        <a href="/backend/storage/policy.pdf" target="_blank">
          <button>Политика конфиденциальности</button>
        </a>
      </div>
    </div>
  ) : (
    <></>
  );
};
