import { cloneElement, FC, useContext, useEffect, useState } from 'react';
import { Controller, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrowScroll.svg';
import { RefsContext } from '@/providers';
import { enableScrollLock, disableScrollLock } from '@/utils';
import styles from './BlockScrollWrapper.module.scss';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

interface Props {
  swiper?: SwiperClass;
  setSwiper: (swiper: SwiperClass) => void;
  blocks: {
    name: string;
    component: JSX.Element;
  }[];
  nextClick?: () => void;
}

export const BlockScrollWrapper: FC<Props> = ({
  swiper,
  blocks,
  setSwiper,
  nextClick,
}) => {
  const [searchParams] = useSearchParams();
  const initialBlock = searchParams.get('block');
  const location = useLocation();

  const getInitialSlide = () => {
    if (initialBlock) {
      const index = blocks.findIndex((block) => block.name === initialBlock);

      if (index > -1) {
        return index;
      }
    }

    return 0;
  };

  const { blockScrollNextRef } = useContext(RefsContext);
  const [currentIdx, setCurrentIdx] = useState(getInitialSlide());
  const navigate = useNavigate();

  const scorllTop = () => {
    window.scrollTo(0, 0);
  };

  const next = () => {
    scorllTop();

    if (nextClick) {
      nextClick();
    } else {
      swiper?.slideNext();
    }
  };

  useEffect(() => {
    function slideChange(swiper: SwiperClass) {
      setCurrentIdx(swiper.realIndex);

      if (blocks[swiper.realIndex]) {
        const name = blocks[swiper.realIndex].name;

        navigate(
          {
            pathname: location.pathname,
            search: `?block=${name}`,
          },
          {
            replace: true,
          }
        );
      }
    }

    !swiper?.destroyed && swiper?.on('slideChange', slideChange);

    return () => {
      !swiper?.destroyed && swiper?.off('slideChange', slideChange);
    };
  }, [swiper, blocks, location, navigate]);

  useEffect(() => {
    enableScrollLock();

    return () => {
      disableScrollLock();
    };
  }, []);

  return (
    <div id="block_scroll" className={styles.block}>
      <Swiper
        direction="vertical"
        onSwiper={setSwiper}
        speed={0}
        spaceBetween={300}
        slidesPerView={1}
        mousewheel={{
          // @ts-ignore
          enabled: true,
          releaseOnEdges: false,
          thresholdDelta: 66,
          forceToAxis: true,
          eventsTarget: '#block_scroll',
        }}
        modules={[Mousewheel, Controller]}
        className={styles.block__swiper}
        initialSlide={currentIdx}
        noSwipingClass="swiper-slide"
        noSwiping
        autoHeight
        preventInteractionOnTransition
        allowSlidePrev
        allowSlideNext
      >
        {blocks.map((block) => (
          <SwiperSlide key={block.name}>
            {cloneElement(block.component, { animate: true })}
          </SwiperSlide>
        ))}
      </Swiper>

      {currentIdx !== blocks.length - 1 && (
        <div
          ref={blockScrollNextRef}
          className={styles.block__next}
          onClick={next}
        >
          <ArrowIcon />
        </div>
      )}
    </div>
  );
};
