import clsx from "clsx";

import "./SwiperWrapper.scss";

export function SwiperWrapper({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={clsx("swiper-custom-root", className)}>{children}</div>
  );
}
