import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { GetAllMassMediaResponse, MassMediaItem } from './interfaces';

export const getAllMassMedia = createAsyncThunk<MassMediaItem[], void>(
  'massMedia/getAllMassMedia',
  async () => {
    const res: GetAllMassMediaResponse = await api.GET('mass_media/all');
    return res.data;
  }
);
