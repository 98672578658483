import React, { FormEvent, useState } from 'react';
import styles from './SendLetter.module.scss';
import { Thanks } from '../../Thanks/Thanks';
import { useDispatch, useSelector } from '../../../store';
import { reset, sendMessage } from '../../../store/sendMessage/actions';
import clsx from 'clsx';
import { useMediaQuery } from '@/hooks';
import { FormLayout } from '@/layouts';
import { Button, Checkbox, Input } from '@/components/UI';
import { createPortal } from 'react-dom';

export const SendLetter: React.FC<{
  rect: DOMRect;
  opened: boolean;
  onClose: () => void;
}> = ({ rect, opened, onClose }) => {
  const matchesTablet = useMediaQuery('tablet');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();
  const { isSended, loading } = useSelector((store) => store.sendMessage);

  const [emailError, setEmailError] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const handleClose = () => {
    dispatch(reset());
    onClose();
    setName('');
    setEmail('');
    setMessage('');
    setCheckError(false);
    setEmailError(false);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    const emailValid = email.length && /\S+@\S+\.\S+/.test(email);

    e.preventDefault();
    if (checked && emailValid) {
      dispatch(
        sendMessage({
          name,
          email,
          org: '',
          tel: '',
          other: message,
        })
      );
      return;
    }
    if (!emailValid) {
      setEmailError(true);
    }
    if (!checked) {
      setCheckError(true);
    }
  };

  const renderButton = (mobile: boolean) => (
    <Button disabled={loading} type="submit" className={clsx({ [styles['send-letter__el-button']]: mobile })}>
      {loading ? 'Отправка...' : 'Отправить'}
    </Button>
  );

  return createPortal(
    <>
      <Thanks opened={isSended} title="Спасибо за ваше письмо" text="Команда Evapps" onClose={handleClose} />
      <FormLayout rect={rect} title="ПИСЬМО В EVAPPS" opened={opened} onClose={handleClose}>
        <form className={styles['send-letter']} onSubmit={onSubmit}>
          <Input type="text" className={styles['send-letter__el-name']} placeholder="Ваше имя" maxLength={50} value={name} onChange={(e) => setName(e.target.value)} />

          <Input
            placeholder="E-mail*"
            className={styles['send-letter__el-email']}
            type="text"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            maxLength={50}
            error={emailError}
            errorText={email.length === 0 ? 'Введите email адрес' : 'Введите корректный email адрес'}
          />

          <div className={styles['send-letter__el-block']}>
            <Input placeholder="Ваше сообщение" className={styles['send-letter__el-block-input']} type="text" value={message} onChange={(e) => setMessage(e.target.value)} maxLength={200} />
            {!matchesTablet && <div className={styles['send-letter__el-block-btn']}>{renderButton(false)}</div>}
          </div>
          <Checkbox
            className={styles['send-letter__el-checkbox']}
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
              setCheckError(false);
            }}
            error={checkError}
            errorText="Дайте согласие"
          >
            Я даю согласие на{' '}
            <a className={clsx(styles['send-letter__el-checkbox-link'], { [styles['send-letter__el-checkbox-link__error']]: checkError })} target="_blank" href="/backend/storage/policy.pdf">
              обработку персональных данных
            </a>
          </Checkbox>
          {matchesTablet && renderButton(true)}
        </form>
      </FormLayout>
    </>,
    document.querySelector('#modal')!
  );
};
