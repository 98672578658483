import { RefObject, useEffect, useState } from "react";
import { useOnScreen } from "./useOnScreen";
import { useScrollEnd } from "./useScrollEnd";

export const useRefRect = (
  ref: RefObject<any>,
  depends?: any[],
  refresh = 66
) => {
  const [rect, setRect] = useState<DOMRect>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    toJSON: () => null,
  });

  const visible = useOnScreen(ref);

  function calculateRect() {
    if (ref && ref.current) {
      const rect = ref.current!.getBoundingClientRect();
      setRect(rect);
    }
  }

  useScrollEnd(calculateRect);

  useEffect(() => {
    calculateRect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, refresh, ...(depends ?? [])]);

  return rect;
};
