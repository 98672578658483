import { useSelector } from "@/store";
import clsx from "clsx";
import { FC } from "react";
import styles from "./Socials.module.scss";

interface Props {
  white: boolean;
}

export const Socials: FC<Props> = ({ white }) => {
  const { footer } = useSelector((store) => store.home.ru);

  return (
    <ul className={clsx(styles.socials)}>
      {footer.map((f) => (
        <li key={f.id}>
          <a target="_blank" href={f.url} rel="noreferrer">
            <img alt="" src={f.svg} />
          </a>
        </li>
      ))}
    </ul>
  );
};
