import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from '@/store';
import { RefsContext } from '@/providers';
import { useBlockScroll, useRefRect } from '@/hooks';
import {
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
  CircleButton,
  Top,
} from '@/components';
import { Footer } from '@/layouts';

import { SendLetter } from './Send/SendLetter';
import styles from './Contacts.module.scss';

export const Contacts: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const isBlockScroll = useBlockScroll();
  const { contactsRef } = useContext(RefsContext);
  const circleButtonRef = useRef<HTMLDivElement>(null);
  const { contacts } = useSelector((store) => store.home.ru);
  const [isSend, setSend] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const rect = useRefRect(circleButtonRef, [animationComplete]);

  return (
    <div ref={contactsRef} className={styles.contacts__wrapper}>
      <SendLetter rect={rect} opened={isSend} onClose={() => setSend(false)} />
      <div>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
          )}
        >
          <h2 className={clsx(styles['contacts__title'])}>КОНТАКТЫ</h2>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedBlock
              onAnimationComplete={() => setAnimationComplete(true)}
            >
              {wrappedChildren}
            </AnimatedBlock>
          )}
        >
          <>
            <div className={clsx(styles.contacts)}>
              <div className={clsx(styles['contacts__item'])}>
                <div className={clsx(styles['contacts__description'])}>
                  <div className={styles['contacts__description-line']}>
                    {contacts?.name}
                  </div>
                  <div className={styles['contacts__description-line']}>
                    {contacts?.adres}
                  </div>
                  <div className={styles['contacts__description-line']}>
                    <a href="tel:+7 (499) 653 59 22">
                      <span>+7 (499)</span> 653 59 22
                    </a>
                  </div>
                  <div className={styles['contacts__description-line']}>
                    <a href="tel:+7 (4872) 76 10 44">
                      <span>+7 (4872)</span> 76 10 44
                    </a>
                  </div>
                  <div className={styles['contacts__description-line']}>
                    <a href={`mailto:${contacts?.email.toLowerCase()}`}>
                      {contacts?.email}
                    </a>
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  styles['contacts__item'],
                  styles['contacts__item-map']
                )}
              >
                <div className={clsx(styles['contacts__circle-wrap'])}>
                  <div className={clsx(styles['contacts__circle'])}>
                    <iframe
                      title="contact-map"
                      src="https://yandex.ru/map-widget/v1/?um=constructor%3A8f40bdbb2f1414f4c64ac17cf6796834a331ac3c655bca393f2f57e0c288b6a6&amp;source=constructor"
                      loading="lazy"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                    />
                  </div>
                  <div
                    ref={circleButtonRef}
                    className={clsx(styles['contacts__item-write'])}
                  >
                    <CircleButton onClick={() => setSend(true)}>
                      НАПИШИТЕ НАМ
                    </CircleButton>
                  </div>
                </div>
              </div>
              <Top className={styles.contacts__top} />
            </div>
          </>
        </ConditionalWrapper>
      </div>
      {isBlockScroll && <Footer layout={false} />}
    </div>
  );
};
