import { useState, useEffect } from "react";
// @ts-ignore
import sizes from "@/assets/styles/_media.scss";

type Sizes =
  | "scroll"
  | "mobile"
  | "tablet"
  | "laptop"
  | "smallDesktop"
  | "desktop";

export const useMediaQuery = (size: Sizes) => {
  const [matches, setMatches] = useState(false);

  const getQuery = (size: Sizes) => {
    const map: Record<Sizes, string> = {
      scroll: `(min-width: ${sizes.laptop}) and (orientation: landscape)`,
      mobile: `(max-width: ${sizes.mobile})`,
      tablet: `(max-width: ${sizes.tablet}), (min-width: ${sizes.tablet} + 1px) and  (orientation: portrait)`,
      laptop: `(max-width: ${sizes.laptop}), (min-width: ${sizes.laptop} + 1px) and (orientation: portrait)`,
      smallDesktop: `(max-width: ${sizes.desktop} - 1px), (min-width: ${sizes.laptop}) and (orientation: portrait)`,
      desktop: `(min-width: ${sizes.desktop}) and (orientation: landscape)`,
    };

    return map[size];
  };

  useEffect(() => {
    const query = getQuery(size);

    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, size]);

  return matches;
};
