import clsx from 'clsx';
import React, { useRef } from 'react';

import styles from './Achievements.module.scss';

import ZoomInIcon from '@/assets/icons/zoomIn.svg';
import { Achievements as AchievementsType } from '@/store/home/interfaces';

export const AchievementsSlide: React.FC<{
  achievement: AchievementsType;
  onSelect: (r: AchievementsType) => void;
  selected?: boolean;
}> = ({ achievement, onSelect, selected }) => {
  const { name, heading, more, logo, img } = achievement;
  const ref = useRef<HTMLParagraphElement>(null);

  const handleSelect = () => {
    onSelect(achievement);
  };

  return (
    <>
      <div
        className={clsx(styles['achievements-slide'])}
        onClick={img ? handleSelect : undefined}
      >
        <div>
          <span className={clsx(styles['achievements-slide__title'])}>
            {name}
          </span>
          <p className={clsx(styles['achievements-slide__header'])}>
            {heading}
          </p>
          <p
            ref={ref}
            className={clsx(styles['achievements-slide__content'], {
              [styles['achievements-slide__content-not-selected']]: !selected,
            })}
          >
            {more}
          </p>
        </div>
        <div className={clsx(styles['achievements-slide__logo--wrap'])}>
          {logo && (
            <div className={clsx(styles['achievements-slide__logo'])}>
              <img alt="" src={logo} />
            </div>
          )}
          {img && (
            <img
              src={ZoomInIcon}
              alt=""
              className={clsx(styles['achievements-slide__logo__zoom'])}
            />
          )}
        </div>
      </div>
    </>
  );
};
