import { useBlockScroll } from '@/hooks';
// @ts-ignore
import { useContext } from 'react';
import { RefsContext, SwiperInstancesContext } from '@/providers';
import { useCareerBlocks, useHomeBlocks } from '@/pages';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from '@/store';

export const useJump = () => {
  const isBlockScroll = useBlockScroll();
  const { headerInnerRef } = useContext(RefsContext);
  const { scrollSwiper } = useContext(SwiperInstancesContext);
  const { activeSections } = useSelector((state) => state.activeSections);
  const homeBlocks = useHomeBlocks(activeSections).filter(
    (block) => block.active
  );
  const careerBlocks = useCareerBlocks();
  const navigate = useNavigate();
  const location = useLocation();

  const findEl = (name: string) => {
    const el = [...homeBlocks, ...careerBlocks].find((el) => el.name === name);
    return el?.ref;
  };

  const jump = (name: string, lower?: boolean) => {
    if (!isBlockScroll) {
      const el = findEl(name);

      if (!lower) {
        window.scrollTo({
          top: el.current!.offsetTop - headerInnerRef.current!.offsetHeight,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo({
          top:
            el.current!.offsetTop - headerInnerRef.current!.offsetHeight + 300,
          behavior: 'smooth',
        });
      }
    } else if (isBlockScroll) {
      const searchIndex = <T extends { name: string }>(arr: T[]) =>
        arr.findIndex((el: T) => el.name === name);

      let index = searchIndex(homeBlocks);

      if (index === -1) {
        index = searchIndex(careerBlocks);
      }

      console.log(homeBlocks);

      if (scrollSwiper) {
        scrollSwiper.allowSlidePrev = true;
        scrollSwiper.allowSlideNext = true;
        scrollSwiper.slideTo(index, 0);
        navigate(
          {
            pathname: name === 'request' ? '/' : location.pathname,
            search: `?block=${name}`,
          },
          {
            replace: true,
          }
        );
      }
    }
  };

  return jump;
};
