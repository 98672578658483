import { IListBlock } from '../../types/blockTypes';
import styles from './ListBlock.module.scss';
import clsx from 'clsx';

interface ListBlockProps {
  block: IListBlock;
}

const ListBlock = ({ block }: ListBlockProps) => {
  const { heading, items } = block.item;

  return (
    <div className={styles['list-block__container']}>
      <h3 className={styles['list-block__container_title']}>{heading}</h3>
      <div className={styles['list-block__container_list']}>
        {Object.values(items).map((i, index) => (
          <div
            key={i.heading + index}
            className={clsx(styles['list-block__container_list_content'])}
          >
            <p className={styles['list-block__container_list_content_name']}>
              {i.heading}
            </p>
            <p
              className={
                styles['list-block__container_list_content_description']
              }
            >
              {i.more}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListBlock;
