import { BlockType } from '@/components/ContentBlocks';
import { renderBlock } from '@/utils';

import styles from './BlogDetailsContent.module.scss';

interface BlogDetailsContentProps {
  blocks?: BlockType[];
}

const BlogDetailsContent = ({ blocks }: BlogDetailsContentProps) => {
  return (
    <div className={styles['blog-details-content']}>
      {blocks?.map(renderBlock)}
    </div>
  );
};

export default BlogDetailsContent;
