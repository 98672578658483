import { createReducer } from '@reduxjs/toolkit';
import { getAllActiveSections } from './actions';
import { ActiveSections } from './interfaces';

export const initialState: ActiveSections = {
  activeSections: {
    benefits: true,
    autsors: true,
    blogs: true,
    cases: true,
    mass_media: true,
    scheme_work: true,
  },
  isLoading: true,
};

export const activeSectionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAllActiveSections.fulfilled, (state, action) => {
    state.activeSections = action.payload;
    state.isLoading = false;
  });
});
