import React from "react";
import clsx from "clsx";
import ReactMarquee from "react-fast-marquee";
import styles from "./Marquee.module.scss";

export const Marquee: React.FC<{ rows: number; children: React.ReactNode }> = ({
  rows,
  children,
}) => {
  return (
    <div className={clsx(styles.marquee)}>
      {new Array(rows).fill(null).map((_, index) => (
        <ReactMarquee
          key={index}
          className={styles.marquee__item}
          speed={35}
          gradient={false}
          direction={index % 2 ? "right" : "left"}
        >
          {children}
        </ReactMarquee>
      ))}
    </div>
  );
};
