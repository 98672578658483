import { createPortal } from "react-dom";
import { FC, memo, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import close from "@/assets/icons/close.svg";
import clsx from "clsx";

import styles from "./PopUp.module.scss";
import { useBlockScroll } from "@/hooks";
import { disableScrollLock, enableScrollLock } from "@/utils";
import { useClickOutside } from "@/hooks/useClickOutside";

interface Props {
  children: React.ReactNode;
  opened: boolean;
  onClose: () => void;
}

const PopUpInner: FC<Props> = ({ opened, children, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);
  const animationFinished = useRef<boolean>(false);

  useClickOutside(ref, () => {
    if (opened && animationFinished.current) {
      onClose();
    }
  });

  return createPortal(
    <motion.div
      className={styles.popup__window}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.8 } }}
      exit={{
        opacity: 0,
        transition: { duration: 0.6, ease: "easeOut" },
      }}
      onAnimationComplete={() => {
        animationFinished.current = true;
      }}
      onAnimationStart={() => {
        animationFinished.current = false;
      }}
    >
      <div className={clsx(styles["popup__window-wrap"])}>
        <div className={styles["popup__window-close"]} onClick={onClose}>
          <img alt="" src={close} />
        </div>
        <div ref={ref} className={styles["popup__window-content"]}>
          {children}
        </div>
      </div>
    </motion.div>,
    document.querySelector("#modal")!
  );
};

export const PopUp: FC<Props> = memo(({ opened, ...rest }) => {
  const isBlockScroll = useBlockScroll();

  useEffect(() => {
    if (!isBlockScroll) {
      if (opened) {
        enableScrollLock();
      } else {
        disableScrollLock();
      }
    }
  }, [opened, isBlockScroll]);

  return (
    <AnimatePresence>
      {opened && <PopUpInner opened={opened} {...rest} />}
    </AnimatePresence>
  );
});
