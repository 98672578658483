import { createReducer } from "@reduxjs/toolkit";
import { reset, sendResume } from "./actions";
import { State } from "./interfaces";

const initialState: State = {
  isSended: false,
  loading: false,
};

export const sendResumeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(reset, (state) => {
      state.loading = false;
      state.isSended = false;
    })
    .addCase(sendResume.pending, (state) => {
      state.loading = true;
    })
    .addCase(sendResume.fulfilled, (state) => {
      state.isSended = true;
      state.loading = false;
    });
});
