import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./assets/styles/main.scss";
import "swiper/css";
import { store } from "./store";
import { Provider } from "react-redux";
import "@/assets/styles/fonts.css";

//TODO context for Thermes
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
