import { useContext } from 'react';
import { CareerTop, CareerValues, Jobs, TeamImages } from '@/components';
import { RefsContext } from '@/providers';

import styles from './CareerPage.module.scss';

export function useBlocks() {
  const { careerTopRef, valuesRef, teamRef, jobsRef } = useContext(RefsContext);

  const blocks = [
    {
      name: 'career-top',
      component: <CareerTop />,
      className: styles.career__top,
      ref: careerTopRef,
    },
    {
      name: 'career-values',
      component: <CareerValues />,
      className: styles.career__values,
      ref: valuesRef,
    },
    {
      name: 'team',
      component: <TeamImages />,
      className: styles.career__team,
      ref: teamRef,
    },
    {
      name: 'jobs',
      component: <Jobs />,
      className: styles.career__jobs,
      ref: jobsRef,
    },
  ];

  return blocks;
}
