import clsx from "clsx";
import { InputHTMLAttributes } from "react";
import "./Checkbox.scss";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactNode;
  error?: boolean;
  errorText?: string;
}

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { children, error, errorText, className, ...rest } = props;

  return (
    <label className={clsx("check", className)}>
      <div>
        <input className="check__input" type="checkbox" {...rest} />
        <span
          className={clsx("check__box", {
            "check__box-error": !!error,
          })}
        ></span>
      </div>
      <div
        className={clsx("check__content", {
          "check__content-error": error,
        })}
      >
        {children}
        {!!error && !!errorText && (
          <div className={"check__content__error"}>{errorText}</div>
        )}
      </div>
    </label>
  );
};
