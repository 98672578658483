import { useMediaQuery } from "@/hooks";
import clsx from "clsx";
import { FC } from "react";
import "./Button.scss";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button: FC<ButtonProps> = (props) => {
  const { children, className, ...rest } = props;
  const matchesTablet = useMediaQuery("tablet");

  return (
    <button
      className={clsx("button", className, {
        "button-desktop": !matchesTablet,
        "button-mobile": matchesTablet,
      })}
      {...rest}
    >
      <span>{children}</span>
    </button>
  );
};
