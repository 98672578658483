import { createAction, createReducer } from '@reduxjs/toolkit';
import { getBlogBySlug } from './actions';
import { BlogState } from './interfaces';

export const initialState: BlogState = {
  blog: {
    id: null,
    slug: '',
    name: '',
    annotation: '',
    date_publication: '',
    tags: [],
    img_title: '',
    blocks: [],
  },
  blogsRandom: [],
};

export const clearBlogDetails = createAction('blog/clearBlogDetails');

export const blogDetailsReducer = createReducer(initialState, (builder) => {
  builder.addCase(clearBlogDetails, (state, action) => {
    return initialState;
  });
  builder.addCase(getBlogBySlug.fulfilled, (state, action) => {
    state.blog = action.payload.blog;
    state.blogsRandom = action.payload.blogsRandom;
  });
});
