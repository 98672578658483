import { useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Zoom } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';

import close from '@/assets/icons/close.svg';
import arrow from '../../../assets/icons/arrowRight1.svg';
import { SwiperWrapper } from '../../SwiperWrapper';
import styles from './Swiper.module.scss';
import { CertificatItem } from '@/store/home/interfaces';

type SwiperComponentProps = {
  data: CertificatItem[];
  onClose: () => void;
};

export const SwiperComponent = ({ data, onClose }: SwiperComponentProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    slidesPerView: 1,
    spaceBetween: 30,
    initialSlide: 0,
    loop: data.length !== 1 ? true : false,
    zoom: true,
    pagination: {
      clickable: true,
    },
    modules: data.length !== 1 ? [Pagination, Navigation, Zoom] : [Zoom],
    onSwiper: setSwiper,
    onSlideChange: (swiper) => swiper.zoom.out(),
  };

  return (
    <SwiperWrapper>
      <div className={clsx(styles['slider__container'])}>
        <Swiper {...params} className={clsx(styles['mySwiper'])}>
          {data.map((c) => (
            <SwiperSlide key={c.id} onClick={onClose}>
              <Slide onClose={onClose}>
                <div className="swiper-zoom-container">
                  <img
                    src={c.files}
                    alt=""
                    className={styles['certificates__slide__image']}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </Slide>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {data?.length !== 1 && (
        <>
          <div
            className={clsx(styles['slider-arrow'], styles['arrow-prev'])}
            onClick={(e) => {
              e.stopPropagation();
              swiper?.slidePrev();
            }}
          >
            <img alt="" src={arrow} />
          </div>
          <div
            className={clsx(styles['slider-arrow'], styles['arrow-next'])}
            onClick={(e) => {
              e.stopPropagation();
              swiper?.slideNext();
            }}
          >
            <img alt="" src={arrow} />
          </div>
        </>
      )}
    </SwiperWrapper>
  );
};

function Slide({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) {
  return (
    <div className={clsx(styles.certificates__slide)}>
      <div className={styles['certificates__slide-close']} onClick={onClose}>
        <img
          alt=""
          src={close}
          className={styles['certificates__slide-close__icon']}
        />
      </div>
      {children}
    </div>
  );
}
