import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "@/store";
import { useOnScreen, useRefRect } from "@/hooks";
import { RefsContext } from "@/providers";
import { HH, CircleButton } from "@/components";
import layoutStyles from "@/layouts/MainLayout/Layout.module.scss";
import clsx from "clsx";
import bg from "@/assets/img/carrer/top.jpg";
import dye1 from "@/assets/icons/dye1.svg";
import dye2 from "@/assets/icons/dye2.svg";
import headerStyles from "@/layouts/MainLayout/components/Header/Header.module.scss";

import styles from "./CareerTop.module.scss";
import { SendResume } from "../SendResume";

export const CareerTop: React.FC<{}> = () => {
  const ref = useRef<HTMLDivElement>(null);
  const circleButtonRef = useRef<HTMLDivElement>(null);
  const { headerRef } = useContext(RefsContext);
  const { isHeaderOpened } = useSelector((store) => store.header);
  const isVisible = useOnScreen(ref, [isHeaderOpened]);
  const [init, setInit] = useState(false);
  const rect = useRefRect(circleButtonRef, [init]);
  const [isSendResume, setSendResume] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      headerRef.current!.classList.add(headerStyles.header_transparent);
    } else {
      headerRef.current!.classList.remove(headerStyles.header_transparent);
    }
  }, [isVisible, headerRef]);

  return (
    <div className={styles["career-top"]} ref={ref}>
      <SendResume
        rect={rect}
        opened={isSendResume}
        onClose={() => setSendResume(false)}
      />
      <img alt="" src={dye1} className={styles["career-top__dye-1"]} />
      <img alt="" src={dye2} className={styles["career-top__dye-2"]} />
      <div
        className={clsx(
          styles["career-top__container"],
          layoutStyles.container
        )}
      >
        <img src={bg} className={styles["career-top__img"]} alt="" />
        <div className={styles["career-top__hh"]}>
          <HH />
        </div>
        <div ref={circleButtonRef} className={styles["career-top__send"]}>
          <CircleButton onClick={() => setSendResume(true)}>
            ОТПРАВИТЬ РЕЗЮМЕ
          </CircleButton>
        </div>
      </div>
    </div>
  );
};
