import { API_URL } from "./constants";

type Headers = {
    'Content-Type': string,
    Authorization?: string
}

const checkStatus = (response: Response) => {
    if (response.ok) {
        return response.text().then(function(text) {
          return text ? JSON.parse(text) : {}
        })
    } else if (response.status === 401 && (response.url !== API_URL + 'auth/token/')) {
        // tokenExpired();
    }
    return response.json();
};

let headers: Headers = {
    'Content-Type': 'application/json'
}

export const setToken = () => {
    const token = localStorage.getItem('access');
    headers = {...headers, Authorization: `Bearer ${token}`};
};
export const unsetToken = () => {
  delete headers.Authorization
};

export const changeContentType = () => {
    headers = {
        'Content-Type': 'multipart/form-data'
    }
}

export const resetContentType = () => {
    headers = {
        'Content-Type': 'application/json'
    }
}

const setSettings = (method: string, body?: object) => {
    const settings = {
        method,
        headers,
        body: body && body instanceof FormData ? body : JSON.stringify(body)
    }
    return settings
}

export const api = {
    GET: (query: string) => {
        return fetch(API_URL + query, setSettings('GET')).then(checkStatus)
    },
    POST: (query: string, body: object) => {
        return fetch(API_URL + query, setSettings('POST', body)).then(checkStatus)
    },
    PUT: (query: string, body: object) => {
        return fetch(API_URL + query, setSettings('PUT', body)).then(checkStatus)
    },
    PATCH: (query: string, body: object) => {
        return fetch(API_URL + query, setSettings('PATCH', body)).then(checkStatus)
    },
    DELETE: (query: string) => {
        return fetch(API_URL + query, setSettings('DELETE')).then(checkStatus)
    },
};