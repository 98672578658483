import { createReducer } from '@reduxjs/toolkit';
import { getAllBlogs, getBlogsBySlug } from './actions';
import { Blogs } from './interfaces';

export const initialState: Blogs = {
  tags: [],
  blogs: [],
};

export const blogsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAllBlogs.fulfilled, (state, action) => {
    state.blogs = action.payload.blogs;
    state.tags = action.payload.tags;
  });
  builder.addCase(getBlogsBySlug.fulfilled, (state, action) => {
    state.blogs = action.payload.blogs;
    state.tags = action.payload.tags;
  });
});
