import { useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';
import { useMediaQuery } from '@/hooks';
import { SwiperWrapper } from '@/components/SwiperWrapper';
import { BlogSlide } from '../../BlogSlide';
import { Blog } from '@/store/blogs/interfaces';

import styles from './BlogDetailsRandomArticles.module.scss';

interface BlogDetailsRandomArticlesProps {
  blogs: Blog[];
}

const BlogDetailsRandomArticles = ({
  blogs,
}: BlogDetailsRandomArticlesProps) => {
  const matchesTablet = useMediaQuery('tablet');
  const matchesMobile = useMediaQuery('mobile');

  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    slidesPerView: matchesMobile ? 1 : 1.5,
    spaceBetween: 30,
    initialSlide: 0,
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    loop: blogs.length < 2 ? false : true,
    centerInsufficientSlides: true,
  };

  if (!blogs.length) return null;

  return (
    <div className={styles['blog-details-random-articles']}>
      <h3 className={styles['blog-details-random-articles__title']}>
        Другие статьи
      </h3>
      <div className={styles['blog-details-random-articles__slides']}>
        {matchesTablet ? (
          <SwiperWrapper>
            <div className={clsx(styles['slider__container'])}>
              <Swiper {...params} className={clsx(styles['mySwiper'])}>
                {blogs.map((c) => (
                  <SwiperSlide key={c.id}>
                    <BlogSlide
                      blogItem={c}
                      className={
                        styles['blog-details-random-articles__slides_slide']
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </SwiperWrapper>
        ) : (
          blogs.map((b) => (
            <BlogSlide
              key={b.id}
              blogItem={b}
              className={styles['blog-details-random-articles__slides_slide']}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default BlogDetailsRandomArticles;
