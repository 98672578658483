import clsx from "clsx";
import React, { FC, useRef } from "react";
import "./CircleButton.scss";

export interface CircleButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const CircleButton: FC<CircleButtonProps> = (props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { children, className, ...rest } = props;

  return (
    <button ref={ref} className={clsx("circle-button", className)} {...rest}>
      <span>{children}</span>
    </button>
  );
};
