import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { api, changeContentType, resetContentType } from "../../utils/api";
import { API_URL } from "../../utils/constants";
import { RequestProject } from "./interfaces";

export const reset = createAction('requestProject/reset')

export const requestProject = createAsyncThunk<boolean, RequestProject>('requestProject/requestProject', async (params) => {
    const formData = new FormData();
    formData.append('name', params.name ?? '')
    formData.append('email', params.email ?? '')
    formData.append('org', params.org ?? '')
    formData.append('tel', params.tel ?? '')
    formData.append('other', params.other ?? '')
    formData.append('files[0]', params.file ?? '')
    changeContentType()
    await fetch(API_URL + 'mail/send', {
        method: 'POST',
        body: formData
    })
    //const res = await api.POST('mail/send', formData)
    resetContentType()
    return true
})