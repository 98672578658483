import clsx from 'clsx';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Header.module.scss';
import { PageTypes } from '../../../Layout';
import { useSelector } from '@/store';

interface Props {
  onOpen: (variant: 'home' | 'career') => void;
  onClose: () => void;
  page: PageTypes;
}

export const Navigator: FC<Props> = ({ page, onOpen, onClose }) => {
  const { activeSections, isLoading } = useSelector(
    (state) => state.activeSections
  );

  return (
    <nav className={clsx(styles.nav)}>
      {!isLoading && (
        <ul className={clsx(styles['nav__list'])}>
          <Link to="/" onClick={onClose}>
            <li
              className={clsx(styles['nav__item'], {
                [styles['nav__item-active']]: page === 'home',
              })}
              onMouseEnter={() => onOpen('home')}
            >
              О нас
            </li>
          </Link>
          {activeSections.cases && (
            <Link to="/cases/all">
              <li
                className={clsx(styles['nav__item'], {
                  [styles['nav__item-active']]: page === 'cases',
                })}
                onMouseEnter={onClose}
              >
                Кейсы
              </li>
            </Link>
          )}
          <Link to="/career" onClick={onClose}>
            <li
              className={clsx(styles['nav__item'], {
                [styles['nav__item-active']]: page === 'career',
              })}
              onMouseEnter={() => onOpen('career')}
            >
              Карьера
            </li>
          </Link>
          {activeSections.blogs && (
            <Link to="/blogs/all">
              <li
                className={clsx(styles['nav__item'], {
                  [styles['nav__item-active']]: page === 'blogs',
                })}
                onMouseEnter={onClose}
              >
                Блог
              </li>
            </Link>
          )}
          {activeSections.mass_media && (
            <Link to="/mass-media">
              <li
                className={clsx(styles['nav__item'], {
                  [styles['nav__item-active']]: page === 'mass-media',
                })}
                onMouseEnter={onClose}
              >
                СМИ
              </li>
            </Link>
          )}
        </ul>
      )}
    </nav>
  );
};
