import { FC } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";

interface Props {
  className?: string;
  children: React.ReactNode;
  onAnimationComplete?: () => void;
}

export const AnimatedBlock: FC<Props> = ({
  className,
  children,
  onAnimationComplete,
}) => {
  return (
    <motion.div
      className={clsx(className)}
      initial={{ opacity: 0, y: 300 }}
      whileInView={{
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.8,
          delay: 0.2,
          easing: "easeInOut",
        },
      }}
      viewport={{ margin: "200px" }}
      onAnimationComplete={onAnimationComplete}
    >
      {children}
    </motion.div>
  );
};
