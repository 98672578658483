import { FC } from "react";

interface Props {
  children: React.ReactElement;
  condition: boolean;
  wrap: (children: React.ReactElement) => JSX.Element;
}

export const ConditionalWrapper: FC<Props> = ({
  condition,
  wrap,
  children,
}) => {
  if (condition) {
    return wrap(children);
  }

  return children;
};
