import React, { useContext } from 'react';
import clsx from 'clsx';
import { useSelector } from '@/store';
import {
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
  Accordion,
} from '@/components';
import { useMediaQuery } from '@/hooks';
import { RefsContext } from '@/providers';

import styles from './Services.module.scss';

export const Services: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { servicesRef } = useContext(RefsContext);
  const { services } = useSelector((store) => store.home.ru);
  const matchesTablet = useMediaQuery('tablet');

  return (
    <div className={clsx(styles.services)} ref={servicesRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['services__title'])}>Технологии</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <Accordion
          data={services.map((s, i) => ({
            className: styles['services__accordion'],
            id: i + 1,
            title: s.name,
            body: '',
            stack: matchesTablet ? [] : s.list.map((l) => l.name),
            list: matchesTablet ? s.list.map((l) => l.name) : [],
            inlineList: matchesTablet,
            unopeneble: !matchesTablet,
            borderStyle: 'dotted',
          }))}
          icon={matchesTablet}
        />
      </ConditionalWrapper>
    </div>
  );
};
