import {
  FC,
  useState,
  useRef,
  useEffect,
  useContext,
  memo,
  PropsWithChildren,
} from 'react';
import {
  motion,
  useAnimationControls,
  AnimationControls,
  TargetAndTransition,
} from 'framer-motion';
import SwiperClass from 'swiper/types/swiper-class';

import { animations, phoneLabels, cvLabels, callLabels } from './animations';
import { SwiperInstancesContext } from '@/providers';
import { ConditionalWrapper } from '@/components/ConditionalWrapper';
import { createPortal } from 'react-dom';
import { useBlockScroll } from '@/hooks';

import styles from './SchemeSlides.module.scss';

type AnimationType = {
  step: number;
  substep: number;
  updatedManually?: boolean;
};

interface Props {}

export const Animation: FC<Props> = () => {
  const isBlockScroll = useBlockScroll();
  const blockEventsRef = useRef(false);
  const [initialized, setInitialized] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const controls = useAnimationControls();
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [animation, setAnimation] = useState<AnimationType>({
    step: 0,
    substep: 0,
    updatedManually: false,
  });
  const { schemaSwiper } = useContext(SwiperInstancesContext);

  const runAnimation = async () => {
    const { step, substep, updatedManually = false } = animation;
    const substeps = animations[step];
    const { enterAnimations, exitAnimations = [] } = substeps[substep];

    // move to 0-0 main circle if step was updated manually and substep = 0
    if (substep === 0 && updatedManually) {
      await controls.start((custom) => {
        if (custom === 'main') {
          return {
            cx: 0,
            cy: 0,
            transition: { duration: 0 },
            r: 13,
            strokeWidth: 10.8871,
            opacity: 1,
          };
        }

        return {};
      });
    }

    // run enter animations of substep
    const enterAnimationsPromises = enterAnimations.map(
      (enterAnimation: {
        labels: string[];
        animation: TargetAndTransition;
      }) => {
        const { labels, animation } = enterAnimation;

        return controls.start((custom) => {
          if (labels.includes(custom)) {
            return animation;
          }
          return {};
        });
      }
    );

    // waiting for enter animations is complete
    await Promise.all(enterAnimationsPromises);

    // run exit animations of substep
    if (exitAnimations) {
      exitAnimations.forEach(
        (exitAnimation: {
          labels: string[];
          animation: TargetAndTransition;
        }) => {
          const { labels, animation } = exitAnimation;

          controls.start((custom) => {
            if (labels.includes(custom)) {
              return animation;
            }
            return {};
          });
        }
      );
    }

    // increment substep or step
    const isNextSupstep = substep < substeps.length - 1;

    if (isNextSupstep) {
      setAnimation((a: AnimationType) => ({ ...a, substep: a.substep + 1 }));
    } else {
      const isNextStep = step < animations.length - 1;

      if (isNextStep) {
        const isLastStep = isNextStep && step + 1 === animations.length - 1;

        setAnimation((a: AnimationType) => {
          const newStep = a.step + 1;

          return {
            step: newStep,
            substep: 0,
            updatedManually: false,
          };
        });

        blockEventsRef.current = true;
        schemaSwiper?.slideNext(isLastStep ? 0 : undefined);
      } else {
        setAnimation((a: AnimationType) => ({ ...a, finished: true }));
      }
    }
  };

  useEffect(() => {
    if (initialized) {
      runAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation, initialized]);

  useEffect(() => {
    function slideChange(swiper: SwiperClass) {
      if (swiper?.realIndex > animations.length - 1) {
        return;
      }

      if (blockEventsRef.current) {
        blockEventsRef.current = false;
        return;
      }

      controls.stop();

      setAnimation({
        step: swiper?.realIndex,
        substep: 0,
        updatedManually: true,
      });
    }

    !schemaSwiper?.destroyed && schemaSwiper?.on('slideChange', slideChange);

    return () => {
      !schemaSwiper?.destroyed && schemaSwiper?.off('slideChange', slideChange);
    };
  }, [controls, schemaSwiper]);

  const deinit = () => {
    if (!initialized) {
      return;
    }

    blockEventsRef.current = false;
    setInitialized(false);

    controls.stop();

    setAnimation((a) => ({
      step: a.step,
      substep: 0,
      updatedManually: true,
    }));
    setLastUpdate(Date.now());
  };

  const init = async () => {
    if (initialized) {
      return;
    }

    setInitialized(true);
  };

  return (
    <>
      {((isBlockScroll && initialized) || !isBlockScroll) && (
        <ConditionalWrapper
          condition={isBlockScroll}
          wrap={(wrappedChildren) => <Wrapper>{wrappedChildren}</Wrapper>}
        >
          <div ref={wrapperRef} className={styles.animation}>
            <svg
              key={lastUpdate}
              width="427"
              height="410"
              viewBox="0 0 427 410"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.animation__main}
            >
              {animation.step === 3 && animation.updatedManually ? null : (
                <motion.circle
                  fill="#80D207"
                  stroke="#80D207"
                  initial={{
                    r: 13,
                    strokeWidth: 10.8871,
                    cx: 0,
                    cy: 0,
                    opacity: 1,
                  }}
                  custom="main"
                  animate={controls}
                />
              )}

              {animation.step === 0 && <FirstAnimation controls={controls} />}
              {animation.step === 1 && <SecondAnimation controls={controls} />}
              {animation.step === 2 && <ThirdAnimation controls={controls} />}
            </svg>
          </div>
        </ConditionalWrapper>
      )}
      <motion.div
        onViewportEnter={init}
        onViewportLeave={deinit}
        viewport={{ margin: '25px' }}
      />
    </>
  );
};

const Wrapper = ({ children }: PropsWithChildren<{}>) =>
  createPortal(children, document.querySelector('#modal')!);

const FirstAnimation = memo(({ controls }: { controls: AnimationControls }) => (
  <>
    {phoneLabels.map((label) => (
      <motion.circle
        key={label}
        r="5.5"
        fill="#80D207"
        initial={{ cx: 214, cy: 335, opacity: 0 }}
        custom={label}
        animate={controls}
      />
    ))}
  </>
));

const SecondAnimation = memo(
  ({ controls }: { controls: AnimationControls }) => (
    <>
      {cvLabels.map((label) => (
        <motion.circle
          key={label}
          r="5.5"
          fill="#80D207"
          initial={{ cx: 173, cy: 116, opacity: 0 }}
          custom={label}
          animate={controls}
        />
      ))}
    </>
  )
);

const ThirdAnimation = memo(({ controls }: { controls: AnimationControls }) => (
  <>
    {callLabels.map((label) => (
      <motion.circle
        key={label}
        r="5.5"
        fill="#80D207"
        initial={{ cx: 173, cy: 109, opacity: 0 }}
        custom={label}
        animate={controls}
      />
    ))}
  </>
));
