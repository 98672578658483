import React, { useState, useContext } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';
import { useSelector } from '@/store';
import { Review as ReviewType } from '@/store/home/interfaces';
import arrow from '@/assets/icons/arrowRight1.svg';
import {
  SwiperWrapper,
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
  PopUp,
} from '@/components';
import { useMediaQuery } from '@/hooks';
import { RefsContext } from '@/providers';

import styles from './Review.module.scss';
import { ReviewSlide } from './RevivewSlide';

export const Review: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { reviewsRef } = useContext(RefsContext);
  const [selected, setSelected] = useState<ReviewType | null>(null);
  const matchesMobile = useMediaQuery('mobile');
  const matchesDesktop = useMediaQuery('desktop');
  const matchesTablet = useMediaQuery('tablet');
  const { reviews } = useSelector((store) => store.home.ru);
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [selectedImgErr, setSelectedImgErr] = useState(false);

  const params: SwiperProps = {
    // ContainerEl: 'section',
    // WrapperEl: 'section',
    slidesPerView: matchesDesktop
      ? 2.5
      : matchesMobile
      ? 1
      : matchesTablet
      ? 1.5
      : 2.5,

    initialSlide: 0,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    loop: reviews.length <= 2 && !matchesTablet ? false : true,
    centerInsufficientSlides: true,
  };

  return (
    <div className={clsx(styles.review)} ref={reviewsRef}>
      <PopUp
        opened={!!selected && !matchesMobile}
        onClose={() => {
          setSelected(null);
          setSelectedImgErr(false);
        }}
      >
        <div className={clsx(styles.card)}>
          <div>
            <div className={clsx(styles['card__logo'])}>
              {!selectedImgErr && (
                <img
                  className={clsx(styles['card__logo-img'])}
                  src={selected?.logo}
                  alt=""
                  onError={() => setSelectedImgErr(true)}
                />
              )}
              <span className={clsx(styles['card__logo-title'])}>
                {selected?.signature}
              </span>
              <span className={clsx(styles['card__logo-subtitle'])}>
                {selected?.signature_two}
              </span>
            </div>
            <h3 className={clsx(styles['card__title'])}>{selected?.name}</h3>
          </div>
          <p className={clsx(styles['card__content'])}>{selected?.more}</p>
        </div>
      </PopUp>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['review__title'])}>ОТЗЫВЫ</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <SwiperWrapper className={clsx('review')}>
          <div className={clsx(styles['slider__container'])}>
            {reviews.length && (
              <Swiper {...params} className={clsx(styles['mySwiper'])}>
                {reviews.map((r) => (
                  <SwiperSlide key={r.id}>
                    <ReviewSlide
                      review={r}
                      onSelect={() => setSelected(r)}
                      selected={
                        !!selected && selected.id === r.id && matchesMobile
                      }
                      clearSelected={
                        !!selected && selected.id === r.id
                          ? () => setSelected(null)
                          : () => null
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          {reviews?.length > 2 && (
            <>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-prev'])}
                onClick={() => swiper?.slidePrev()}
              >
                <img alt="" src={arrow} />
              </div>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-next'])}
                onClick={() => swiper?.slideNext()}
              >
                <img alt="" src={arrow} />
              </div>
            </>
          )}
        </SwiperWrapper>
      </ConditionalWrapper>
    </div>
  );
};
