import { IImageBlock } from '../../types/blockTypes';

import styles from './ImageBlock.module.scss';

interface ImageBlockProps {
  block: IImageBlock;
}

const ImageBlock = ({ block }: ImageBlockProps) => {
  const { more, multimedia } = block.item;

  return (
    <div className={styles['image-block__container']}>
      <img
        src={multimedia}
        alt=""
        className={styles['image-block__container_image']}
      />
      {more && (
        <p className={styles['image-block__container_content']}>{more}</p>
      )}
    </div>
  );
};

export default ImageBlock;
