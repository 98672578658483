import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import SwiperClass from 'swiper/types/swiper-class';
import { useSelector } from '@/store';
import { RefsContext, SwiperInstancesContext } from '@/providers';
import { useMediaQuery } from '@/hooks';
import {
  AnimatedBlock,
  AnimatedTitle,
  ConditionalWrapper,
  Accordion,
} from '@/components';
import { Animation, AnimationType } from './AutsorsAnimation';

import styles from './Autsors.module.scss';

export const Autsors: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { autsors } = useSelector((store) => store.home.ru);
  const { autsorsRef } = useContext(RefsContext);
  const matchesTablet = useMediaQuery('tablet');
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [block, setBlock] = useState(false);
  const [animation, setAnimation] = useState<AnimationType>({
    step: 0,
    substep: 0,
  });
  const { autsorsSwiper, setAutsorsSwiper } = useContext(
    SwiperInstancesContext
  );
  const onlyBlock = matchesTablet || block;

  const clear = () => {
    setLastUpdate(Date.now());

    setAnimation((a) => ({
      step: onlyBlock ? a.step : 0,
      substep: 0,
    }));
  };

  const handleOpen = (step: number) => {
    setBlock(true);
    setLastUpdate(Date.now());
    setAnimation({
      step,
      substep: 0,
    });
  };

  useEffect(() => {
    function slideChange(swiper: SwiperClass) {
      if (matchesTablet) {
        setLastUpdate(Date.now());
        setAnimation({
          step: swiper.realIndex,
          substep: 0,
        });
      }
    }

    !autsorsSwiper?.destroyed && autsorsSwiper?.on('slideChange', slideChange);

    return () => {
      !autsorsSwiper?.destroyed &&
        autsorsSwiper?.off('slideChange', slideChange);
    };
  }, [matchesTablet, autsorsSwiper]);

  if (!autsors.length) return null;

  return (
    <div className={clsx(styles.autsors)} ref={autsorsRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['autsors__title'])}>Аутсорсинг</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <div className={clsx(styles['autsors__block'])}>
          <div className={clsx(styles['autsors__block-icon'])}>
            <Animation
              key={lastUpdate}
              animation={animation}
              setAnimation={setAnimation}
              onlyBlock={onlyBlock}
              clear={clear}
            />
          </div>
          <div className={clsx(styles['autsors__block-list'])}>
            <Accordion
              data={autsors.map((a, i) => ({
                id: i,
                title: a.name,
                body: a.more,
                className: clsx(styles['autsors__block-list-accordion'], {
                  [styles['autsors__block-list-accordion-active']]:
                    animation.step === i,
                }),
              }))}
              handleOpen={handleOpen}
              variant={{
                type: matchesTablet ? 'slider' : 'list',
                meta: {
                  setSwiper: setAutsorsSwiper,
                },
              }}
            />
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};
