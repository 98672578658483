import { createReducer } from "@reduxjs/toolkit";
import { reset, sendMessage } from "./actions";
import { State } from "./interfaces";

const initialState: State = {
    loading: false,
    isSended: false
}

export const sendMessageReducer = createReducer(initialState, builder => {
    builder
        .addCase(reset, state => {
            state.loading = false
            state.isSended = false
        })
        .addCase(sendMessage.pending, state => {
            state.loading = true
        })
        .addCase(sendMessage.fulfilled, state => {
            state.isSended = true
            state.loading = false
        })
})