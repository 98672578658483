import { createContext, FC, useState } from 'react';
import SwiperClass from 'swiper/types/swiper-class';

interface Props {
  children: React.ReactNode;
}

type SwiperInstancesContextType = {
  setSchemaSwiper: (swiper: SwiperClass) => void;
  schemaSwiper?: SwiperClass;
  setScrollSwiper: (swiper: SwiperClass) => void;
  scrollSwiper?: SwiperClass;
  setBenefitsSwiper: (swiper: SwiperClass) => void;
  benefitsSwiper?: SwiperClass;
  setAutsorsSwiper: (swiper: SwiperClass) => void;
  autsorsSwiper?: SwiperClass;
};

export const SwiperInstancesContext = createContext<SwiperInstancesContextType>(
  {} as SwiperInstancesContextType
);

export const SwiperInstancesProvider: FC<Props> = ({ children }) => {
  const [schemaSwiper, setSchemaSwiper] = useState<SwiperClass>();
  const [scrollSwiper, setScrollSwiper] = useState<SwiperClass>();
  const [benefitsSwiper, setBenefitsSwiper] = useState<SwiperClass>();
  const [autsorsSwiper, setAutsorsSwiper] = useState<SwiperClass>();

  return (
    <SwiperInstancesContext.Provider
      value={{
        schemaSwiper,
        setSchemaSwiper,
        scrollSwiper,
        setScrollSwiper,
        benefitsSwiper,
        setBenefitsSwiper,
        autsorsSwiper,
        setAutsorsSwiper,
      }}
    >
      {children}
    </SwiperInstancesContext.Provider>
  );
};
