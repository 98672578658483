import {
  Dispatch,
  FC,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import ArrowIcon from '@/assets/icons/arrowDiagonal.svg';
import { Case } from '@/store/home/interfaces';

import styles from './Cases.module.scss';

interface CasesSlideProps {
  className?: string;
  caseItem: Case;
  setActive?: Dispatch<SetStateAction<Record<string, boolean>>>;
}

export const CasesSlide: FC<CasesSlideProps> = ({
  caseItem,
  className,
  setActive,
}) => {
  const { slug, name, tags, img_card } = caseItem;

  const [offset, setOffset] = useState<{ height: number; width: number }>({
    height: 0,
    width: 0,
  });
  const nameRef = useRef<HTMLParagraphElement>(null);
  const cardRef = useRef<HTMLAnchorElement>(null);

  useLayoutEffect(() => {
    const offsetHeight = nameRef.current?.offsetHeight;
    const offsetWidth = cardRef.current?.offsetWidth;
    const listener = () => {
      if (offsetHeight && offsetWidth) {
        setOffset({ height: offsetHeight, width: offsetWidth });
      }
    };
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [nameRef.current?.offsetHeight, cardRef.current?.offsetWidth]);

  return (
    <div className={clsx(styles['cases-slide__wrapper'], className)}>
      <Link
        ref={cardRef}
        to={`/case/${slug}`}
        className={clsx(styles['cases-slide'])}
      >
        <img src={img_card} alt="" className={styles['cases-slide__bgImage']} />
        <div className={styles['cases-slide__description']}>
          <p ref={nameRef} className={styles['cases-slide__description_name']}>
            {name}
          </p>
        </div>
        <img src={ArrowIcon} alt="" className={styles['cases-slide__icon']} />
      </Link>
      <p
        className={styles['cases-slide__tags']}
        style={{
          left: '5%',
          bottom: `calc(${offset.height}px + 6%)`,
          width: `calc(${offset.width}px - 25%)`,
        }}
      >
        {tags?.map((t) => (
          <Link
            key={t.id}
            to={`/cases/${t.slug}`}
            className={styles['cases-slide__tags_tag']}
            onClick={(e) => {
              setActive && setActive(() => ({ [t.slug]: !![t.slug] }));
            }}
          >
            #{t.name}{' '}
          </Link>
        ))}
      </p>
    </div>
  );
};
