import {
  TextBlock,
  ImageBlock,
  ListBlock,
  QuoteBlock,
  TextWithBgBlock,
  PersonsBlock,
  BlockType,
  BlockTypes,
  TechnologyListBlock,
} from '@/components';

export function renderBlock(block: BlockType) {
  switch (block.type) {
    case BlockTypes.TEXT:
      return <TextBlock key={block.item.id + block.type} block={block} />;
    case BlockTypes.IMAGE:
      return <ImageBlock key={block.item.id + block.type} block={block} />;
    case BlockTypes.LIST:
      return <ListBlock key={block.item.id + block.type} block={block} />;
    case BlockTypes.TECHNOLOGY_LIST:
      return (
        <TechnologyListBlock key={block.item.id + block.type} block={block} />
      );
    case BlockTypes.QUOTE:
      return <QuoteBlock key={block.item.id + block.type} block={block} />;
    case BlockTypes.TEXT_WITH_BG:
      return <TextWithBgBlock key={block.item.id + block.type} block={block} />;
    case BlockTypes.PERSONS:
      return <PersonsBlock key={block.item.id + block.type} block={block} />;
    default:
      return null;
  }
}
