import { createReducer } from '@reduxjs/toolkit';
import { getHomePageInfo } from './actions';
import { HomeState } from './interfaces';

export const initialState: HomeState = {
  ru: {
    showReal: {
      small: '',
      medium: '',
      large: '',
    },
    contacts: {
      name: '',
      email: '',
      adres: '',
      tel1: '',
      tel2: '',
    },
    schemeWork: [],
    faq: [],
    certificat: [],
    services: [],
    home: '',
    benefits: [],
    autsors: [],
    reviews: [],
    achievements: [],
    header: [],
    footer: [],
    values: [],
    team: [],
    jobs: [],
    cases: [],
  },
};

export const homeReducer = createReducer(initialState, (builder) => {
  builder.addCase(getHomePageInfo.fulfilled, (store, action) => {
    // @ts-ignore
    const showReal = action.payload.ru.find((object) => !!object.show_real).show_real;
    // @ts-ignore
    const contacts = action.payload.ru.find((object) => !!object.contact).contact[0];
    // @ts-ignore
    const schemeWork = action.payload.ru.find((object) => !!object.scheme_work).scheme_work;
    // @ts-ignore
    const faq = action.payload.ru.find((object) => !!object.faq).faq;
    // @ts-ignore
    const certificat = action.payload.ru.find((object) => !!object.certificat).certificat;
    // @ts-ignore
    const services = action.payload.ru.find((object) => !!object.servis).servis;
    // @ts-ignore
    const home = action.payload.ru.find((object) => !!object.home).home[0].name;
    // @ts-ignore
    const benefits = action.payload.ru.find((object) => !!object.benefits).benefits;
    // @ts-ignore
    const autsors = action.payload.ru.find((object) => !!object.autsors).autsors;
    // @ts-ignore
    const reviews = action.payload.ru.find((object) => !!object.review).review;
    // @ts-ignore
    const achievements = action.payload.ru.find((object) => !!object.achievements).achievements;
    // @ts-ignore
    const header = action.payload.ru.find((object) => !!object.soclogo).soclogo.header;
    // @ts-ignore
    const footer = action.payload.ru.find((object) => !!object.soclogo).soclogo.footer;
    // @ts-ignore
    const values = action.payload.ru.find((object) => !!object.career_value).career_value;
    // @ts-ignore
    const team = action.payload.ru.find((object) => !!object.career_team).career_team;
    // @ts-ignore
    const jobs = action.payload.ru.find((object) => !!object.career_vacancies).career_vacancies;
    // @ts-ignore
    const cases = action.payload.ru.find((object) => !!object.cases).cases;

    store.ru.showReal = showReal;
    store.ru.contacts = contacts;
    store.ru.schemeWork = schemeWork;
    store.ru.faq = faq;
    store.ru.certificat = certificat;
    store.ru.services = services;
    store.ru.home = home;
    store.ru.benefits = benefits;
    store.ru.autsors = autsors;
    store.ru.reviews = reviews;
    store.ru.achievements = achievements;
    store.ru.header = header;
    store.ru.footer = footer;
    store.ru.values = values;
    store.ru.team = team;
    store.ru.jobs = jobs;
    store.ru.cases = cases;
  });
});
