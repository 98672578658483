import { ITechnologyListBlock } from '../../types/blockTypes';
import styles from './TechnologyListBlock.module.scss';
import clsx from 'clsx';

interface TechnologyListBlockProps {
  block: ITechnologyListBlock;
}

const TechnologyListBlock = ({ block }: TechnologyListBlockProps) => {
  const { heading, items } = block.item;

  return (
    <div className={styles['technology-list-block__container']}>
      <h3 className={styles['technology-list-block__container_title']}>
        {heading}
      </h3>
      <div className={styles['technology-list-block__container_list']}>
        {items.map((i, index) => (
          <div
            key={i.name + index}
            className={clsx(
              styles['technology-list-block__container_list_content']
            )}
          >
            <p
              className={
                styles['technology-list-block__container_list_content_name']
              }
            >
              {i.name}
            </p>
            <img
              src={i.logo}
              alt=""
              className={
                styles['technology-list-block__container_list_content_image']
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechnologyListBlock;
