import { TargetAndTransition } from "framer-motion";

const moveCircleTransition = ({ delay = 0.2, duration = 0.4 }) => ({
  ease: "backOut",
  delay,
  duration,
});

export const phoneLabels = Array.from(
  { length: 88 },
  (_, i) => `anim1_${i + 2}`
);
export const cvLabels = Array.from({ length: 129 }, (_, i) => `anim2_${i + 1}`);
export const callLabels = Array.from(
  { length: 176 },
  (_, i) => `anim3_${i + 1}`
);

const phoneDots = ({
  delay,
  duration,
}: {
  delay?: number;
  duration?: number;
}) => [
  {
    labels: ["anim1_2"],
    animation: {
      cx: 229.5,
      cy: 201.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_3"],
    animation: {
      cx: 291.5,
      cy: 169.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_4"],
    animation: {
      cx: 164.5,
      cy: 97.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_5"],
    animation: {
      cx: 249.5,
      cy: 248.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_6"],
    animation: {
      cx: 304.5,
      cy: 71.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_7"],
    animation: {
      cx: 219.5,
      cy: 36.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_8"],
    animation: {
      cx: 158.5,
      cy: 70.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_9"],
    animation: {
      cx: 257.5,
      cy: 49.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_10"],
    animation: {
      cx: 201.5,
      cy: 65.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_11"],
    animation: {
      cx: 225.5,
      cy: 158.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_12"],
    animation: {
      cx: 299.5,
      cy: 129.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_13"],
    animation: {
      cx: 182.5,
      cy: 222.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_14"],
    animation: {
      cx: 239.5,
      cy: 214.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_15"],
    animation: {
      cx: 207.5,
      cy: 229.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_16"],
    animation: {
      cx: 273.5,
      cy: 97.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_17"],
    animation: {
      cx: 196.5,
      cy: 108.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_18"],
    animation: {
      cx: 284.5,
      cy: 306.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_19"],
    animation: {
      cx: 190.5,
      cy: 348.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_20"],
    animation: {
      cx: 168.5,
      cy: 268.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_21"],
    animation: {
      cx: 255.5,
      cy: 263.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_22"],
    animation: {
      cx: 202.5,
      cy: 256.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_23"],
    animation: {
      cx: 258.5,
      cy: 279.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_24"],
    animation: {
      cx: 183.5,
      cy: 267.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_25"],
    animation: {
      cx: 216.5,
      cy: 262.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_26"],
    animation: {
      cx: 270.5,
      cy: 287.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_27"],
    animation: {
      cx: 172.5,
      cy: 299.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_28"],
    animation: {
      cx: 240.5,
      cy: 286.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_29"],
    animation: {
      cx: 194.5,
      cy: 282.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_30"],
    animation: {
      cx: 270.5,
      cy: 317.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },

  {
    labels: ["anim1_31"],
    animation: {
      cx: 168.5,
      cy: 317.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_32"],
    animation: {
      cx: 226.5,
      cy: 292.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_33"],
    animation: {
      cx: 208.5,
      cy: 285.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_34"],
    animation: {
      cx: 197.5,
      cy: 315.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_35"],
    animation: {
      cx: 232.5,
      cy: 317.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_36"],
    animation: {
      cx: 261.5,
      cy: 328.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_37"],
    animation: {
      cx: 197.5,
      cy: 299.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_38"],
    animation: {
      cx: 228.5,
      cy: 238.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_39"],
    animation: {
      cx: 186.5,
      cy: 176.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_40"],
    animation: {
      cx: 281.5,
      cy: 221.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_41"],
    animation: {
      cx: 210.5,
      cy: 197.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_42"],
    animation: {
      cx: 214.5,
      cy: 365.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_43"],
    animation: {
      cx: 254.5,
      cy: 203.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_44"],
    animation: {
      cx: 151.5,
      cy: 127.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_45"],
    animation: {
      cx: 290.5,
      cy: 290.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_46"],
    animation: {
      cx: 177.5,
      cy: 332.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_47"],
    animation: {
      cx: 140.5,
      cy: 222.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_48"],
    animation: {
      cx: 254.5,
      cy: 362.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_49"],
    animation: {
      cx: 173.5,
      cy: 351.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_50"],
    animation: {
      cx: 291.5,
      cy: 252.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_51"],
    animation: {
      cx: 161.5,
      cy: 198.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_52"],
    animation: {
      cx: 163.5,
      cy: 280.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_53"],
    animation: {
      cx: 280.5,
      cy: 326.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_54"],
    animation: {
      cx: 243.5,
      cy: 233.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_55"],
    animation: {
      cx: 161.5,
      cy: 227.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_56"],
    animation: {
      cx: 269.5,
      cy: 256.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_57"],
    animation: {
      cx: 178.5,
      cy: 251.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_58"],
    animation: {
      cx: 246.5,
      cy: 340.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_59"],
    animation: {
      cx: 256.5,
      cy: 293.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_60"],
    animation: {
      cx: 151.5,
      cy: 298.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_61"],
    animation: {
      cx: 233.5,
      cy: 262.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },

  {
    labels: ["anim1_62"],
    animation: {
      cx: 199.5,
      cy: 269.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_63"],
    animation: {
      cx: 270.5,
      cy: 304.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_64"],
    animation: {
      cx: 184.5,
      cy: 311.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_65"],
    animation: {
      cx: 238.5,
      cy: 299.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_66"],
    animation: {
      cx: 256.5,
      cy: 165.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_67"],
    animation: {
      cx: 208.5,
      cy: 176.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_68"],
    animation: {
      cx: 292.5,
      cy: 198.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_69"],
    animation: {
      cx: 163.5,
      cy: 169.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_70"],
    animation: {
      cx: 273.5,
      cy: 139.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_71"],
    animation: {
      cx: 214.5,
      cy: 247.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_72"],
    animation: {
      cx: 220.5,
      cy: 90.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_73"],
    animation: {
      cx: 270.5,
      cy: 188.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_74"],
    animation: {
      cx: 199.5,
      cy: 144.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_75"],
    animation: {
      cx: 238.5,
      cy: 182.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_76"],
    animation: {
      cx: 187.5,
      cy: 204.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_77"],
    animation: {
      cx: 214.5,
      cy: 305.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_78"],
    animation: {
      cx: 243.5,
      cy: 272.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_79"],
    animation: {
      cx: 190.5,
      cy: 240.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_80"],
    animation: {
      cx: 231.5,
      cy: 138.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_81"],
    animation: {
      cx: 281.5,
      cy: 274.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_82"],
    animation: {
      cx: 146.5,
      cy: 262.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_83"],
    animation: {
      cx: 220.5,
      cy: 279.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_84"],
    animation: {
      cx: 262.5,
      cy: 234.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_85"],
    animation: {
      cx: 275.5,
      cy: 347.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_86"],
    animation: {
      cx: 220.5,
      cy: 216.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_87"],
    animation: {
      cx: 182.5,
      cy: 285.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_88"],
    animation: {
      cx: 152.5,
      cy: 331.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim1_89"],
    animation: {
      cx: 251.5,
      cy: 316.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
];

const cvDots = ({ delay, duration }: { delay?: number; duration?: number }) => [
  {
    labels: ["anim2_1"],
    animation: {
      cx: 221.5,
      cy: 316.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_2"],
    animation: {
      cx: 330.5,
      cy: 59.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_3"],
    animation: {
      cx: 166.5,
      cy: 231.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_4"],
    animation: {
      cx: 337.5,
      cy: 107.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_5"],
    animation: {
      cx: 246.5,
      cy: 332.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_6"],
    animation: {
      cx: 205.5,
      cy: 220.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_7"],
    animation: {
      cx: 296.5,
      cy: 96.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_8"],
    animation: {
      cx: 258.5,
      cy: 209.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_9"],
    animation: {
      cx: 208.5,
      cy: 88.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_10"],
    animation: {
      cx: 238.5,
      cy: 119.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_11"],
    animation: {
      cx: 135.5,
      cy: 263.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_12"],
    animation: {
      cx: 259.5,
      cy: 98.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_13"],
    animation: {
      cx: 303.5,
      cy: 119.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_14"],
    animation: {
      cx: 301.5,
      cy: 197.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_15"],
    animation: {
      cx: 157.5,
      cy: 356.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_16"],
    animation: {
      cx: 324.5,
      cy: 336.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_17"],
    animation: {
      cx: 206.5,
      cy: 360.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_18"],
    animation: {
      cx: 129.5,
      cy: 326.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_19"],
    animation: {
      cx: 254.5,
      cy: 359.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_20"],
    animation: {
      cx: 361.5,
      cy: 76.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_21"],
    animation: {
      cx: 345.5,
      cy: 124.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_22"],
    animation: {
      cx: 337.5,
      cy: 287.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_23"],
    animation: {
      cx: 265.5,
      cy: 324.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_24"],
    animation: {
      cx: 139.5,
      cy: 287.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_25"],
    animation: {
      cx: 284.5,
      cy: 354.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_26"],
    animation: {
      cx: 185.5,
      cy: 343.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_27"],
    animation: {
      cx: 295.5,
      cy: 293.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_28"],
    animation: {
      cx: 218.5,
      cy: 160.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_29"],
    animation: {
      cx: 174.5,
      cy: 270.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_30"],
    animation: {
      cx: 227.5,
      cy: 288.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_31"],
    animation: {
      cx: 318.5,
      cy: 108.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_32"],
    animation: {
      cx: 324.5,
      cy: 164.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_33"],
    animation: {
      cx: 132.5,
      cy: 228.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_34"],
    animation: {
      cx: 278.5,
      cy: 148.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_35"],
    animation: {
      cx: 229.5,
      cy: 147.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_36"],
    animation: {
      cx: 309.5,
      cy: 258.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_37"],
    animation: {
      cx: 163.5,
      cy: 296.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_38"],
    animation: {
      cx: 233.5,
      cy: 312.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_39"],
    animation: {
      cx: 315.5,
      cy: 134.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_40"],
    animation: {
      cx: 314.5,
      cy: 218.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_41"],
    animation: {
      cx: 246.5,
      cy: 249.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_42"],
    animation: {
      cx: 289.5,
      cy: 58.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_43"],
    animation: {
      cx: 152.5,
      cy: 243.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_44"],
    animation: {
      cx: 253.5,
      cy: 285.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_45"],
    animation: {
      cx: 219.5,
      cy: 248.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_46"],
    animation: {
      cx: 300.5,
      cy: 158.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_47"],
    animation: {
      cx: 220.5,
      cy: 53.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_48"],
    animation: {
      cx: 215.5,
      cy: 203.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_49"],
    animation: {
      cx: 277.5,
      cy: 258.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_50"],
    animation: {
      cx: 190.5,
      cy: 296.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_51"],
    animation: {
      cx: 272.5,
      cy: 177.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_52"],
    animation: {
      cx: 258.5,
      cy: 228.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_53"],
    animation: {
      cx: 140.5000041872263,
      cy: 199.5000059455631,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_54"],
    animation: {
      cx: 157.50000469386578,
      cy: 211.50000630319096,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_55"],
    animation: {
      cx: 181.5000054091215,
      cy: 242.50000722706295,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_56"],
    animation: {
      cx: 238.5,
      cy: 204.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_57"],
    animation: {
      cx: 320.5,
      cy: 86.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_58"],
    animation: {
      cx: 279.5,
      cy: 86.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_59"],
    animation: {
      cx: 284.5,
      cy: 120.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_60"],
    animation: {
      cx: 185.5,
      cy: 218.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_61"],
    animation: {
      cx: 252.5,
      cy: 151.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_62"],
    animation: {
      cx: 156.5,
      cy: 188.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_63"],
    animation: {
      cx: 174.5,
      cy: 193.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_64"],
    animation: {
      cx: 189.5,
      cy: 171.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_65"],
    animation: {
      cx: 238.5,
      cy: 66.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_66"],
    animation: {
      cx: 210.5,
      cy: 141.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_67"],
    animation: {
      cx: 249.5,
      cy: 86.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_68"],
    animation: {
      cx: 217.5,
      cy: 105.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_69"],
    animation: {
      cx: 134.5,
      cy: 62.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_70"],
    animation: {
      cx: 128.5,
      cy: 109.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_71"],
    animation: {
      cx: 128.50000382959843,
      cy: 164.5000049024818,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_72"],
    animation: {
      cx: 149.5000044554472,
      cy: 162.50000484287716,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_73"],
    animation: {
      cx: 216.5000064522028,
      cy: 177.500005289912,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_74"],
    animation: {
      cx: 186.50000555813313,
      cy: 192.50000573694683,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_75"],
    animation: {
      cx: 234.5,
      cy: 165.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_76"],
    animation: {
      cx: 254.5,
      cy: 131.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_77"],
    animation: {
      cx: 197.5,
      cy: 71.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_78"],
    animation: {
      cx: 152.50000454485416,
      cy: 78.50000233948231,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_79"],
    animation: {
      cx: 128.50000382959843,
      cy: 126.50000376999355,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_80"],
    animation: {
      cx: 170.50000508129597,
      cy: 162.50000484287716,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_81"],
    animation: {
      cx: 264.50000788271427,
      cy: 116.50000347197033,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_82"],
    animation: {
      cx: 235.50000701844692,
      cy: 104.50000311434269,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_83"],
    animation: {
      cx: 172.5000051409006,
      cy: 66.50000198185444,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_84"],
    animation: {
      cx: 127.50000379979609,
      cy: 88.50000263750553,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_85"],
    animation: {
      cx: 138.50000412762165,
      cy: 146.50000436604,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_86"],
    animation: {
      cx: 224.50000669062138,
      cy: 132.5000039488075,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_87"],
    animation: {
      cx: 200.50000597536564,
      cy: 158.50000472366787,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_88"],
    animation: {
      cx: 232.5,
      cy: 34.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_89"],
    animation: {
      cx: 102.5,
      cy: 241.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_90"],
    animation: {
      cx: 172.5,
      cy: 404.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_91"],
    animation: {
      cx: 280.5,
      cy: 40.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_92"],
    animation: {
      cx: 118.5,
      cy: 248.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_93"],
    animation: {
      cx: 65.5,
      cy: 188.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_94"],
    animation: {
      cx: 237.50000707805157,
      cy: 5.500000163912773,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_95"],
    animation: {
      cx: 346.5000103265047,
      cy: 89.50000266730785,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_96"],
    animation: {
      cx: 221.5000066012144,
      cy: 402.50001199543453,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_97"],
    animation: {
      cx: 269.5,
      cy: 391.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_98"],
    animation: {
      cx: 210.5,
      cy: 265.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_99"],
    animation: {
      cx: 351.5,
      cy: 251.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_100"],
    animation: {
      cx: 105.5,
      cy: 315.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_101"],
    animation: {
      cx: 292.5,
      cy: 236.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_102"],
    animation: {
      cx: 71.5,
      cy: 226.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_103"],
    animation: {
      cx: 303.5,
      cy: 15.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_104"],
    animation: {
      cx: 329.5,
      cy: 47.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_105"],
    animation: {
      cx: 332.5,
      cy: 200.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_106"],
    animation: {
      cx: 100.5,
      cy: 269.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_107"],
    animation: {
      cx: 71.5,
      cy: 92.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_108"],
    animation: {
      cx: 170.5,
      cy: 15.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_109"],
    animation: {
      cx: 354.5,
      cy: 148.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_110"],
    animation: {
      cx: 301.5,
      cy: 176.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_111"],
    animation: {
      cx: 282.5,
      cy: 202.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_112"],
    animation: {
      cx: 84.50000251829623,
      cy: 149.50000445544697,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_113"],
    animation: {
      cx: 103.50000308454035,
      cy: 218.50000651180721,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_114"],
    animation: {
      cx: 103.50000308454035,
      cy: 61.50000183284283,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_115"],
    animation: {
      cx: 252.5000075250864,
      cy: 42.5000012665987,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_116"],
    animation: {
      cx: 271.5,
      cy: 161.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_117"],
    animation: {
      cx: 89.5,
      cy: 116.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_118"],
    animation: {
      cx: 128.5,
      cy: 187.5,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_119"],
    animation: {
      cx: 214.50000639259815,
      cy: 119.5000035613773,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_120"],
    animation: {
      cx: 97.50000290572642,
      cy: 183.50000546872593,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_121"],
    animation: {
      cx: 268.50000800192356,
      cy: 66.50000198185444,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_122"],
    animation: {
      cx: 108.50000323355196,
      cy: 84.50000251829624,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_123"],
    animation: {
      cx: 108.50000323355196,
      cy: 142.5000042468307,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_124"],
    animation: {
      cx: 186.50000555813313,
      cy: 155.5000046342609,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_125"],
    animation: {
      cx: 202.50000603497028,
      cy: 40.50000120699406,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_126"],
    animation: {
      cx: 180.5000053793192,
      cy: 52.500001564621925,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_127"],
    animation: {
      cx: 110.50000329315661,
      cy: 115.50000344216801,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_128"],
    animation: {
      cx: 152.50000454485416,
      cy: 46.50000138580799,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim2_129"],
    animation: {
      cx: 248.5000074058771,
      cy: 183.50000546872593,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
];

const callDots = ({
  delay,
  duration,
}: {
  delay?: number;
  duration?: number;
}) => [
  {
    labels: ["anim3_1"],
    animation: {
      cx: 5.500000163912773,
      cy: 136.500004068017,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_2"],
    animation: {
      cx: 409.50001220405096,
      cy: 128.50000382959843,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_3"],
    animation: {
      cx: 75.50000225007534,
      cy: 100.5000029951334,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_4"],
    animation: {
      cx: 255.5000076144933,
      cy: 74.50000222027302,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_5"],
    animation: {
      cx: 307.5000091642141,
      cy: 296.5000088363886,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_6"],
    animation: {
      cx: 236.50000704824924,
      cy: 200.50000597536564,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_7"],
    animation: {
      cx: 98.50000293552876,
      cy: 134.50000400841236,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_8"],
    animation: {
      cx: 141.50000421702862,
      cy: 148.50000442564487,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_9"],
    animation: {
      cx: 200.50000597536564,
      cy: 126.50000376999378,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_10"],
    animation: {
      cx: 173.50000517070293,
      cy: 92.50000275671482,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_11"],
    animation: {
      cx: 271.50000809133047,
      cy: 129.50000385940075,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_12"],
    animation: {
      cx: 149.5000044554472,
      cy: 168.50000502169132,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_13"],
    animation: {
      cx: 124.50000371038914,
      cy: 119.50000356137753,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_14"],
    animation: {
      cx: 218.50000651180744,
      cy: 101.50000302493572,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_15"],
    animation: {
      cx: 192.50000573694706,
      cy: 109.5000032633543,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_16"],
    animation: {
      cx: 359.50001071393484,
      cy: 129.50000385940075,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_17"],
    animation: {
      cx: 404.50001205503935,
      cy: 167.500004991889,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_18"],
    animation: {
      cx: 61.50000183284283,
      cy: 259.50000773370266,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_19"],
    animation: {
      cx: 252.50000752508635,
      cy: 183.50000546872616,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_20"],
    animation: {
      cx: 341.50001017749304,
      cy: 96.50000287592411,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_21"],
    animation: {
      cx: 44.500001326203346,
      cy: 154.5000046044588,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_22"],
    animation: {
      cx: 401.5000119656324,
      cy: 350.500010445714,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_23"],
    animation: {
      cx: 295.5000088065862,
      cy: 93.50000278651714,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_24"],
    animation: {
      cx: 49.50000147521496,
      cy: 79.50000236928463,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_25"],
    animation: {
      cx: 421.50001256167883,
      cy: 138.50000412762165,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_26"],
    animation: {
      cx: 77.50000230967999,
      cy: 161.50000481307507,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_27"],
    animation: {
      cx: 415.5000123828649,
      cy: 298.50000889599323,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_28"],
    animation: {
      cx: 386.50001151859755,
      cy: 132.50000394880772,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_29"],
    animation: {
      cx: 121.50000362098217,
      cy: 76.50000227987766,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_30"],
    animation: {
      cx: 351.50001047551626,
      cy: 117.50000350177288,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_31"],
    animation: {
      cx: 189.5000056475401,
      cy: 260.500007763505,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_32"],
    animation: {
      cx: 384.5000114589929,
      cy: 252.5000075250864,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_33"],
    animation: {
      cx: 169.50000505149364,
      cy: 119.50000356137753,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_34"],
    animation: {
      cx: 208.50000621378422,
      cy: 135.50000403821468,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_35"],
    animation: {
      cx: 229.500006839633,
      cy: 323.5000096410513,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_36"],
    animation: {
      cx: 224.50000669062138,
      cy: 56.500001683831215,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_37"],
    animation: {
      cx: 98.50000293552876,
      cy: 238.5000071078539,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_38"],
    animation: {
      cx: 135.50000403821468,
      cy: 320.5000095516443,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_39"],
    animation: {
      cx: 101.50000302493572,
      cy: 66.50000198185444,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_40"],
    animation: {
      cx: 280.5000083595514,
      cy: 74.50000222027302,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_41"],
    animation: {
      cx: 90.50000269711018,
      cy: 265.5000079125166,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_42"],
    animation: {
      cx: 140.5000041872263,
      cy: 234.5000069886446,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_43"],
    animation: {
      cx: 322.5000096112489,
      cy: 219.50000654160976,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_44"],
    animation: {
      cx: 75.50000225007534,
      cy: 197.50000588595867,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_45"],
    animation: {
      cx: 303.5000090450048,
      cy: 251.50000749528408,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_46"],
    animation: {
      cx: 324.50000967085356,
      cy: 148.50000442564487,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_47"],
    animation: {
      cx: 383.5000114291906,
      cy: 174.50000520050526,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_48"],
    animation: {
      cx: 339.5000101178884,
      cy: 224.50000669062138,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_49"],
    animation: {
      cx: 123.50000368058681,
      cy: 242.50000722706318,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_50"],
    animation: {
      cx: 237.50000707805157,
      cy: 243.5000072568655,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_51"],
    animation: {
      cx: 58.50000174343586,
      cy: 113.50000338256359,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_52"],
    animation: {
      cx: 382.50001139938826,
      cy: 232.50000692903996,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_53"],
    animation: {
      cx: 319.50000952184195,
      cy: 206.50000615417957,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_54"],
    animation: {
      cx: 341.50001017749304,
      cy: 278.5000082999468,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_55"],
    animation: {
      cx: 357.5000106543302,
      cy: 239.5000071376562,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_56"],
    animation: {
      cx: 334.5000099688768,
      cy: 195.50000582635403,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_57"],
    animation: {
      cx: 278.5000082999467,
      cy: 280.50000835955143,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_58"],
    animation: {
      cx: 136.500004068017,
      cy: 273.5000081509352,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_59"],
    animation: {
      cx: 195.50000582635403,
      cy: 174.50000520050526,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_60"],
    animation: {
      cx: 196.50000585615635,
      cy: 205.50000612437725,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_61"],
    animation: {
      cx: 120.50000359117985,
      cy: 134.50000400841236,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_62"],
    animation: {
      cx: 262.50000782310957,
      cy: 171.5000051110983,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_63"],
    animation: {
      cx: 169.50000505149364,
      cy: 281.50000838935375,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_64"],
    animation: {
      cx: 349.5000104159116,
      cy: 155.50000463426113,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_65"],
    animation: {
      cx: 261.50000779330725,
      cy: 218.50000651180744,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_66"],
    animation: {
      cx: 348.5000103861093,
      cy: 210.50000627338886,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_67"],
    animation: {
      cx: 311.50000928342337,
      cy: 338.5000100880861,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_68"],
    animation: {
      cx: 234.5000069886446,
      cy: 121.50000362098217,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_69"],
    animation: {
      cx: 226.50000675022602,
      cy: 163.5000048726797,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_70"],
    animation: {
      cx: 155.50000463426113,
      cy: 111.50000332295895,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_71"],
    animation: {
      cx: 165.50000493228436,
      cy: 224.50000669062138,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_72"],
    animation: {
      cx: 97.50000290572643,
      cy: 160.50000478327274,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_73"],
    animation: {
      cx: 202.50000603497028,
      cy: 99.50000296533108,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_74"],
    animation: {
      cx: 272.5000081211328,
      cy: 116.50000347197056,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_75"],
    animation: {
      cx: 189.5000056475401,
      cy: 241.50000719726086,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_76"],
    animation: {
      cx: 249.50000743567938,
      cy: 103.50000308454037,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_77"],
    animation: {
      cx: 144.50000430643559,
      cy: 124.50000371038914,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_78"],
    animation: {
      cx: 242.50000722706318,
      cy: 131.5000039190054,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_79"],
    animation: {
      cx: 178.50000531971455,
      cy: 186.50000555813313,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_80"],
    animation: {
      cx: 116.50000347197056,
      cy: 168.50000502169132,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_81"],
    animation: {
      cx: 204.50000609457493,
      cy: 273.5000081509352,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_82"],
    animation: {
      cx: 321.5000095814466,
      cy: 88.50000263750553,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_83"],
    animation: {
      cx: 367.5000109523534,
      cy: 321.50000958144665,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_84"],
    animation: {
      cx: 230.5000068694353,
      cy: 355.5000105947256,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_85"],
    animation: {
      cx: 164.50000490248203,
      cy: 301.5000089854002,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_86"],
    animation: {
      cx: 271.50000809133047,
      cy: 333.5000099390745,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_87"],
    animation: {
      cx: 367.5000109523534,
      cy: 263.50000785291195,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_88"],
    animation: {
      cx: 144.50000430643559,
      cy: 53.50000159442425,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_89"],
    animation: {
      cx: 225.5000067204237,
      cy: 214.50000639259815,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_90"],
    animation: {
      cx: 101.50000302493572,
      cy: 203.5000060647726,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_91"],
    animation: {
      cx: 200.50000597536564,
      cy: 303.50000904500484,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_92"],
    animation: {
      cx: 293.50000874698156,
      cy: 107.50000320374966,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_93"],
    animation: {
      cx: 319.50000952184195,
      cy: 116.50000347197056,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_94"],
    animation: {
      cx: 344.5000102669,
      cy: 299.50000892579556,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_95"],
    animation: {
      cx: 286.5000085383653,
      cy: 247.5000073760748,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_96"],
    animation: {
      cx: 153.5000045746565,
      cy: 243.5000072568655,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_97"],
    animation: {
      cx: 356.5000106245279,
      cy: 193.50000576674938,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_98"],
    animation: {
      cx: 91.5000027269125,
      cy: 86.50000257790089,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_99"],
    animation: {
      cx: 247.5000073760748,
      cy: 197.50000588595867,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_100"],
    animation: {
      cx: 336.5000100284814,
      cy: 178.50000531971455,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_101"],
    animation: {
      cx: 227.50000678002834,
      cy: 285.50000850856304,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_102"],
    animation: {
      cx: 331.5000098794698,
      cy: 335.50000999867916,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_103"],
    animation: {
      cx: 330.5000098496675,
      cy: 308.50000919401646,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_104"],
    animation: {
      cx: 332.50000990927214,
      cy: 242.50000722706318,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_105"],
    animation: {
      cx: 335.5000099986791,
      cy: 136.500004068017,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_106"],
    animation: {
      cx: 219.50000654160976,
      cy: 147.50000439584255,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_107"],
    animation: {
      cx: 326.5000097304582,
      cy: 272.50000812113285,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_108"],
    animation: {
      cx: 181.5000054091215,
      cy: 69.5000020712614,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_109"],
    animation: {
      cx: 310.50000925362104,
      cy: 238.5000071078539,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_110"],
    animation: {
      cx: 255.5000076144933,
      cy: 246.50000734627247,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_111"],
    animation: {
      cx: 85.50000254809856,
      cy: 127.5000037997961,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_112"],
    animation: {
      cx: 305.50000910460943,
      cy: 191.50000570714474,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_113"],
    animation: {
      cx: 314.50000937283033,
      cy: 137.50000409781933,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_114"],
    animation: {
      cx: 294.5000087767839,
      cy: 206.50000615417957,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_115"],
    animation: {
      cx: 288.50000859796995,
      cy: 127.5000037997961,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_116"],
    animation: {
      cx: 284.50000847876066,
      cy: 302.5000090152025,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_117"],
    animation: {
      cx: 122.50000365078449,
      cy: 222.50000663101673,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_118"],
    animation: {
      cx: 292.50000871717924,
      cy: 174.50000520050526,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_119"],
    animation: {
      cx: 293.50000874698156,
      cy: 265.5000079125166,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_120"],
    animation: {
      cx: 148.50000442564487,
      cy: 215.50000642240047,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_121"],
    animation: {
      cx: 253.50000755488867,
      cy: 268.50000800192356,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_122"],
    animation: {
      cx: 278.5000082999467,
      cy: 227.50000678002834,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_123"],
    animation: {
      cx: 88.50000263750553,
      cy: 148.50000442564487,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_124"],
    animation: {
      cx: 292.50000871717924,
      cy: 149.5000044554472,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_125"],
    animation: {
      cx: 174.50000520050526,
      cy: 261.5000077933073,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_126"],
    animation: {
      cx: 163.5000048726797,
      cy: 75.50000225007534,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_127"],
    animation: {
      cx: 270.50000806152815,
      cy: 157.50000469386578,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_128"],
    animation: {
      cx: 209.50000624358654,
      cy: 247.5000073760748,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_129"],
    animation: {
      cx: 115.50000344216824,
      cy: 99.50000296533108,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_130"],
    animation: {
      cx: 241.50000719726086,
      cy: 219.50000654160976,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_131"],
    animation: {
      cx: 268.5000080019235,
      cy: 197.50000588595867,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_132"],
    animation: {
      cx: 207.5000061839819,
      cy: 216.5000064522028,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_133"],
    animation: {
      cx: 109.5000032633543,
      cy: 183.50000546872616,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_134"],
    animation: {
      cx: 237.50000707805157,
      cy: 89.50000266730785,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_135"],
    animation: {
      cx: 266.50000794231886,
      cy: 97.50000290572643,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_136"],
    animation: {
      cx: 257.50000767409796,
      cy: 137.50000409781933,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_137"],
    animation: {
      cx: 129.50000385940075,
      cy: 196.50000585615635,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_138"],
    animation: {
      cx: 234.5000069886446,
      cy: 181.5000054091215,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_139"],
    animation: {
      cx: 130.50000388920307,
      cy: 154.5000046044588,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_140"],
    animation: {
      cx: 156.50000466406345,
      cy: 94.50000281631947,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_141"],
    animation: {
      cx: 227.50000678002834,
      cy: 110.50000329315662,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_142"],
    animation: {
      cx: 107.50000320374966,
      cy: 147.50000439584255,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_143"],
    animation: {
      cx: 241.50000719726086,
      cy: 163.5000048726797,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_144"],
    animation: {
      cx: 152.50000454485416,
      cy: 181.5000054091215,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_145"],
    animation: {
      cx: 107.50000320374966,
      cy: 120.50000359117985,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_146"],
    animation: {
      cx: 211.50000630319118,
      cy: 84.50000251829624,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_147"],
    animation: {
      cx: 248.50000740587706,
      cy: 116.50000347197056,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_148"],
    animation: {
      cx: 182.50000543892384,
      cy: 218.50000651180744,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_149"],
    animation: {
      cx: 169.50000505149364,
      cy: 197.50000588595867,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_150"],
    animation: {
      cx: 141.50000421702862,
      cy: 86.50000257790089,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_151"],
    animation: {
      cx: 233.50000695884228,
      cy: 141.50000421702862,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_152"],
    animation: {
      cx: 136.500004068017,
      cy: 113.50000338256359,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_153"],
    animation: {
      cx: 186.50000555813313,
      cy: 96.50000287592411,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_154"],
    animation: {
      cx: 132.50000394880772,
      cy: 171.5000051110983,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_155"],
    animation: {
      cx: 214.50000639259815,
      cy: 194.5000057965517,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_156"],
    animation: {
      cx: 204.50000609457493,
      cy: 115.50000344216824,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_157"],
    animation: {
      cx: 136.500004068017,
      cy: 135.50000403821468,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_158"],
    animation: {
      cx: 18.500000551342964,
      cy: 176.5000052601099,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_159"],
    animation: {
      cx: 54.50000162422657,
      cy: 185.5000055283308,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_160"],
    animation: {
      cx: 219.50000654160976,
      cy: 126.50000376999378,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_161"],
    animation: {
      cx: 211.50000630319118,
      cy: 167.500004991889,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_162"],
    animation: {
      cx: 190.50000567734241,
      cy: 191.50000570714474,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_163"],
    animation: {
      cx: 169.50000505149364,
      cy: 105.50000314414501,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_164"],
    animation: {
      cx: 203.5000060647726,
      cy: 148.50000442564487,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_165"],
    animation: {
      cx: 172.5000051409006,
      cy: 171.5000051110983,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_166"],
    animation: {
      cx: 172.5000051409006,
      cy: 137.50000409781933,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_167"],
    animation: {
      cx: 155.50000463426113,
      cy: 156.50000466406345,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_168"],
    animation: {
      cx: 185.5000055283308,
      cy: 160.50000478327274,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_169"],
    animation: {
      cx: 182.50000543892384,
      cy: 120.50000359117985,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_170"],
    animation: {
      cx: 159.50000475347042,
      cy: 126.50000376999378,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_171"],
    animation: {
      cx: 152.50000454485416,
      cy: 142.50000424683094,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_172"],
    animation: {
      cx: 170.50000508129597,
      cy: 153.5000045746565,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_173"],
    animation: {
      cx: 191.50000570714474,
      cy: 134.50000400841236,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_174"],
    animation: {
      cx: 182.50000543892384,
      cy: 145.5000043362379,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
  {
    labels: ["anim3_175"],
    animation: {
      cx: 172.5000051409006,
      cy: 137.50000409781933,
      opacity: 1,
      transition: moveCircleTransition({ delay, duration }),
    },
  },
];

export const animations: {
  enterAnimations: {
    labels: string[];
    animation: TargetAndTransition;
  }[];
  exitAnimations?: {
    labels: string[];
    animation: TargetAndTransition;
  }[];
}[][] = [
  [
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            cx: 214.412,
            cy: 335.412,
            transition: { delay: 0.3, duration: 0.4, ease: "backOut" },
          },
        },
      ],
    },
    {
      enterAnimations: phoneDots({ delay: 0.1, duration: 0.85 }),
    },
    {
      enterAnimations: [
        {
          labels: ["anim1_2"],
          animation: {
            cx: 126.5,
            cy: 44.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_3"],
          animation: {
            cx: 302.5,
            cy: 44.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_4"],
          animation: {
            cx: 292.5,
            cy: 37.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_5"],
          animation: {
            cx: 136.5,
            cy: 37.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_6"],
          animation: {
            cx: 306.5,
            cy: 55.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_7"],
          animation: {
            cx: 148.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_8"],
          animation: {
            cx: 122.5,
            cy: 55.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_9"],
          animation: {
            cx: 306.5,
            cy: 67.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_10"],
          animation: {
            cx: 122.5,
            cy: 115.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_11"],
          animation: {
            cx: 306.5,
            cy: 79.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_12"],
          animation: {
            cx: 306.5,
            cy: 127.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_13"],
          animation: {
            cx: 122.5,
            cy: 127.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_14"],
          animation: {
            cx: 306.5,
            cy: 91.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_15"],
          animation: {
            cx: 306.5,
            cy: 103.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_16"],
          animation: {
            cx: 122.5,
            cy: 67.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_17"],
          animation: {
            cx: 306.5,
            cy: 115.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_18"],
          animation: {
            cx: 122.5,
            cy: 79.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_19"],
          animation: {
            cx: 160.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_20"],
          animation: {
            cx: 172.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_21"],
          animation: {
            cx: 208.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_22"],
          animation: {
            cx: 122.5,
            cy: 103.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_23"],
          animation: {
            cx: 220.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_24"],
          animation: {
            cx: 232.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_25"],
          animation: {
            cx: 122.5,
            cy: 331.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_26"],
          animation: {
            cx: 256.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_27"],
          animation: {
            cx: 244.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_28"],
          animation: {
            cx: 306.5,
            cy: 343.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_29"],
          animation: {
            cx: 306.5,
            cy: 151.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_30"],
          animation: {
            cx: 268.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },

        {
          labels: ["anim1_31"],
          animation: {
            cx: 306.5,
            cy: 175.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_32"],
          animation: {
            cx: 122.5,
            cy: 91.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_33"],
          animation: {
            cx: 198.5,
            cy: 63.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_34"],
          animation: {
            cx: 209.5,
            cy: 63.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_35"],
          animation: {
            cx: 122.5,
            cy: 151.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_36"],
          animation: {
            cx: 196.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_37"],
          animation: {
            cx: 231.5,
            cy: 63.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_38"],
          animation: {
            cx: 306.5,
            cy: 211.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_39"],
          animation: {
            cx: 306.5,
            cy: 163.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_40"],
          animation: {
            cx: 306.5,
            cy: 235.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_41"],
          animation: {
            cx: 122.5,
            cy: 163.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_42"],
          animation: {
            cx: 306.5,
            cy: 247.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_43"],
          animation: {
            cx: 122.5,
            cy: 295.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_44"],
          animation: {
            cx: 184.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_45"],
          animation: {
            cx: 122.5,
            cy: 283.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_46"],
          animation: {
            cx: 122.5,
            cy: 139.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_47"],
          animation: {
            cx: 306.5,
            cy: 187.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_48"],
          animation: {
            cx: 306.5,
            cy: 139.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_49"],
          animation: {
            cx: 122.5,
            cy: 223.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_50"],
          animation: {
            cx: 122.5,
            cy: 259.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_51"],
          animation: {
            cx: 306.5,
            cy: 295.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_52"],
          animation: {
            cx: 122.5,
            cy: 271.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_53"],
          animation: {
            cx: 306.5,
            cy: 283.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_54"],
          animation: {
            cx: 122.5,
            cy: 199.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_55"],
          animation: {
            cx: 306.5,
            cy: 319.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_56"],
          animation: {
            cx: 122.5,
            cy: 247.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_57"],
          animation: {
            cx: 306.5,
            cy: 331.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_58"],
          animation: {
            cx: 122.5,
            cy: 355.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_59"],
          animation: {
            cx: 306.5,
            cy: 259.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_60"],
          animation: {
            cx: 122.5,
            cy: 307.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_61"],
          animation: {
            cx: 306.5,
            cy: 223.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },

        {
          labels: ["anim1_62"],
          animation: {
            cx: 122.5,
            cy: 343.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_63"],
          animation: {
            cx: 306.5,
            cy: 271.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_64"],
          animation: {
            cx: 220.5,
            cy: 63.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_65"],
          animation: {
            cx: 122.5,
            cy: 175.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_66"],
          animation: {
            cx: 122.5,
            cy: 235.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_67"],
          animation: {
            cx: 122.5,
            cy: 211.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_68"],
          animation: {
            cx: 122.5,
            cy: 319.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_69"],
          animation: {
            cx: 122.5,
            cy: 187.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_70"],
          animation: {
            cx: 280.5,
            cy: 36.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_71"],
          animation: {
            cx: 126.5,
            cy: 366.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_72"],
          animation: {
            cx: 306.5,
            cy: 199.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_73"],
          animation: {
            cx: 306.5,
            cy: 307.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_74"],
          animation: {
            cx: 136.5,
            cy: 373.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_75"],
          animation: {
            cx: 292.5,
            cy: 373.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_76"],
          animation: {
            cx: 148.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_77"],
          animation: {
            cx: 160.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_78"],
          animation: {
            cx: 196.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_79"],
          animation: {
            cx: 184.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_80"],
          animation: {
            cx: 302.5,
            cy: 366.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_81"],
          animation: {
            cx: 306.5,
            cy: 355.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_82"],
          animation: {
            cx: 280.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_83"],
          animation: {
            cx: 256.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_84"],
          animation: {
            cx: 172.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_85"],
          animation: {
            cx: 244.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_86"],
          animation: {
            cx: 232.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_87"],
          animation: {
            cx: 209.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_88"],
          animation: {
            cx: 220.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
        {
          labels: ["anim1_89"],
          animation: {
            cx: 268.5,
            cy: 374.5,
            transition: moveCircleTransition({ delay: 0.1, duration: 0.625 }),
          },
        },
      ],
    },
    {
      enterAnimations: phoneDots({ delay: 0.1, duration: 0.625 }),
    },
    {
      enterAnimations: [
        {
          labels: phoneLabels,
          animation: {
            cx: 214,
            cy: 335,
            transition: {
              duration: 0.4,
              ease: "easeIn",
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: phoneLabels,
          animation: {
            opacity: 0,
            transition: {
              duration: 0,
            },
          },
        },
      ],
    },
  ],
  [
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            cx: 173,
            cy: 116,
            transition: {
              delay: 0.3,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            r: 27.5,
            transition: {
              duration: 0.4,
              ease: "backOut",
            },
          },
        },
      ],
    },
    {
      enterAnimations: cvDots({ delay: 0.1, duration: 0.85 }),
    },
    {
      enterAnimations: [
        {
          labels: ["anim2_1"],
          animation: {
            cx: 259.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_2"],
          animation: {
            cx: 341.5,
            cy: 50.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_3"],
          animation: {
            cx: 175.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_4"],
          animation: {
            cx: 345.5,
            cy: 109.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_5"],
          animation: {
            cx: 295.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_6"],
          animation: {
            cx: 193.5,
            cy: 264.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_7"],
          animation: {
            cx: 289.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_8"],
          animation: {
            cx: 331.5,
            cy: 379.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_9"],
          animation: {
            cx: 211.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_10"],
          animation: {
            cx: 241.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_11"],
          animation: {
            cx: 127.5,
            cy: 379.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_12"],
          animation: {
            cx: 265.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_13"],
          animation: {
            cx: 345.5,
            cy: 181.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_14"],
          animation: {
            cx: 345.5,
            cy: 205.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_15"],
          animation: {
            cx: 113.5,
            cy: 337.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_16"],
          animation: {
            cx: 345.5,
            cy: 325.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_17"],
          animation: {
            cx: 113.5,
            cy: 361.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_18"],
          animation: {
            cx: 113.5,
            cy: 325.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_19"],
          animation: {
            cx: 307.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_20"],
          animation: {
            cx: 345.5,
            cy: 73.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_21"],
          animation: {
            cx: 345.5,
            cy: 121.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_22"],
          animation: {
            cx: 345.5,
            cy: 289.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_23"],
          animation: {
            cx: 345.5,
            cy: 349.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_24"],
          animation: {
            cx: 113.5,
            cy: 289.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_25"],
          animation: {
            cx: 345.5,
            cy: 337.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_26"],
          animation: {
            cx: 113.5,
            cy: 349.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_27"],
          animation: {
            cx: 345.5,
            cy: 277.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_28"],
          animation: {
            cx: 193.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_29"],
          animation: {
            cx: 151.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_30"],
          animation: {
            cx: 247.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_31"],
          animation: {
            cx: 345.5,
            cy: 97.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_32"],
          animation: {
            cx: 345.5,
            cy: 169.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_33"],
          animation: {
            cx: 145.5,
            cy: 264.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_34"],
          animation: {
            cx: 313.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_35"],
          animation: {
            cx: 229.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_36"],
          animation: {
            cx: 345.5,
            cy: 241.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_37"],
          animation: {
            cx: 113.5,
            cy: 301.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_38"],
          animation: {
            cx: 283.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_39"],
          animation: {
            cx: 345.5,
            cy: 133.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_40"],
          animation: {
            cx: 345.5,
            cy: 217.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_41"],
          animation: {
            cx: 345.5,
            cy: 313.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_42"],
          animation: {
            cx: 283.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_43"],
          animation: {
            cx: 163.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_44"],
          animation: {
            cx: 345.5,
            cy: 361.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_45"],
          animation: {
            cx: 319.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_46"],
          animation: {
            cx: 345.5,
            cy: 157.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_47"],
          animation: {
            cx: 223.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_48"],
          animation: {
            cx: 205.5,
            cy: 264.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_49"],
          animation: {
            cx: 345.5,
            cy: 265.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_50"],
          animation: {
            cx: 117.5,
            cy: 372.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_51"],
          animation: {
            cx: 277.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_52"],
          animation: {
            cx: 345.5,
            cy: 301.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_53"],
          animation: {
            cx: 157.50000469386578,
            cy: 264.50000788271427,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_54"],
          animation: {
            cx: 169.50000505149364,
            cy: 264.50000788271427,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_55"],
          animation: {
            cx: 223.50000666081905,
            cy: 380.50001133978367,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_56"],
          animation: {
            cx: 217.5,
            cy: 264.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_57"],
          animation: {
            cx: 345.5,
            cy: 61.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_58"],
          animation: {
            cx: 253.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_59"],
          animation: {
            cx: 313.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_60"],
          animation: {
            cx: 181.5,
            cy: 264.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_61"],
          animation: {
            cx: 289.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_62"],
          animation: {
            cx: 169.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_63"],
          animation: {
            cx: 139.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_64"],
          animation: {
            cx: 181.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_65"],
          animation: {
            cx: 259.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_66"],
          animation: {
            cx: 217.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_67"],
          animation: {
            cx: 157.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_68"],
          animation: {
            cx: 205.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_69"],
          animation: {
            cx: 127.5,
            cy: 43.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_70"],
          animation: {
            cx: 113.5,
            cy: 121.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_71"],
          animation: {
            cx: 113.50000338256359,
            cy: 169.50000505149364,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_72"],
          animation: {
            cx: 145.5000043362379,
            cy: 223.50000666081905,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_73"],
          animation: {
            cx: 217.50000648200512,
            cy: 182.50000543892384,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_74"],
          animation: {
            cx: 199.50000594556332,
            cy: 380.50001133978367,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_75"],
          animation: {
            cx: 241.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_76"],
          animation: {
            cx: 253.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_77"],
          animation: {
            cx: 187.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_78"],
          animation: {
            cx: 139.50000415742397,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_79"],
          animation: {
            cx: 113.50000338256359,
            cy: 133.50000397861004,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_80"],
          animation: {
            cx: 157.50000469386578,
            cy: 223.50000666081905,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_81"],
          animation: {
            cx: 277.50000827014446,
            cy: 182.50000543892384,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_82"],
          animation: {
            cx: 229.500006839633,
            cy: 182.50000543892384,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_83"],
          animation: {
            cx: 163.5000048726797,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_84"],
          animation: {
            cx: 113.50000338256359,
            cy: 73.5000021904707,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_85"],
          animation: {
            cx: 145.5000043362379,
            cy: 182.50000543892384,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_86"],
          animation: {
            cx: 205.50000612437725,
            cy: 223.50000666081905,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_87"],
          animation: {
            cx: 193.50000576674938,
            cy: 182.50000543892384,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_88"],
          animation: {
            cx: 235.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_89"],
          animation: {
            cx: 113.5,
            cy: 241.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_90"],
          animation: {
            cx: 211.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_91"],
          animation: {
            cx: 271.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_92"],
          animation: {
            cx: 113.5,
            cy: 253.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_93"],
          animation: {
            cx: 113.5,
            cy: 229.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_94"],
          animation: {
            cx: 307.50000916421413,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_95"],
          animation: {
            cx: 345.5000102967024,
            cy: 85.50000254809856,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_96"],
          animation: {
            cx: 235.50000701844692,
            cy: 380.50001133978367,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_97"],
          animation: {
            cx: 271.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_98"],
          animation: {
            cx: 187.5,
            cy: 380.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_99"],
          animation: {
            cx: 345.5,
            cy: 253.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_100"],
          animation: {
            cx: 113.5,
            cy: 313.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_101"],
          animation: {
            cx: 345.5,
            cy: 229.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_102"],
          animation: {
            cx: 113.5,
            cy: 265.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_103"],
          animation: {
            cx: 319.5,
            cy: 42.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_104"],
          animation: {
            cx: 331.5,
            cy: 43.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_105"],
          animation: {
            cx: 345.5,
            cy: 193.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_106"],
          animation: {
            cx: 113.5,
            cy: 277.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_107"],
          animation: {
            cx: 113.5,
            cy: 97.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_108"],
          animation: {
            cx: 117.5,
            cy: 50.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_109"],
          animation: {
            cx: 345.5,
            cy: 145.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_110"],
          animation: {
            cx: 301.5,
            cy: 182.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_111"],
          animation: {
            cx: 341.5,
            cy: 372.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_112"],
          animation: {
            cx: 113.50000338256359,
            cy: 205.50000612437725,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_113"],
          animation: {
            cx: 113.50000338256359,
            cy: 217.50000648200512,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_114"],
          animation: {
            cx: 113.50000338256359,
            cy: 61.50000183284283,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_115"],
          animation: {
            cx: 247.50000737607482,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_116"],
          animation: {
            cx: 301.5,
            cy: 223.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_117"],
          animation: {
            cx: 113.5,
            cy: 157.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_118"],
          animation: {
            cx: 113.5,
            cy: 193.5,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_119"],
          animation: {
            cx: 181.5000054091215,
            cy: 223.50000666081905,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_120"],
          animation: {
            cx: 113.50000338256359,
            cy: 181.5000054091215,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_121"],
          animation: {
            cx: 295.50000880658627,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_122"],
          animation: {
            cx: 113.50000338256359,
            cy: 85.50000254809856,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_123"],
          animation: {
            cx: 113.50000338256359,
            cy: 145.5000043362379,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_124"],
          animation: {
            cx: 169.50000505149364,
            cy: 182.50000543892384,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_125"],
          animation: {
            cx: 199.50000594556332,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_126"],
          animation: {
            cx: 175.50000523030758,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_127"],
          animation: {
            cx: 113.50000338256359,
            cy: 109.5000032633543,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_128"],
          animation: {
            cx: 151.50000451505184,
            cy: 42.5000012665987,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim2_129"],
          animation: {
            cx: 265.5000079125166,
            cy: 223.50000666081905,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
      ],
    },
    {
      enterAnimations: cvDots({ delay: 0.1, duration: 0.625 }),
    },
    {
      enterAnimations: [
        {
          labels: cvLabels,
          animation: {
            cx: 173,
            cy: 116,
            transition: {
              duration: 0.4,
              ease: "easeIn",
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: cvLabels,
          animation: {
            opacity: 0,
            transition: {
              duration: 0,
            },
          },
        },
      ],
    },
  ],
  [
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            cy: 109,
            cx: 173,
            r: 5.5,
            strokeWidth: 0,
            transition: { delay: 0.3, duration: 0.4, ease: "backOut" },
          },
        },
      ],
    },
    {
      enterAnimations: callDots({ delay: 0.1, duration: 0.85 }),
    },
    {
      enterAnimations: [
        {
          labels: ["anim3_1"],
          animation: {
            cx: 154.5000046044588,
            cy: 296.5000088363886,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_2"],
          animation: {
            cx: 364.5000108629465,
            cy: 184.50000549852848,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_3"],
          animation: {
            cx: 68.50000204145907,
            cy: 86.50000257790089,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_4"],
          animation: {
            cx: 234.5000069886446,
            cy: 56.500001683831215,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_5"],
          animation: {
            cx: 321.50000958144665,
            cy: 358.5000106841325,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_6"],
          animation: {
            cx: 214.50000639259815,
            cy: 221.5000066012144,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_7"],
          animation: {
            cx: 165.50000493228436,
            cy: 301.5000089854002,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_8"],
          animation: {
            cx: 179.50000534951687,
            cy: 265.5000079125166,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_9"],
          animation: {
            cx: 185.5000055283308,
            cy: 106.50000317394733,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_10"],
          animation: {
            cx: 175.50000523030758,
            cy: 52.500001564621925,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_11"],
          animation: {
            cx: 257.500007674098,
            cy: 110.50000329315662,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_12"],
          animation: {
            cx: 132.50000394880772,
            cy: 173.50000517070293,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_13"],
          animation: {
            cx: 189.5000056475401,
            cy: 308.50000919401646,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_14"],
          animation: {
            cx: 290.50000865757465,
            cy: 279.5000083297491,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_15"],
          animation: {
            cx: 282.5000084191561,
            cy: 288.50000859797,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_16"],
          animation: {
            cx: 337.5000100582838,
            cy: 116.50000347197056,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_17"],
          animation: {
            cx: 362.50001080334187,
            cy: 172.5000051409006,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_18"],
          animation: {
            cx: 67.50000201165675,
            cy: 241.50000719726086,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_19"],
          animation: {
            cx: 222.50000663101673,
            cy: 183.50000546872616,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_20"],
          animation: {
            cx: 312.50000931322575,
            cy: 90.50000269711018,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_21"],
          animation: {
            cx: 54.500001624226556,
            cy: 133.50000397861004,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_22"],
          animation: {
            cx: 365.50001089274883,
            cy: 316.50000943243504,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_23"],
          animation: {
            cx: 281.50000838935375,
            cy: 70.50000210106373,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_24"],
          animation: {
            cx: 62.500001862645135,
            cy: 97.50000290572643,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_25"],
          animation: {
            cx: 366.50001092255116,
            cy: 196.50000585615635,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_26"],
          animation: {
            cx: 55.50000165402888,
            cy: 145.5000043362379,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_27"],
          animation: {
            cx: 373.5000111311674,
            cy: 280.50000835955143,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_28"],
          animation: {
            cx: 344.50001026690006,
            cy: 126.50000376999378,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_29"],
          animation: {
            cx: 105.500003144145,
            cy: 54.50000162422657,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_30"],
          animation: {
            cx: 329.5000098198652,
            cy: 107.50000320374966,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_31"],
          animation: {
            cx: 165.50000493228436,
            cy: 245.50000731647015,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_32"],
          animation: {
            cx: 363.5000108331442,
            cy: 232.50000692903996,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_33"],
          animation: {
            cx: 247.5000073760748,
            cy: 267.50000797212124,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_34"],
          animation: {
            cx: 238.5000071078539,
            cy: 228.50000680983067,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_35"],
          animation: {
            cx: 213.50000636279583,
            cy: 310.5000092536211,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_36"],
          animation: {
            cx: 210.50000627338886,
            cy: 55.50000165402889,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_37"],
          animation: {
            cx: 64.50000192224978,
            cy: 229.500006839633,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_38"],
          animation: {
            cx: 110.50000329315661,
            cy: 315.5000094026327,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_39"],
          animation: {
            cx: 94.50000281631945,
            cy: 60.500001803040504,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_40"],
          animation: {
            cx: 258.50000770390034,
            cy: 61.50000183284283,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_41"],
          animation: {
            cx: 70.50000210106371,
            cy: 253.50000755488873,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_42"],
          animation: {
            cx: 86.50000257790087,
            cy: 286.50000853836536,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_43"],
          animation: {
            cx: 289.50000862777233,
            cy: 211.50000630319118,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_44"],
          animation: {
            cx: 65.5000019520521,
            cy: 181.5000054091215,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_45"],
          animation: {
            cx: 250.50000746548176,
            cy: 358.5000106841325,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_46"],
          animation: {
            cx: 355.5000105947256,
            cy: 148.50000442564487,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_47"],
          animation: {
            cx: 359.5000107139349,
            cy: 160.50000478327274,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_48"],
          animation: {
            cx: 263.50000785291195,
            cy: 170.50000508129597,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_49"],
          animation: {
            cx: 75.50000225007533,
            cy: 264.50000788271427,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_50"],
          animation: {
            cx: 226.50000675022602,
            cy: 357.5000106543302,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_51"],
          animation: {
            cx: 55.50000165402888,
            cy: 121.50000362098217,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_52"],
          animation: {
            cx: 365.50001089274883,
            cy: 220.5000065714121,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_53"],
          animation: {
            cx: 280.50000835955143,
            cy: 203.5000060647726,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_54"],
          animation: {
            cx: 371.50001107156277,
            cy: 256.5000076442957,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_55"],
          animation: {
            cx: 302.5000090152025,
            cy: 245.50000731647015,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_56"],
          animation: {
            cx: 286.50000853836536,
            cy: 167.500004991889,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_57"],
          animation: {
            cx: 285.50000850856304,
            cy: 364.50001086294645,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_58"],
          animation: {
            cx: 80.50000239908694,
            cy: 275.5000082105398,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_59"],
          animation: {
            cx: 177.50000528991222,
            cy: 164.50000490248203,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_60"],
          animation: {
            cx: 178.50000531971455,
            cy: 213.50000636279583,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_61"],
          animation: {
            cx: 177.50000528991222,
            cy: 305.5000091046095,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_62"],
          animation: {
            cx: 254.50000758469105,
            cy: 162.5000048428774,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_63"],
          animation: {
            cx: 171.5000051110983,
            cy: 256.5000076442957,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_64"],
          animation: {
            cx: 350.500010445714,
            cy: 137.50000409781933,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_65"],
          animation: {
            cx: 129.50000385940075,
            cy: 331.5000098794698,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_66"],
          animation: {
            cx: 366.50001092255116,
            cy: 208.50000621378422,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_67"],
          animation: {
            cx: 342.5000102072954,
            cy: 345.5000102967023,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_68"],
          animation: {
            cx: 221.5000066012144,
            cy: 102.50000305473804,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_69"],
          animation: {
            cx: 193.50000576674938,
            cy: 136.500004068017,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_70"],
          animation: {
            cx: 212.5000063329935,
            cy: 278.5000082999468,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_71"],
          animation: {
            cx: 147.50000439584255,
            cy: 231.50000689923763,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_72"],
          animation: {
            cx: 62.500001862645135,
            cy: 169.50000505149364,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_73"],
          animation: {
            cx: 186.50000555813313,
            cy: 57.50000171363354,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_74"],
          animation: {
            cx: 245.50000731647015,
            cy: 106.50000317394733,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_75"],
          animation: {
            cx: 159.50000475347042,
            cy: 234.5000069886446,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_76"],
          animation: {
            cx: 297.5000088661909,
            cy: 269.5000080317259,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_77"],
          animation: {
            cx: 200.50000597536564,
            cy: 277.50000827014446,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_78"],
          animation: {
            cx: 301.5000089854002,
            cy: 257.500007674098,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_79"],
          animation: {
            cx: 145.5000043362379,
            cy: 194.5000057965517,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_80"],
          animation: {
            cx: 127.50000379979609,
            cy: 256.5000076442957,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_81"],
          animation: {
            cx: 175.50000523030758,
            cy: 352.5000105053186,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_82"],
          animation: {
            cx: 302.5000090152025,
            cy: 83.50000248849392,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_83"],
          animation: {
            cx: 369.5000110119581,
            cy: 304.50000907480717,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_84"],
          animation: {
            cx: 214.50000639259815,
            cy: 357.5000106543302,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_85"],
          animation: {
            cx: 144.50000430643559,
            cy: 288.50000859797,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_86"],
          animation: {
            cx: 332.5000099092722,
            cy: 352.5000105053186,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_87"],
          animation: {
            cx: 367.5000109523535,
            cy: 244.50000728666782,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_88"],
          animation: {
            cx: 128.50000382959843,
            cy: 47.50000141561031,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_89"],
          animation: {
            cx: 202.50000603497028,
            cy: 219.50000654160976,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_90"],
          animation: {
            cx: 64.50000192224978,
            cy: 193.50000576674938,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_91"],
          animation: {
            cx: 162.5000048428774,
            cy: 348.5000103861093,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_92"],
          animation: {
            cx: 270.5000080615282,
            cy: 65.50000195205212,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_93"],
          animation: {
            cx: 292.5000087171793,
            cy: 76.50000227987766,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_94"],
          animation: {
            cx: 372.5000111013651,
            cy: 268.50000800192356,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_95"],
          animation: {
            cx: 261.5000077933073,
            cy: 362.5000108033418,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_96"],
          animation: {
            cx: 93.50000278651713,
            cy: 296.5000088363886,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_97"],
          animation: {
            cx: 291.500008687377,
            cy: 144.50000430643559,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_98"],
          animation: {
            cx: 75.50000225007533,
            cy: 76.50000227987766,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_99"],
          animation: {
            cx: 234.5000069886446,
            cy: 185.5000055283308,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_100"],
          animation: {
            cx: 291.500008687377,
            cy: 156.50000466406345,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_101"],
          animation: {
            cx: 238.5000071078539,
            cy: 355.50001059472555,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_102"],
          animation: {
            cx: 351.5000104755163,
            cy: 336.5000100284814,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_103"],
          animation: {
            cx: 359.5000107139349,
            cy: 327.5000097602605,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_104"],
          animation: {
            cx: 301.5000089854002,
            cy: 233.50000695884228,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_105"],
          animation: {
            cx: 321.50000958144665,
            cy: 98.50000293552876,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_106"],
          animation: {
            cx: 205.50000612437725,
            cy: 133.50000397861004,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_107"],
          animation: {
            cx: 372.5000111013651,
            cy: 292.5000087171793,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_108"],
          animation: {
            cx: 164.50000490248203,
            cy: 49.50000147521496,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_109"],
          animation: {
            cx: 297.5000088661909,
            cy: 221.5000066012144,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_110"],
          animation: {
            cx: 202.50000603497028,
            cy: 357.5000106543302,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_111"],
          animation: {
            cx: 58.500001743435845,
            cy: 109.5000032633543,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_112"],
          animation: {
            cx: 258.50000770390034,
            cy: 192.50000573694706,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_113"],
          animation: {
            cx: 278.5000082999468,
            cy: 124.50000371038914,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_114"],
          animation: {
            cx: 269.5000080317259,
            cy: 197.50000588595867,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_115"],
          animation: {
            cx: 268.50000800192356,
            cy: 116.50000347197056,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_116"],
          animation: {
            cx: 309.5000092238188,
            cy: 362.5000108033418,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_117"],
          animation: {
            cx: 63.50000189244746,
            cy: 217.50000648200512,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_118"],
          animation: {
            cx: 275.5000082105398,
            cy: 172.5000051409006,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_119"],
          animation: {
            cx: 273.5000081509352,
            cy: 364.50001086294645,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_120"],
          animation: {
            cx: 135.50000403821468,
            cy: 234.5000069886446,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_121"],
          animation: {
            cx: 297.5000088661909,
            cy: 364.50001086294645,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_122"],
          animation: {
            cx: 189.5000056475401,
            cy: 355.50001059472555,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_123"],
          animation: {
            cx: 58.500001743435845,
            cy: 157.50000469386578,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_124"],
          animation: {
            cx: 286.50000853836536,
            cy: 133.50000397861004,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_125"],
          animation: {
            cx: 101.50000302493571,
            cy: 306.5000091344118,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_126"],
          animation: {
            cx: 140.5000041872263,
            cy: 45.50000135600567,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_127"],
          animation: {
            cx: 248.5000074058771,
            cy: 151.50000451505184,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_128"],
          animation: {
            cx: 140.5000041872263,
            cy: 338.50001008808607,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_129"],
          animation: {
            cx: 84.50000251829623,
            cy: 68.50000204145908,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_130"],
          animation: {
            cx: 151.50000451505184,
            cy: 343.5000102370977,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_131"],
          animation: {
            cx: 246.50000734627247,
            cy: 188.50000561773777,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_132"],
          animation: {
            cx: 190.50000567734241,
            cy: 216.5000064522028,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_133"],
          animation: {
            cx: 128.50000382959843,
            cy: 244.50000728666782,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_134"],
          animation: {
            cx: 222.50000663101673,
            cy: 55.50000165402889,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_135"],
          animation: {
            cx: 246.50000734627247,
            cy: 58.50000174343586,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_136"],
          animation: {
            cx: 229.500006839633,
            cy: 135.50000403821468,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_137"],
          animation: {
            cx: 63.50000189244746,
            cy: 205.50000612437725,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_138"],
          animation: {
            cx: 210.50000627338886,
            cy: 180.5000053793192,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_139"],
          animation: {
            cx: 136.500004068017,
            cy: 279.5000083297491,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_140"],
          animation: {
            cx: 152.50000454485416,
            cy: 46.50000138580799,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_141"],
          animation: {
            cx: 209.50000624358654,
            cy: 102.50000305473804,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_142"],
          animation: {
            cx: 130.50000388920307,
            cy: 268.50000800192356,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_143"],
          animation: {
            cx: 240.50000716745853,
            cy: 141.50000421702862,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_144"],
          animation: {
            cx: 137.50000409781933,
            cy: 184.50000549852848,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_145"],
          animation: {
            cx: 225.5000067204237,
            cy: 310.5000092536211,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_146"],
          animation: {
            cx: 198.500005915761,
            cy: 56.500001683831215,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_147"],
          animation: {
            cx: 233.50000695884228,
            cy: 103.50000308454037,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_148"],
          animation: {
            cx: 166.50000496208668,
            cy: 208.50000621378422,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_149"],
          animation: {
            cx: 155.50000463426113,
            cy: 202.50000603497028,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_150"],
          animation: {
            cx: 116.50000347197054,
            cy: 50.50000150501728,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_151"],
          animation: {
            cx: 217.50000648200512,
            cy: 133.50000397861004,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_152"],
          animation: {
            cx: 189.5000056475401,
            cy: 272.50000812113285,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_153"],
          animation: {
            cx: 272.50000812113285,
            cy: 295.50000880658627,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_154"],
          animation: {
            cx: 129.50000385940075,
            cy: 161.50000481307507,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_155"],
          animation: {
            cx: 198.500005915761,
            cy: 176.5000052601099,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_156"],
          animation: {
            cx: 197.50000588595867,
            cy: 103.50000308454037,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_157"],
          animation: {
            cx: 201.50000600516796,
            cy: 309.5000092238188,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_158"],
          animation: {
            cx: 248.5000074058771,
            cy: 235.50000701844692,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_159"],
          animation: {
            cx: 187.50000558793545,
            cy: 171.5000051110983,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_160"],
          animation: {
            cx: 119.50000356137751,
            cy: 323.5000096410513,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_161"],
          animation: {
            cx: 237.50000707805157,
            cy: 308.50000919401646,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_162"],
          animation: {
            cx: 182.50000543892384,
            cy: 142.50000424683094,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_163"],
          animation: {
            cx: 226.50000675022602,
            cy: 224.50000669062138,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_164"],
          animation: {
            cx: 236.50000704824924,
            cy: 274.5000081807375,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_165"],
          animation: {
            cx: 130.50000388920307,
            cy: 149.5000044554472,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_166"],
          animation: {
            cx: 175.50000523030758,
            cy: 152.50000454485416,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_167"],
          animation: {
            cx: 173.50000517070293,
            cy: 109.5000032633543,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_168"],
          animation: {
            cx: 161.50000481307507,
            cy: 113.50000338256359,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_169"],
          animation: {
            cx: 141.50000421702862,
            cy: 127.5000037997961,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_170"],
          animation: {
            cx: 134.50000400841236,
            cy: 137.50000409781933,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_171"],
          animation: {
            cx: 254.50000758469105,
            cy: 257.500007674098,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_172"],
          animation: {
            cx: 255.50000761449337,
            cy: 245.50000731647015,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_173"],
          animation: {
            cx: 150.50000448524952,
            cy: 119.50000356137753,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_174"],
          animation: {
            cx: 249.50000743567944,
            cy: 305.5000091046095,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_175"],
          animation: {
            cx: 261.5000077933073,
            cy: 301.5000089854002,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
        {
          labels: ["anim3_176"],
          animation: {
            cx: 224.5000077933073,
            cy: 277.5000089854002,
            opacity: 1,
            transition: {
              delay: 0.1,
              duration: 0.625,
              ease: "backOut",
            },
          },
        },
      ],
    },
    {
      enterAnimations: callDots({ delay: 0.1, duration: 0.625 }),
    },
    {
      enterAnimations: [
        {
          labels: callLabels,
          animation: {
            cx: 173,
            cy: 116,
            transition: {
              duration: 0.4,
              ease: "easeIn",
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: callLabels,
          animation: {
            opacity: 0,
            transition: {
              duration: 0,
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            cx: 208.5,
            cy: 205,
            transition: {
              delay: 0.06,
              duration: 0.4,
            },
          },
        },
      ],
    },
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            r: Math.max(window.innerHeight, window.innerWidth),
            transition: {
              delay: 0.06,
              duration: 1,
              ease: "linear",
            },
          },
        },
      ],
    },
  ],
  [
    {
      enterAnimations: [
        {
          labels: ["main"],
          animation: {
            opacity: 0,
            transition: {
              duration: 0.6,
              delay: 0.1,
              ease: "linear",
            },
          },
        },
      ],
    },
  ],
];
