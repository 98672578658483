import React, { useContext, useRef, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import bg from "@/assets/img/carrer/bg.jpg";
import arrow from "@/assets/icons/arrowRight1.svg";
import { RefsContext } from "@/providers";
import {
  HH,
  CircleButton,
  AnimatedTitle,
  SendResume,
  ConditionalWrapper,
  Marquee,
  AnimatedBlock,
} from "@/components";
import { useMediaQuery, useRefRect } from "@/hooks";

import styles from "./Career.module.scss";

export const Career: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { careerRef } = useContext(RefsContext);
  const circleButtonRef = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const rect = useRefRect(circleButtonRef, [animationComplete]);
  const [isSendResume, setSendResume] = useState(false);
  const matchesMobile = useMediaQuery("mobile");

  return (
    <div className={clsx(styles["career"])} ref={careerRef}>
      <SendResume
        rect={rect}
        opened={isSendResume}
        onClose={() => setSendResume(false)}
      />
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles["career__title"])}>НАША КОМАНДА</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock onAnimationComplete={() => setAnimationComplete(true)}>
            {wrappedChildren}
          </AnimatedBlock>
        )}
      >
        <div className={clsx(styles["career__content"])}>
          <div
            className={clsx(styles["career__text-bg"], styles["animate-text"])}
          >
            <div className={clsx(styles["career__marquee"])}>
              <Marquee rows={matchesMobile ? 4 : 5}>
                <div className={styles["career__marquee-item"]}>
                  УВАЖЕНИЕ&nbsp;РАЗВИТИЕ&nbsp;ОТВЕТСТВЕННОСТЬ&nbsp;УВАЖЕНИЕ&nbsp;РАЗВИТИЕ&nbsp;ОТВЕТСТВЕННОСТЬ&nbsp;УВАЖЕНИЕ&nbsp;РАЗВИТИЕ&nbsp;ОТВЕТСТВЕННОСТЬ&nbsp;
                </div>
              </Marquee>
            </div>
            <div className={clsx(styles["career__circle"])}>
              <img src={bg} className={clsx(styles["career__img"])} alt="" />
              <Link to="/career" className={styles.career__vac}>
                <div>
                  <span>ПОДРОБНЕЕ</span>
                  <img alt="" src={arrow} />
                </div>
              </Link>
            </div>

            <div ref={circleButtonRef} className={styles["career__btn"]}>
              <CircleButton onClick={() => setSendResume(true)}>
                ОТПРАВИТЬ РЕЗЮМЕ
              </CircleButton>
            </div>
          </div>
          <div className={styles["career__btn--hh"]}>
            <HH />
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};
