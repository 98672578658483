import clsx from "clsx";
import { FC } from "react";
import "./Title.scss";

export interface TitleProps {
  className?: string;
  children: React.ReactNode;
}

export const Title: FC<TitleProps> = ({ children, className }) => {
  return <div className={clsx("h2", className)}>{children}</div>;
};
