import { IPersonsBlock } from '../../types/blockTypes';

import styles from './PersonsBlock.module.scss';

interface PersonsBlockProps {
  block: IPersonsBlock;
}

const PersonsBlock = ({ block }: PersonsBlockProps) => {
  const { items } = block.item;

  return (
    <div className={styles['persons-block__container']}>
      {items?.map((p) => (
        <div key={p.id} className={styles['persons-block__container_card']}>
          <img
            src={p.photo}
            alt=""
            className={styles['persons-block__container_card_image']}
          />
          <div className={styles['persons-block__container_card_content']}>
            <p>{p.name}</p>
            <p>{p.post}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PersonsBlock;
