import { IQuoteBlock } from '../../types/blockTypes';

import styles from './QuoteBlock.module.scss';

interface QuoteBlockProps {
  block: IQuoteBlock;
}

const QuoteBlock = ({ block }: QuoteBlockProps) => {
  const { more } = block.item;

  return (
    <div className={styles['quote-block__container']}>
      <p className={styles['quote-block__container_content']}>{more}</p>
    </div>
  );
};

export default QuoteBlock;
