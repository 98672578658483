import { useEffect } from "react";

export const useScrollEnd = (cb: Function, refresh = 66) => {
  useEffect(() => {
    // Setup scrolling variable
    let isScrolling: undefined | ReturnType<typeof setTimeout> = undefined;

    function scrollStop() {
      // Clear our timeout throughout the scroll
      window.clearTimeout(isScrolling);

      // Set a timeout to run after scrolling ends
      isScrolling = setTimeout(() => cb(), refresh);
    }

    window.addEventListener("scroll", scrollStop, false);

    return () => {
      window.removeEventListener("scroll", scrollStop);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
};
