import { createReducer } from "@reduxjs/toolkit";
import { setHeaderMenu } from "./actions";
import { State } from "./interfaces";

const initialState: State = {
  isHeaderOpened: false,
};

export const headerReducer = createReducer(initialState, (builder) => {
  builder.addCase(setHeaderMenu, (state, action) => {
    state.isHeaderOpened = action.payload;
  });
});
