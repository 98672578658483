import React from 'react';
import clsx from 'clsx';
import styles from './Footer.module.scss';
import layoutStyles from '@/layouts/MainLayout/Layout.module.scss';
import { Socials } from './components/Socials';
import arda from '@/assets/icons/arda.svg';
import { useSelector } from '@/store';
import { useLocation } from 'react-router-dom';

export interface FooterProps {
  layout?: boolean;
}

export const Footer: React.FC<FooterProps> = ({ layout = true }) => {
  const { activeSections } = useSelector((state) => state.activeSections);
  const location = useLocation();

  return (
    <footer
      className={clsx(styles.footer, { [layoutStyles.container]: layout })}
    >
      <div className={styles.footer__container}>
        <div className={styles.footer__texts}>
          <div className={styles.footer__copy}>
            © {new Date().getFullYear()} Все права защищены
            <a
              className={styles['footer__copy-arda-mobile']}
              target="_blank"
              rel="noreferrer"
              href="https://arda.digital/"
            >
              <img alt="" src={arda} />
            </a>
          </div>
          <div className={styles.footer__policy}>
            <a target="_blank" href="/backend/storage/policy.pdf">
              Политика обработки персональных данных
            </a>
          </div>
          <a
            className={styles['footer__copy-arda-desktop']}
            target="_blank"
            rel="noreferrer"
            href="https://arda.digital/"
          >
            <img alt="" src={arda} />
          </a>
        </div>
        <div className={styles.footer__list}>
          <Socials white />
        </div>
      </div>
      {activeSections.benefits && location.pathname === '/' && (
        <div className={styles.footer__notes}>
          *Аутстаффинг — направление аутсорсинга ИТ-услуг. ИТ-компания
          подключает одного или нескольких своих специалистов для быстрого
          решения бизнес-задач заказчика.
        </div>
      )}
    </footer>
  );
};
