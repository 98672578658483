import clsx from 'clsx';
import { Review as ReviewType } from '@/store/home/interfaces';
import close from '@/assets/icons/close.svg';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useMediaQuery, useWindowSize } from '@/hooks';

import styles from './Review.module.scss';

export const ReviewSlide: React.FC<{
  review: ReviewType;
  onSelect: (r: ReviewType) => void;
  selected?: boolean;
  clearSelected: () => void;
}> = ({ review, onSelect, clearSelected, selected }) => {
  const sizes = useWindowSize();
  const { name, more, signature, signature_two, logo } = review;
  const ref = useRef<HTMLParagraphElement>(null);
  const [showLink, setShowLink] = useState(false);
  const matchesTablet = useMediaQuery('tablet');

  useLayoutEffect(() => {
    if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
      setShowLink(true);
    }
  }, [ref, sizes]);

  const handleSelect = () => {
    if (!showLink && matchesTablet) {
      return;
    }

    onSelect(review);
  };

  const handleClearSelected = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    clearSelected();
  };

  return (
    <>
      <div
        className={clsx(styles['review-slide'], {
          [styles['review-slide-selected']]: selected,
        })}
        onClick={handleSelect}
      >
        {selected && (
          <div
            className={styles['review-slide__close']}
            onClick={handleClearSelected}
          >
            <img alt="" src={close} />
          </div>
        )}
        <div>
          <span className={clsx(styles['review-slide__title'])}>{name}</span>
          <p
            ref={ref}
            className={clsx(styles['review-slide__content'], {
              [styles['review-slide__content-not-selected']]: !selected,
            })}
          >
            {more}
          </p>
        </div>
        <div className={clsx(styles['review-slide__logo--wrap'])}>
          {logo && (
            <div className={clsx(styles['review-slide__logo'])}>
              <img alt="" src={logo} />
            </div>
          )}
          <div className={clsx(styles['review-slide__surname'])}>
            {signature}
            <span>{signature_two}</span>
          </div>
        </div>
      </div>
    </>
  );
};
