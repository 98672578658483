import clsx from "clsx";
import { forwardRef } from "react";
import { SchemeWork } from "@/store/home/interfaces";

import styles from "./SchemeSlides.module.scss";
import { useJump } from "@/hooks";

interface Props {
  slide: SchemeWork;
}

export const SchemeSlideLast = forwardRef<any, Props>(({ slide }, ref) => {
  const jump = useJump();

  const renderButtonText = (isMobile: boolean) => (
    <span
      className={clsx(styles["slide__radius-text"], {
        [styles["slide__radius-text-mobile"]]: isMobile,
        [styles["slide__radius-text-desktop"]]: !isMobile,
      })}
    >
      ОСТАВИТЬ ЗАЯВКУ
    </span>
  );

  return (
    <div className={clsx(styles.slide, styles.slide_last)} ref={ref}>
      <div className={clsx(styles["slide__item"], styles["slide__item1"])}>
        <span className={clsx(styles["slide__num"])}>{slide.num}</span>
        <h3 className={clsx(styles["slide__title"])}>{slide.name}</h3>
      </div>
      <div className={styles.slide__radius} onClick={() => jump("request")}>
        {renderButtonText(true)}
      </div>
      {renderButtonText(false)}
      <div className={clsx(styles.slide__dots)}>
        <div className={clsx([styles.slide__dot, styles.slide__dot_1])} />
        <div className={clsx([styles.slide__dot, styles.slide__dot_2])} />
        <div className={clsx([styles.slide__dot, styles.slide__dot_3])} />
        <div className={clsx([styles.slide__dot, styles.slide__dot_4])} />
        <div className={clsx([styles.slide__dot, styles.slide__dot_5])} />
      </div>
    </div>
  );
});
