import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from '../../utils/api'
import { GetHomePageInfoResponce } from "./interfaces";

export const getHomePageInfo = createAsyncThunk<GetHomePageInfoResponce, void>('home/getHomePageInfo', async () => {
    const res: GetHomePageInfoResponce = await api.GET('all')
    return res
})

// [
//     {
//         id: 1,
//         title: 'PHP-разработчик',
//         body: '',
//         stack: ['PHP Laravel', 'Symfony', 'Yii2', 'Zend', 'Git', 'SQL', 'Docker', 'JS'],
//         unopeneble: true,
//     },
//     {
//         id: 2,
//         title: 'Frontend разработчик',
//         body: '',
//         stack: ['JavaScript', 'HTML', 'CSS', 'React.js/Angular/Vue.js', 'Bootstrap', 'Webpack'],
//         unopeneble: true,
//     },
//     {
//         id: 3,
//         title: 'Android разработчик',
//         body: '',
//         stack: ['Kotlin', 'Java', 'Android', 'SDK', 'MVP', 'SOA', 'MVVM', 'Git'],
//         unopeneble: true,
//     },
//     {
//         id: 4,
//         title: 'ios разработчик',
//         body: '',
//         stack: ['Swift', 'SOLID', 'MVP', 'Git'],
//         unopeneble: true,
//     }
// ]