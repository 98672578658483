import { useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';
import { CasesSlide } from '@/components';
import { useMediaQuery } from '@/hooks';
import { SwiperWrapper } from '@/components/SwiperWrapper';
import { Case } from '@/store/home/interfaces';

import styles from './CaseDetailsRandomCases.module.scss';

interface CaseDetailsRandomCasesProps {
  cases: Case[];
}

const CaseDetailsRandomCases = ({ cases }: CaseDetailsRandomCasesProps) => {
  const matchesTablet = useMediaQuery('tablet');
  const matchesMobile = useMediaQuery('mobile');

  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    slidesPerView: matchesMobile ? 1 : 1.5,
    spaceBetween: 30,
    initialSlide: 0,
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    loop: cases.length < 2 ? false : true,
    centerInsufficientSlides: true,
  };

  if (!cases.length) return null;

  return (
    <div className={styles['case-details-random-cases']}>
      <h3 className={styles['case-details-random-cases__title']}>
        Другие кейсы
      </h3>
      <div className={styles['case-details-random-cases__slides']}>
        {matchesTablet ? (
          <SwiperWrapper>
            <div className={clsx(styles['slider__container'])}>
              <Swiper {...params} className={clsx(styles['mySwiper'])}>
                {cases.map((c) => (
                  <SwiperSlide key={c.id}>
                    <CasesSlide
                      caseItem={c}
                      className={
                        styles['case-details-random-cases__slides_slide']
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </SwiperWrapper>
        ) : (
          cases.map((c) => (
            <CasesSlide
              key={c.id}
              caseItem={c}
              className={styles['case-details-random-cases__slides_slide']}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default CaseDetailsRandomCases;
