import React, { useState, useContext } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';
import { useSelector } from '@/store';
import arrow from '@/assets/icons/arrowRight1.svg';
import {
  SwiperWrapper,
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
} from '@/components';
import { useMediaQuery } from '@/hooks';
import { RefsContext } from '@/providers';

import styles from './Cases.module.scss';
import { CasesSlide } from './CasesSlide';

export const Cases: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { casesRef } = useContext(RefsContext);
  const { cases } = useSelector((store) => store.home.ru);
  const matchesMobile = useMediaQuery('mobile');
  const matchesDesktop = useMediaQuery('desktop');
  const matchesTablet = useMediaQuery('tablet');
  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    slidesPerView: matchesDesktop
      ? 2.5
      : matchesMobile
      ? 1
      : matchesTablet
      ? 1.5
      : 2.5,

    initialSlide: 0,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    loop: cases.length <= 2 && !matchesTablet ? false : true,
    centerInsufficientSlides: true,
  };

  return (
    <div className={clsx(styles.cases)} ref={casesRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['cases__title'])}>НАШИ КЕЙСЫ</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <SwiperWrapper className={clsx('cases')}>
          <div className={clsx(styles['slider__container'])}>
            {cases.length && (
              <Swiper {...params} className={clsx(styles['mySwiper'])}>
                {cases.map((c) => (
                  <SwiperSlide key={c.id}>
                    <CasesSlide caseItem={c} />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          {cases?.length > 2 && (
            <>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-prev'])}
                onClick={() => swiper?.slidePrev()}
              >
                <img alt="" src={arrow} />
              </div>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-next'])}
                onClick={() => swiper?.slideNext()}
              >
                <img alt="" src={arrow} />
              </div>
            </>
          )}
        </SwiperWrapper>
      </ConditionalWrapper>
    </div>
  );
};
