import { createContext, FC, useRef } from 'react';

interface Props {
  children: React.ReactNode;
}

type RefsContextType = Record<string, any>;

export const RefsContext = createContext<RefsContextType>({});

export const RefsProvider: FC<Props> = ({ children }) => {
  const appRef = useRef<HTMLDivElement>(null);
  const blockScrollNextRef = useRef<HTMLDivElement>(null);
  const blockScrollRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const valuesRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const teamImageRef = useRef<HTMLDivElement>(null);
  const careerRef = useRef<HTMLDivElement>(null);
  const jobsRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);
  const contactsRef = useRef<HTMLDivElement>(null);
  const benefitsRef = useRef<HTMLDivElement>(null);
  const schemeRef = useRef<HTMLDivElement>(null);
  const autsorsRef = useRef<HTMLDivElement>(null);
  const casesRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const certificatesRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);
  const requestProjectRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLImageElement>(null);
  const achievementsRef = useRef<HTMLImageElement>(null);

  return (
    <RefsContext.Provider
      value={{
        appRef,
        headerRef,
        teamRef,
        teamImageRef,
        valuesRef,
        jobsRef,
        topRef,
        aboutRef,
        servicesRef,
        contactsRef,
        benefitsRef,
        schemeRef,
        autsorsRef,
        casesRef,
        reviewsRef,
        certificatesRef,
        faqRef,
        requestProjectRef,
        logoRef,
        careerRef,
        blockScrollRef,
        blockScrollNextRef,
        headerInnerRef,
        achievementsRef,
      }}
    >
      <div ref={appRef}>{children}</div>
    </RefsContext.Provider>
  );
};
