import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag } from '@/store/home/interfaces';

export const FilterButton = ({
  tag,
  route,
  active,
  setActive,
  className,
}: {
  tag: Tag;
  route: string;
  active: boolean;
  setActive: Dispatch<SetStateAction<Record<string, boolean>>>;
  className: string;
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    if (!active) {
      navigate(`/${route}/${tag.slug}`);
    } else {
      navigate(`/${route}/all`);
    }
    setActive((prev) => ({ [tag.slug]: !prev[tag.slug] }));
  };

  return (
    <button
      className={className}
      style={{ color: active ? 'white' : undefined }}
      onClick={onClick}
    >
      #{tag.name}
    </button>
  );
};
