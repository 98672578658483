import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { Blogs, GetAllBlogsResponse } from './interfaces';

export const getAllBlogs = createAsyncThunk<Blogs, void>(
  'blogs/getAllBlogs',
  async () => {
    const res: GetAllBlogsResponse = await api.GET('blogs/all');
    return res.data;
  }
);

export const getBlogsBySlug = createAsyncThunk<Blogs, string>(
  'blogs/getBlogsBySlug',
  async (slug) => {
    const res: GetAllBlogsResponse = await api.GET(`blogs/all?tag=${slug}`);
    return res.data;
  }
);
