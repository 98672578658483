import { createReducer } from '@reduxjs/toolkit';
import { getAllMassMedia } from './actions';
import { MassMedia } from './interfaces';

export const initialState: MassMedia = {
  massMedia: [],
};

export const massMediaReducer = createReducer(initialState, (builder) => {
  builder.addCase(getAllMassMedia.fulfilled, (state, action) => {
    state.massMedia = action.payload;
  });
});
