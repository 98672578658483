import clsx from "clsx";
import { useContext } from "react";
import layoutStyles from "@/layouts/MainLayout/Layout.module.scss";
import { ConditionalWrapper, AnimatedBlock, AnimatedTitle } from "@/components";
import { SchemeSlides } from "./components/Slides/SchemeSlides";
import { RefsContext } from "@/providers";

import styles from "./Scheme.module.scss";

export const Scheme: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { schemeRef } = useContext(RefsContext);

  return (
    <div className={clsx(styles.scheme, "scheme")} ref={schemeRef}>
      <div className={clsx(styles.scheme__container, layoutStyles.container)}>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
          )}
        >
          <h2 className={clsx(styles["scheme__title"])}>СХЕМА РАБОТЫ</h2>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
          )}
        >
          <div className={clsx(styles["slider__container"])}>
            <SchemeSlides />
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  );
};
