import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { BlogState, GetBlogBySlugResponse } from './interfaces';

export const getBlogBySlug = createAsyncThunk<BlogState, string>(
  'blog/getBlogBySlug',
  async (slug) => {
    const res: GetBlogBySlugResponse = await api.GET(`blog/${slug}`);
    return res.data;
  }
);
