import clsx from "clsx";
import React, { FC } from "react";
import { SchemeWork } from "@/store/home/interfaces";

import styles from "./SchemeSlides.module.scss";

interface Props {
  slide: SchemeWork;
  nextSlide: SchemeWork;
}

export const SchemeSlide: FC<Props> = ({ slide, nextSlide }) => {
  return (
    <>
      <div className={styles.slide__before__fake} />
      <div className={clsx(styles["slide"])}>
        <div className={clsx(styles["slide__item"], styles["slide__item1"])}>
          <span className={clsx(styles["slide__num"])}>{slide.num}</span>
          <h3 className={clsx(styles["slide__title"])}>{slide.name}</h3>
        </div>
        <div
          className={clsx(styles["slide__item"], styles["slide__item-center"])}
        ></div>
        <div className={clsx(styles["slide__item"], styles["slide__item2"])}>
          <span className={clsx(styles["slide__num"])}>{nextSlide.num}</span>
          <h3 className={clsx(styles["slide__title"])}>{nextSlide.name}</h3>
        </div>
      </div>
    </>
  );
};
