import { FC } from "react";
import styles from "./HH.module.scss";
import hh from "@/assets/icons/hh.svg";

interface Props {}

export const HH: FC<Props> = () => {
  return (
    <a
      className={styles.hh}
      target="_blank"
      href={process.env.REACT_APP_HH_URL}
      rel="noreferrer"
    >
      <div className={styles.hh__circle}>hh.ru</div>
      <img src={hh} alt="" className={styles.hh__animation} />
    </a>
  );
};
