import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { ActiveSectionsType } from './interfaces';

export const getAllActiveSections = createAsyncThunk<ActiveSectionsType, void>(
  'activeSections/getAllActiveSections',
  async () => {
    return api.GET('active_sections');
  }
);
