import React, { useContext, useState } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';
import { useSelector } from '@/store';
import arrow from '../../assets/icons/arrowRight1.svg';
import {
  SwiperWrapper,
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
} from '@/components';
import { RefsContext } from '@/providers';

import styles from './Certificates.module.scss';
import { useMediaQuery } from '@/hooks';

import { CertificatItem } from '@/store/home/interfaces';
import { SwiperComponent } from './Swiper';
import { PopUp } from './PopUp';

export const Certificates: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const matchesMobile = useMediaQuery('mobile');
  const matchesDesktop = useMediaQuery('desktop');
  const matchesTablet = useMediaQuery('tablet');
  const { certificatesRef } = useContext(RefsContext);
  const { certificat } = useSelector((store) => store.home.ru);
  const [selected, setSelected] = useState<CertificatItem[] | null>(null);
  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    // ContainerEl: 'section',
    // WrapperEl: 'section',
    slidesPerView: matchesMobile
      ? 1
      : matchesDesktop
      ? 3.4
      : matchesTablet
      ? 2
      : 3,

    spaceBetween: 30,
    initialSlide: 0,
    loop: certificat.length <= 3 && !matchesTablet ? false : true,
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    centerInsufficientSlides: true,
  };

  return (
    <div className={clsx(styles.certificates)} ref={certificatesRef}>
      <PopUp opened={!!selected} onClose={() => setSelected(null)}>
        {selected && (
          <SwiperComponent data={selected} onClose={() => setSelected(null)} />
        )}
      </PopUp>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['certificates__title'])}>Сертификаты</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <SwiperWrapper>
          <div className={clsx(styles['slider__container'])}>
            {certificat.length && (
              <Swiper {...params} className={clsx(styles['mySwiper'])}>
                {certificat.map((c) => (
                  <SwiperSlide key={c.id}>
                    <Slide
                      onClick={() =>
                        c.certificates.length
                          ? setSelected(c.certificates)
                          : undefined
                      }
                    >
                      <img src={c.logo} alt="" />
                    </Slide>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          {certificat?.length > 3 && (
            <>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-prev'])}
                onClick={() => swiper?.slidePrev()}
              >
                <img alt="" src={arrow} />
              </div>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-next'])}
                onClick={() => swiper?.slideNext()}
              >
                <img alt="" src={arrow} />
              </div>
            </>
          )}
        </SwiperWrapper>
      </ConditionalWrapper>
    </div>
  );
};

function Slide({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <div className={clsx(styles.certificates__slide)} onClick={onClick}>
      {children}
    </div>
  );
}
