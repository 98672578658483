import clsx from "clsx";
import React from "react";
import styles from "../../Accordion.module.scss";

export const AccordionBody: React.FC<{
  className?: string;
  children: React.ReactNode;
  isShow: boolean;
  list?: string[];
  inlineList?: boolean;
}> = ({ children, isShow, list, inlineList, className }) => {
  return (
    <div
      className={clsx(styles.accordion__body, className, {
        [styles["accordion__body-opened"]]: isShow,
      })}
    >
      {children}
      {list ? (
        <ul
          className={clsx(styles["accordion__body-list"], {
            [styles["accordion__body-list-inline"]]: inlineList,
          })}
        >
          {list.map((l) => (
            <li key={l}>{l}</li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </div>
  );
};
