import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../utils/api';
import { Cases, GetAllCasesResponse } from './interfaces';

export const getAllCases = createAsyncThunk<Cases, void>('cases/getAllCases', async () => {
  const res: GetAllCasesResponse = await api.GET('cases/all');
  return res.data;
});

export const getCasesBySlug = createAsyncThunk<Cases, string>('cases/getCasesBySlug', async (slug) => {
  const res: GetAllCasesResponse = await api.GET(`cases/all?tag=${slug}`);
  return res.data;
});