import React, { useContext, useEffect, useRef, useState } from "react";
import { Mousewheel, Pagination } from "swiper";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import { useSelector } from "@/store";
import headerStyles from "@/layouts/MainLayout/components/Header/Header.module.scss";
import scrollBlockStyles from "@/components/BlockScrollWrapper/BlockScrollWrapper.module.scss";
import styles from "./SchemeSlides.module.scss";
import { useBlockScroll, useOnScreen } from "@/hooks";
import { RefsContext, SwiperInstancesContext } from "@/providers";

import { SchemeSlide } from "./SchemeSlide";
import { SchemeSlideLast } from "./SchemeSlideLast";
import { Animation } from "./Animation";
import { SwiperWrapper } from "@/components/SwiperWrapper";

interface SchemeSlidesProps {}

export const SchemeSlides: React.FC<SchemeSlidesProps> = () => {
  const isBlockScroll = useBlockScroll();
  const { schemaSwiper, setSchemaSwiper } = useContext(SwiperInstancesContext);
  const { blockScrollNextRef, headerRef, schemeRef } = useContext(RefsContext);
  const [swiperIdx, setSwiperIdx] = useState(0);

  const params: SwiperProps = {
    // ContainerEl: 'section',
    // WrapperEl: 'section',
    autoHeight: false,
    slidesPerView: 1,
    spaceBetween: 100,
    pagination: {
      // el: '.swiper-pagination',
      clickable: true,
      // renderBullet: function (index: number, className: string) {
      //     return '<span class="' + className + '">' + (index) + '</span>';
      // },
    },
    mousewheel: {
      // @ts-ignore
      enabled: false,
      releaseOnEdges: false,
      thresholdDelta: 66,
      eventsTarget: "#block_scroll",
    },
    // navigation: {
    //     prevEl: swiperNavPrevRef.current,
    //     nextEl: swiperNavNextRef.current,
    // },
    onSwiper: setSchemaSwiper,
    onSlideChange: (swiper) => setSwiperIdx(swiper.realIndex),
    modules: [Pagination, Mousewheel],
    // onInit: (swiper: any) => {
    //     swiper.params.navigation.prevEl = swiperNavPrevRef.current
    //     swiper.params.navigation.nextEl = swiperNavNextRef.current
    //     swiper.navigation.destroy()
    //     swiper.navigation.init()
    //     swiper.navigation.update()
    // },
  };

  const swiperRef = useRef<HTMLDivElement>(null);
  const ref = useRef(null);
  const isVisible = useOnScreen(ref, [swiperIdx]);
  const swiperIsVisible = useOnScreen(swiperRef, []);

  const { schemeWork } = useSelector((store) => store.home.ru);
  // в апи нету иконок, поэтому стоит иконка телефона

  useEffect(() => {
    if (isBlockScroll && !schemaSwiper?.destroyed) {
      if (swiperIsVisible) {
        schemaSwiper?.mousewheel.enable();
      } else {
        schemaSwiper?.mousewheel.disable();
      }
    }
  }, [schemaSwiper, isBlockScroll, swiperIsVisible]);

  useEffect(() => {
    if (isVisible) {
      if (blockScrollNextRef.current) {
        blockScrollNextRef.current?.classList.add(
          scrollBlockStyles["block__next-revert"]
        );
      }
      schemeRef.current!.style.background = "#80D207";
      headerRef.current!.classList.add(headerStyles.header_green);
    } else {
      if (blockScrollNextRef.current) {
        blockScrollNextRef.current?.classList.remove(
          scrollBlockStyles["block__next-revert"]
        );
      }
      schemeRef.current!.style.background = "#000";
      headerRef.current!.classList.remove(headerStyles.header_green);
    }
  }, [blockScrollNextRef, schemeRef, headerRef, isVisible]);

  return (
    <div ref={swiperRef} className={styles.root}>
      <Animation />

      <SwiperWrapper>
        <Swiper
          {...params}
          className={clsx(styles["mySwiper"], {
            [styles.swiper_opened]: isVisible,
          })}
        >
          {schemeWork.map((slide, index, arr) => (
            <SwiperSlide key={slide.id}>
              {index === schemeWork.length - 1 ? (
                <SchemeSlideLast ref={ref} slide={slide} />
              ) : (
                <SchemeSlide slide={slide} nextSlide={arr[index + 1]} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperWrapper>
    </div>
  );
};
