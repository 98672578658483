import { useContext } from 'react';
import {
  ItOutstaffing,
  Services,
  Benefits,
  Scheme,
  Autsors,
  Cases,
  Review,
  Career,
  Achievements,
  Certificates,
  Faq,
  RequestProject,
  Contacts,
} from '@/components';
import { RefsContext } from '@/providers';
import { ActiveSectionsType } from '@/store/activeSections/interfaces';

import styles from './HomePage.module.scss';

export function useBlocks(activeSections?: ActiveSectionsType) {
  const {
    aboutRef,
    certificatesRef,
    servicesRef,
    benefitsRef,
    schemeRef,
    autsorsRef,
    casesRef,
    reviewsRef,
    faqRef,
    requestProjectRef,
    careerRef,
    contactsRef,
    achievementsRef,
  } = useContext(RefsContext);

  const blocks = [
    {
      name: 'it-outstaffing',
      component: <ItOutstaffing />,
      className: styles.home__outstaffing,
      ref: aboutRef,
      active: true,
    },
    {
      name: 'services',
      component: <Services />,
      className: styles.home__services,
      ref: servicesRef,
      active: true,
    },
    {
      name: 'benefits',
      component: <Benefits />,
      className: styles.home__benefits,
      ref: benefitsRef,
      active: activeSections && !!activeSections.benefits,
    },
    {
      name: 'schema',
      component: <Scheme />,
      className: styles.home__schema,
      ref: schemeRef,
      active: activeSections && !!activeSections.scheme_work,
    },
    {
      name: 'autsors',
      component: <Autsors />,
      className: styles.home__autsors,
      ref: autsorsRef,
      active: activeSections && !!activeSections.autsors,
    },
    {
      name: 'cases',
      component: <Cases />,
      className: styles.home__cases,
      ref: casesRef,
      active: activeSections && !!activeSections.cases,
    },
    {
      name: 'review',
      component: <Review />,
      className: styles.home__review,
      ref: reviewsRef,
      active: true,
    },
    {
      name: 'career',
      component: <Career />,
      className: styles.home__career,
      ref: careerRef,
      active: true,
    },
    {
      name: 'achievements',
      component: <Achievements />,
      className: styles.home__achievements,
      ref: achievementsRef,
      active: true,
    },
    {
      name: 'certificates',
      component: <Certificates />,
      className: styles.home__certificates,
      ref: certificatesRef,
      active: true,
    },
    {
      name: 'faq',
      component: <Faq />,
      className: styles.home__faq,
      ref: faqRef,
      active: true,
    },
    {
      name: 'request',
      component: <RequestProject />,
      className: styles.home__request,
      ref: requestProjectRef,
      active: true,
    },
    {
      name: 'contacts',
      component: <Contacts />,
      className: styles.home__contacts,
      ref: contactsRef,
      active: true,
    },
  ];

  return blocks;
}
