import clsx from "clsx";
import { FC, InputHTMLAttributes } from "react";
import "./Input.scss";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  errorText?: string;
}

export const Input: FC<InputProps> = (props) => {
  const { error, errorText, className, ...rest } = props;

  return (
    <div className={clsx("input-root", className)}>
      <input
        className={clsx("input-root__input", {
          "input-root__input-error": !!error,
        })}
        {...rest}
      />
      {!!error && !!errorText && (
        <div className={clsx("input-root__error")}>{errorText}</div>
      )}
    </div>
  );
};
