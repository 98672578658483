import React, { useContext } from "react";
import clsx from "clsx";
import { useSelector } from "@/store";
import { RefsContext } from "@/providers";
import {
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
  Accordion,
} from "@/components";

import styles from "./Faq.module.scss";

export const Faq: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { faqRef } = useContext(RefsContext);
  const { faq } = useSelector((store) => store.home.ru);

  return (
    <div className={clsx(styles.faq)} ref={faqRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles["faq__title"])}>FAQ</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <Accordion
          data={faq.map((f) => ({ title: f.name, id: f.id, body: f.more }))}
        />
      </ConditionalWrapper>
    </div>
  );
};
