import {
  motion,
  TargetAndTransition,
  useAnimationControls,
} from 'framer-motion';
import { FC, useEffect, useRef, Dispatch, SetStateAction } from 'react';

export type AnimationType = {
  step: number;
  substep: number;
};

interface Props {
  onlyBlock: boolean;
  animation: AnimationType;
  setAnimation: Dispatch<SetStateAction<AnimationType>>;
  clear: () => void;
}

export const Animation: FC<Props> = ({
  onlyBlock,
  animation,
  clear,
  setAnimation,
}) => {
  const initRef = useRef<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const controls = useAnimationControls();

  const animations: {
    enterAnimations: {
      labels: string[];
      animation: TargetAndTransition;
    }[];
    exitAnimations?: {
      labels: string[];
      animation: TargetAndTransition;
    }[];
  }[][] = [
    [
      {
        enterAnimations: [
          {
            labels: ['anim1_1'],
            animation: {
              opacity: 0,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim1_2'],
            animation: {
              cx: 227,
              cy: 119,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim1_3'],
            animation: {
              cx: 268,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim1_4'],
            animation: {
              cx: 227,
              cy: 259,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim1_5'],
            animation: {
              cx: 151,
              cy: 259,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim1_6'],
            animation: {
              cx: 112,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim1_7'],
            animation: {
              cx: 151,
              cy: 119,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: [
              'anim2_1',
              'anim2_2',
              'anim2_3',
              'anim2_4',
              'anim2_5',
              'anim2_6',
            ],
            animation: {
              opacity: 1,
            },
          },
        ],
        exitAnimations: [
          {
            labels: [
              'anim1_1',
              'anim1_2',
              'anim1_3',
              'anim1_4',
              'anim1_5',
              'anim1_6',
              'anim1_7',
            ],
            animation: {
              opacity: 0,
            },
          },
        ],
      },
      {
        enterAnimations: [
          {
            labels: ['anim1_8'],
            animation: {
              cx: 190,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: [
              'anim2_1',
              'anim2_2',
              'anim2_3',
              'anim2_4',
              'anim2_5',
              'anim2_6',
            ],
            animation: {
              opacity: 1,
            },
          },
        ],
        exitAnimations: [
          {
            labels: ['anim1_8'],
            animation: {
              opacity: 0,
            },
          },
        ],
      },
    ],
    [
      {
        enterAnimations: [
          {
            labels: ['anim2_1'],
            animation: {
              cx: 258,
              cy: 150,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim2_2'],
            animation: {
              cx: 258,
              cy: 228,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim2_3'],
            animation: {
              cx: 190,
              cy: 268,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim2_4'],
            animation: {
              cx: 122,
              cy: 228,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim2_5'],
            animation: {
              cx: 122,
              cy: 150,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim2_6'],
            animation: {
              cx: 190,
              cy: 112,
              transition: { duration: 0.5, delay: 1 },
            },
          },
        ],
      },
      {
        enterAnimations: [
          {
            labels: ['anim2_1'],
            animation: {
              cx: 268,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim2_2'],
            animation: {
              cx: 268,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim2_4'],
            animation: {
              cx: 112,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim2_5'],
            animation: {
              cx: 112,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
        ],
      },
      {
        enterAnimations: [
          {
            labels: ['anim2_7'],
            animation: {
              cx: 190,
              cy: 112,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim2_8'],
            animation: {
              cx: 268,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim2_9'],
            animation: {
              cx: 190,
              cy: 268,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim2_10'],
            animation: {
              cx: 112,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
        ],
        exitAnimations: [
          {
            labels: [
              'anim2_1',
              'anim2_2',
              'anim2_3',
              'anim2_4',
              'anim2_5',
              'anim2_6',
            ],
            animation: {
              opacity: 0,
            },
          },
        ],
      },
    ],
    [
      {
        enterAnimations: [
          {
            labels: ['anim3_1'],
            animation: {
              cx: 190,
              cy: 190,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim3_2'],
            animation: {
              cx: 190,
              cy: 190,
              transition: { duration: 0.5, delay: 1 },
            },
          },
          {
            labels: ['anim3_5', 'anim3_6'],
            animation: {
              opacity: 0,
              transition: { duration: 0.5, delay: 1 },
            },
          },
        ],
      },
      {
        enterAnimations: [
          {
            labels: ['anim3_1'],
            animation: {
              cx: 190,
              cy: 34,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim3_2'],
            animation: {
              cx: 267,
              cy: 55,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim3_3'],
            animation: {
              cx: 324,
              cy: 111,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
          {
            labels: ['anim3_4'],
            animation: {
              cx: 346,
              cy: 190,
              transition: { duration: 0.5, delay: 0.5 },
            },
          },
        ],
      },
    ],
  ];

  const runAnimation = async () => {
    const { step, substep } = animation;
    const substeps = animations[step];
    const { enterAnimations, exitAnimations = [] } = substeps[substep];

    // run enter animations of substep
    const enterAnimationsPromises = enterAnimations?.map(
      (enterAnimation: {
        labels: string[];
        animation: Record<string, any>;
      }) => {
        const { labels, animation } = enterAnimation;

        return controls.start((custom) => {
          if (labels.includes(custom)) {
            return animation;
          }
          return {};
        });
      }
    );

    // waiting for enter animations is complete
    await Promise.all(enterAnimationsPromises);

    // run exit animations of substep
    if (exitAnimations) {
      exitAnimations?.forEach(
        (exitAnimation: {
          labels: string[];
          animation: Record<string, any>;
        }) => {
          const { labels, animation } = exitAnimation;

          controls.start((custom) => {
            if (labels.includes(custom)) {
              return animation;
            }
            return {};
          });
        }
      );
    }

    // increment substep or step
    const isNextSupstep = substep < substeps.length - 1;

    if (isNextSupstep) {
      setAnimation((a: AnimationType) => ({
        ...a,
        substep: a.substep + 1,
      }));
    } else if (!onlyBlock) {
      const isNextStep = step < animations.length - 1;

      if (isNextStep) {
        setAnimation((a: AnimationType) => {
          const newStep = a.step + 1;

          return {
            ...a,
            step: newStep,
            substep: 0,
          };
        });
      }
    }
  };

  useEffect(() => {
    if (initRef.current) {
      runAnimation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation]);

  const deinit = () => {
    if (!initRef.current) {
      return;
    }

    initRef.current = false;

    controls.stop();
    clear();
  };

  const init = async () => {
    if (initRef.current) {
      return;
    }

    initRef.current = true;
    runAnimation();
  };

  return (
    <div ref={wrapperRef}>
      <svg
        width="380"
        height="380"
        viewBox="0 0 380 380"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* BACKGROUND CIRCLES */}
        {/* OUTER CIRCLE 1 */}
        <circle cx="190" cy="34" r="34" fill="#1D1D1D" />
        <circle cx="267" cy="55" r="34" fill="#1D1D1D" />
        <circle cx="324" cy="111" r="34" fill="#1D1D1D" />
        <circle cx="346" cy="190" r="34" fill="#1D1D1D" />
        <circle cx="324" cy="270" r="34" fill="#1D1D1D" />
        <circle cx="267" cy="325" r="34" fill="#1D1D1D" />
        <circle cx="190" cy="346" r="34" fill="#1D1D1D" />
        <circle cx="113" cy="325" r="34" fill="#1D1D1D" />
        <circle cx="56" cy="270" r="34" fill="#1D1D1D" />
        <circle cx="34" cy="190" r="34" fill="#1D1D1D" />
        <circle cx="56" cy="111" r="34" fill="#1D1D1D" />
        <circle cx="113" cy="55" r="34" fill="#1D1D1D" />

        {/* INNER CIRCLE */}
        <motion.circle
          cx="227"
          cy="119"
          r="34"
          initial={{
            fill: '#1D1D1D',
            stroke: 'none',
            strokeWidth: 1,
            opacity: 0,
          }}
          custom="anim2_1"
          animate={controls}
        />
        <motion.circle
          cx="268"
          cy="190"
          r="34"
          initial={{
            fill: '#1D1D1D',
            stroke: 'none',
            strokeWidth: 1,
            opacity: 0,
          }}
          custom="anim2_2"
          animate={controls}
        />
        <motion.circle
          cx="227"
          cy="259"
          r="34"
          initial={{
            fill: '#1D1D1D',
            stroke: 'none',
            strokeWidth: 1,
            opacity: 0,
          }}
          custom="anim2_3"
          animate={controls}
        />
        <motion.circle
          cx="151"
          cy="259"
          r="34"
          initial={{
            fill: '#1D1D1D',
            stroke: 'none',
            strokeWidth: 1,
            opacity: 0,
          }}
          custom="anim2_4"
          animate={controls}
        />
        <motion.circle
          cx="112"
          cy="190"
          r="34"
          initial={{
            fill: '#1D1D1D',
            stroke: 'none',
            strokeWidth: 1,
            opacity: 0,
          }}
          custom="anim2_5"
          animate={controls}
        />
        <motion.circle
          cx="151"
          cy="119"
          r="34"
          initial={{
            fill: '#1D1D1D',
            stroke: 'none',
            strokeWidth: 1,
            opacity: 0,
          }}
          custom="anim2_6"
          animate={controls}
        />

        {/* CENTER CIRCLE */}
        <circle cx="190" cy="190" r="34" fill="#80D207" />

        {/* -------------- FIRST BLOCK ---------------*/}
        {animation.step === 0 && (
          <>
            {/* OUTER CIRCLE 1 */}
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 34, opacity: 1 }}
              custom="anim1_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 267, cy: 55, opacity: 1 }}
              custom="anim1_2"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 324, cy: 111, opacity: 1 }}
              custom="anim1_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 346, cy: 190, opacity: 1 }}
              custom="anim1_3"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 324, cy: 270, opacity: 1 }}
              custom="anim1_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 267, cy: 325, opacity: 1 }}
              custom="anim1_4"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 346, opacity: 1 }}
              custom="anim1_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 113, cy: 325, opacity: 1 }}
              custom="anim1_5"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 56, cy: 270, opacity: 1 }}
              custom="anim1_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 34, cy: 190, opacity: 1 }}
              custom="anim1_6"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 56, cy: 111, opacity: 1 }}
              custom="anim1_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 113, cy: 55, opacity: 1 }}
              custom="anim1_7"
              fill="#80D207"
              animate={controls}
            />

            {/* INNER CIRCLE */}
            <motion.circle
              r="34"
              initial={{ cx: 227, cy: 119, opacity: 1 }}
              custom="anim1_8"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 268, cy: 190, opacity: 1 }}
              custom="anim1_8"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 227, cy: 259, opacity: 1 }}
              custom="anim1_8"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 151, cy: 259, opacity: 1 }}
              custom="anim1_8"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 112, cy: 190, opacity: 1 }}
              custom="anim1_8"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 151, cy: 119, opacity: 1 }}
              custom="anim1_8"
              fill="#80D207"
              animate={controls}
            />
          </>
        )}

        {/* -------------- SECOND BLOCK ---------------*/}
        {animation.step === 1 && (
          <>
            {/* BACKGROUND CIRCLE */}
            {/* INNER CIRCLE */}
            <motion.circle
              cx="227"
              cy="119"
              r="34"
              initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
              custom="anim2_1"
              animate={controls}
            />
            <motion.circle
              cx="268"
              cy="190"
              r="34"
              initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
              custom="anim2_2"
              animate={controls}
            />
            <motion.circle
              cx="227"
              cy="259"
              r="34"
              initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
              custom="anim2_3"
              animate={controls}
            />
            <motion.circle
              cx="151"
              cy="259"
              r="34"
              initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
              custom="anim2_4"
              animate={controls}
            />
            <motion.circle
              cx="112"
              cy="190"
              r="34"
              initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
              custom="anim2_5"
              animate={controls}
            />
            <motion.circle
              cx="151"
              cy="119"
              r="34"
              initial={{ fill: '#1D1D1D', stroke: 'none', strokeWidth: 1 }}
              custom="anim2_6"
              animate={controls}
            />

            {/* INNER CIRCLE */}
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 190, opacity: 1 }}
              custom="anim2_7"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 190, opacity: 1 }}
              custom="anim2_8"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 190, opacity: 1 }}
              custom="anim2_9"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 190, opacity: 1 }}
              custom="anim2_10"
              fill="#80D207"
              animate={controls}
            />
          </>
        )}

        {/* -------------- THIRD BLOCK ---------------*/}
        {animation.step === 2 && (
          <>
            {/* BACKGROUND CIRCLE */}
            {/* INNER CIRCLE */}
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 268, opacity: 1 }}
              custom="anim3_6"
              fill="#1D1D1D"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 112, cy: 190, opacity: 1 }}
              custom="anim3_5"
              fill="#1D1D1D"
              animate={controls}
            />

            {/* INNER CIRCLE */}
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 112, opacity: 1 }}
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 268, cy: 190, opacity: 1 }}
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 268, opacity: 1 }}
              custom="anim3_1"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 112, cy: 190, opacity: 1 }}
              custom="anim3_2"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 190, opacity: 1 }}
              custom="anim3_3"
              fill="#80D207"
              animate={controls}
            />
            <motion.circle
              r="34"
              initial={{ cx: 190, cy: 190, opacity: 1 }}
              custom="anim3_4"
              fill="#80D207"
              animate={controls}
            />
          </>
        )}
      </svg>
      <motion.div
        onViewportEnter={init}
        onViewportLeave={deinit}
        viewport={{ margin: '25px' }}
      />
    </div>
  );
};
