import React, { useContext, useState } from "react";
import layoutStyles from "@/layouts/MainLayout/Layout.module.scss";
import dye3 from "../../assets/icons/dye3.svg";
import dye4 from "../../assets/icons/dye4.svg";
import sign from "../../assets/icons/sign.svg";
import pic1 from "../../assets/img/carrer/value1.png";
import pic2 from "../../assets/img/carrer/value2.png";
import pic3 from "../../assets/img/carrer/value3.png";
import { useSelector } from "@/store";
import { RefsContext } from "@/providers";
import {
  Accordion,
  AnimatedBlock,
  AnimatedTitle,
  ConditionalWrapper,
} from "@/components";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./CareerValues.module.scss";

export const CareerValues: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { valuesRef } = useContext(RefsContext);
  const { values } = useSelector((store) => store.home.ru);
  const [hovered, setHovered] = useState(-1);
  const [opened, setOpened] = useState(-1);

  const hover = (id: number) => {
    if (opened > -1 && opened !== id) {
      return;
    }

    setHovered(id);
  };

  const unhover = () => {
    setHovered(-1);
  };

  const open = (id: number) => {
    setOpened(id);
  };

  const close = () => {
    setOpened(-1);
  };

  const shown = opened > -1 ? opened : hovered;

  const images: Record<string, string> = {
    "1": pic1,
    "2": pic2,
    "3": pic3,
  };

  return (
    <div className={styles["career-values"]} ref={valuesRef}>
      <div
        className={clsx(
          styles["career-values__container"],
          layoutStyles.container
        )}
      >
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
          )}
        >
          <h2 className={styles["career-values__title"]}>ЦЕННОСТИ</h2>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
          )}
        >
          <div className={styles["career-values__content"]}>
            <Accordion
              data={values.map((v, i) => ({
                className: styles["career-values__accordion"],
                id: i + 1,
                title: v.name,
                body: (
                  <div className={styles["career-values__accordion-content"]}>
                    {v.more}
                    {/* mobile images */}
                    <img
                      alt=""
                      src={images[shown]}
                      className={clsx(
                        styles["career-values__accordion-content-img"],
                        {
                          [styles["career-values__accordion-content-img-1"]]:
                            shown === 1,
                          [styles["career-values__accordion-content-img-2"]]:
                            shown === 2,
                          [styles["career-values__accordion-content-img-3"]]:
                            shown === 3,
                        }
                      )}
                    />
                  </div>
                ),
              }))}
              handleOpen={open}
              handleClose={close}
              handleHoverEnter={hover}
              handleHoverExit={unhover}
            />
            {/* desktop images */}
            <AnimatePresence>
              {shown > -1 && (
                <motion.img
                  key={`img_${shown}`}
                  alt=""
                  src={images[shown]}
                  className={clsx(styles["career-values__value"], {
                    [styles["career-values__value-1"]]: shown === 1,
                    [styles["career-values__value-2"]]: shown === 2,
                    [styles["career-values__value-3"]]: shown === 3,
                  })}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.5, ease: "easeOut" },
                  }}
                />
              )}
            </AnimatePresence>
            <img alt="" src={sign} className={styles["career-values__sign"]} />
          </div>
        </ConditionalWrapper>
      </div>

      <img alt="" src={dye3} className={styles["career-values__dye-3"]} />
      <img alt="" src={dye4} className={styles["career-values__dye-4"]} />
    </div>
  );
};
