import { FC, useRef } from "react";
import { motion } from "framer-motion";

interface Props {
  children: React.ReactNode;
}

export const AnimatedTitle: FC<Props> = ({ children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={scrollRef}>
      <motion.div
        initial={{ opacity: 0, x: -150 }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.5,
            delay: 0.2,
            easing: "easeInOut",
          },
        }}
        viewport={{ margin: "100px" }}
      >
        {children}
      </motion.div>
    </div>
  );
};
