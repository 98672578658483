import { useMediaQuery } from '@/hooks';
import { ITextBlock } from '../../types/blockTypes';

import styles from './TextBlock.module.scss';

interface TextBlockProps {
  block: ITextBlock;
}

const TextBlock = ({ block }: TextBlockProps) => {
  const { heading, more } = block.item;
  const matchesTablet = useMediaQuery('tablet');
  const matchesMobile = useMediaQuery('mobile');

  return (
    <div className={styles['text-block__container']}>
      {(heading || (!matchesMobile && !matchesTablet)) && (
        <h3 className={styles['text-block__container_title']}>{heading}</h3>
      )}
      <div
        className={styles['text-block__container_content']}
        dangerouslySetInnerHTML={{ __html: more }}
      ></div>
    </div>
  );
};

export default TextBlock;
