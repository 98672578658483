const $body = document.querySelector("body");
let scrollPosition = 0;

export function enableScrollLock() {
  scrollPosition = window.pageYOffset;
  $body!.style.position = "fixed";
  $body!.style.overflowY = "scroll";
  $body!.style.top = `-${scrollPosition}px`;
  $body!.style.width = "100%";
}

export function disableScrollLock() {
  $body!.style.removeProperty("overflowY");
  $body!.style.removeProperty("position");
  $body!.style.removeProperty("top");
  $body!.style.removeProperty("width");
  window.scrollTo(0, scrollPosition);
}
