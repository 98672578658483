import React, { useState, useContext } from 'react';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import clsx from 'clsx';
import { useSelector } from '@/store';
import arrow from '@/assets/icons/arrowRight1.svg';
import {
  SwiperWrapper,
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
} from '@/components';
import { PopUp } from './PopUp';
import { useMediaQuery } from '@/hooks';
import { RefsContext } from '@/providers';

import styles from './Achievements.module.scss';
import { AchievementsSlide } from './AchievementsSlide';
import { Achievements as AchievementsType } from '@/store/home/interfaces';

export const Achievements: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { achievementsRef } = useContext(RefsContext);
  const { achievements } = useSelector((store) => store.home.ru);
  const [selected, setSelected] = useState<AchievementsType | null>(null);
  const matchesMobile = useMediaQuery('mobile');
  const matchesDesktop = useMediaQuery('desktop');
  const matchesTablet = useMediaQuery('tablet');
  const [swiper, setSwiper] = useState<SwiperClass>();

  const params: SwiperProps = {
    slidesPerView: matchesDesktop
      ? 2.5
      : matchesMobile
      ? 1
      : matchesTablet
      ? 1.5
      : 2.5,

    initialSlide: 0,
    spaceBetween: 30,
    pagination: {
      clickable: true,
    },
    modules: [Pagination, Navigation],
    onSwiper: setSwiper,
    loop: achievements.length <= 2 && !matchesTablet ? false : true,
    centerInsufficientSlides: true,
  };

  return (
    <div className={clsx(styles.achievements)} ref={achievementsRef}>
      <PopUp opened={!!selected} onClose={() => setSelected(null)}>
        <div className={clsx(styles['card'])}>
          <img
            src={selected?.img}
            alt=""
            className={clsx(styles['card__image'])}
          />
        </div>
      </PopUp>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['achievements__title'])}>ДОСТИЖЕНИЯ</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <SwiperWrapper className={clsx('achievements')}>
          <div className={clsx(styles['slider__container'])}>
            {achievements.length && (
              <Swiper {...params} className={clsx(styles['mySwiper'])}>
                {achievements.map((a) => (
                  <SwiperSlide key={a.id}>
                    <AchievementsSlide
                      achievement={a}
                      onSelect={() => setSelected(a)}
                      selected={
                        !!selected && selected.id === a.id && matchesMobile
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
          {achievements?.length > 2 && (
            <>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-prev'])}
                onClick={() => swiper?.slidePrev()}
              >
                <img alt="" src={arrow} />
              </div>
              <div
                className={clsx(styles['slider-arrow'], styles['arrow-next'])}
                onClick={() => swiper?.slideNext()}
              >
                <img alt="" src={arrow} />
              </div>
            </>
          )}
        </SwiperWrapper>
      </ConditionalWrapper>
    </div>
  );
};
