import React, { FC } from "react";
import close from "@/assets/icons/close.svg";
import styles from "./Thanks.module.scss";
import { motion, AnimatePresence } from "framer-motion";
import { createPortal } from "react-dom";

interface ThanksProps {
  opened: boolean;
  title: string;
  text: string;
  subtext?: string;
  onClose: () => void;
}

export const ThanksInner: React.FC<ThanksProps> = ({
  opened,
  title,
  text,
  onClose,
  subtext,
}) => {
  return createPortal(
    <motion.div
      className={styles.thanks}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{
        opacity: 0,
        transition: { duration: 0.5, ease: "easeOut" },
      }}
    >
      <div className={styles.thanks__overlay} />
      <div className={styles.thanks__block}>
        <h2>{title}</h2>
        <span>{text}</span>
        {subtext ? (
          <>
            <br />
            <br />
            <span>{subtext}</span>
          </>
        ) : (
          ""
        )}
        <div className={styles.thanks__close} onClick={onClose}>
          <div>
            <img alt="" src={close} />
          </div>
        </div>
      </div>
    </motion.div>,
    document.querySelector("#modal")!
  );
};

export const Thanks: FC<ThanksProps> = ({ opened, ...rest }) => {
  return (
    <AnimatePresence>
      {opened && <ThanksInner opened={opened} {...rest} />}
    </AnimatePresence>
  );
};
