import { FC, useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useAnimationControls, motion } from "framer-motion";
import arrowR from "@/assets/icons/arrowRight1.svg";
import { Team as TeamType } from "@/store/home/interfaces";
import layoutStyles from "@/layouts/MainLayout/Layout.module.scss";
import { RefsContext } from "@/providers";
import { Socials } from "@/layouts";
import { useWindowSize } from "@/hooks";

import styles from "./Carousel.module.scss";
import { PopUp } from "../PopUp";

interface Props {
  photos: TeamType[];
}

export const Carousel: FC<Props> = ({ photos }) => {
  const [selected, setSelected] = useState<TeamType | null>(null);
  const [value, setValue] = useState(0);

  const { teamImageRef, headerInnerRef } = useContext(RefsContext);
  const animInProgressRef = useRef(false);
  const ref = useRef<HTMLDivElement>(null);
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  const beforeAnimationStartedPrevPositionsRef = useRef<{
    left: number;
    top: number;
  }>({ left: 0, top: 0 });
  const beforeAnimationStartedNextPositionsRef = useRef<{
    left: number;
    top: number;
  }>({ left: 0, top: 0 });
  const [old, setOld] = useState(value);
  const prev = value > 0 ? value - 1 : photos.length - 1;
  const next = value === photos.length - 1 ? 0 : value + 1;
  const controls = useAnimationControls();
  const windowSize = useWindowSize();

  const animate = async () => {
    animInProgressRef.current = true;
    const prevRect = prevRef.current!.getBoundingClientRect();
    const nextRect = nextRef.current!.getBoundingClientRect();
    const parentRect = ref.current!.getBoundingClientRect();

    beforeAnimationStartedPrevPositionsRef.current = {
      left: prevRect.left - parentRect.left,
      top: prevRect.top - parentRect.top,
    };

    beforeAnimationStartedNextPositionsRef.current = {
      left: nextRect.left - parentRect.left,
      top: nextRect.top - parentRect.top,
    };

    await controls.start(() => {
      return {
        top: "50%",
        left: "50%",
        x: "-50%",
        y: `calc(-50% - ${headerInnerRef.current!.clientHeight / 2}px)`,
        transition: {
          duration: 0.4,
          ease: "linear",
        },
      };
    });
  };

  const onBullet = async (newValue: number) => {
    if (animInProgressRef.current) {
      return;
    }

    await animate();

    setOld(value);
    setValue(newValue);
  };

  const onNext = async () => {
    if (animInProgressRef.current) {
      return;
    }

    await animate();

    setOld(value);

    if (value === photos.length - 1) {
      setValue(0);
      return;
    }

    setValue(value + 1);
  };

  const onChange = async (newValue: number) => {
    setOld(value);
    setValue(newValue);
  };

  const onPrev = async () => {
    if (animInProgressRef.current) {
      return;
    }

    await animate();

    setOld(value);

    if (value === 0) {
      setValue(photos.length - 1);
      return;
    }

    setValue(value - 1);
  };

  const onSelect = (t: TeamType) => {
    setSelected(t);
  };

  useEffect(() => {
    if (animInProgressRef.current) {
      const animate = async () => {
        await controls.start((custom) => {
          const ref =
            custom === "prev"
              ? beforeAnimationStartedPrevPositionsRef
              : beforeAnimationStartedNextPositionsRef;

          return {
            top: ref.current.top,
            left: ref.current.left,
            x: 0,
            y: 0,
            transition: {
              duration: 1.2,
              ease: "backInOut",
            },
          };
        });

        animInProgressRef.current = false;
      };

      animate();
    }
  }, [value, controls]);

  return (
    <>
      <PopUp
        opened={!!selected}
        initial={value}
        photos={photos}
        onChange={onChange}
        onClose={() => setSelected(null)}
      />
      <div className={styles.carousel} ref={ref}>
        <motion.div
          key={`${windowSize.width}/${windowSize.height}_prev`}
          ref={prevRef}
          animate={controls}
          custom="prev"
          className={clsx(
            styles.carousel__image,
            styles["carousel__image-prev"]
          )}
          style={{ backgroundImage: `url(${photos[prev].files})` }}
        />
        <motion.div
          key={`${windowSize.width}/${windowSize.height}_next`}
          ref={nextRef}
          animate={controls}
          className={clsx(
            styles.carousel__image,
            styles["carousel__image-next"]
          )}
          style={{ backgroundImage: `url(${photos[next].files})` }}
          custom="next"
        />
        <div
          key={`${windowSize.width}/${windowSize.height}_main`}
          ref={teamImageRef}
          className={clsx(
            styles.carousel__image,
            styles["carousel__image-current"]
          )}
          style={{ backgroundImage: `url(${photos[old].files})` }}
        />
        <motion.div
          key={value}
          className={clsx(
            styles.carousel__image,
            styles["carousel__image-current"],
            styles["carousel__image-interactive"]
          )}
          style={{ backgroundImage: `url(${photos[value].files})` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          onClick={() => onSelect(photos[value])}
        />
        <div
          className={clsx(styles["carousel-arrow"], styles["arrow-next"])}
          onClick={onNext}
        >
          <img alt="" src={arrowR} />
        </div>
        <div
          className={clsx(styles["carousel-arrow"], styles["arrow-prev"])}
          onClick={onPrev}
        >
          <img alt="" src={arrowR} />
        </div>
        <div className={clsx(styles.carousel__footer, layoutStyles.container)}>
          <div className={clsx(styles["carousel__footer-pagination"])}>
            {(photos || []).map((photo, index) => (
              <div
                key={photo.id}
                className={clsx(styles["carousel__footer-pagination-bullet"], {
                  [styles["carousel__footer-pagination-bullet-active"]]:
                    value === index,
                })}
                onClick={() => onBullet(index)}
              />
            ))}
          </div>
          <div className={styles["carousel__footer-socials"]}>
            <Socials white />
          </div>
        </div>
      </div>
    </>
  );
};
