import { BlockType } from '@/components/ContentBlocks';
import { renderBlock } from '@/utils';

import styles from './CaseDetailsContent.module.scss';

interface CaseDetailsContentProps {
  blocks?: BlockType[];
}

const CaseDetailsContent = ({ blocks }: CaseDetailsContentProps) => {
  return (
    <div className={styles['case-details-content']}>
      {blocks?.map(renderBlock)}
    </div>
  );
};

export default CaseDetailsContent;
