import { Link } from 'react-router-dom';
import { BlogDetails } from '@/store/blogDetails/interfaces';
import { useMediaQuery } from '@/hooks';
import VkLogo from '@/assets/icons/vk.svg';
import TelegramLogo from '@/assets/icons/telegram.svg';

import styles from './BlogDetailsTop.module.scss';

interface BlogDetailsTopProps {
  data: BlogDetails;
}

const BlogDetailsTop = ({ data }: BlogDetailsTopProps) => {
  const { name, annotation, tags, img_title, date_publication } = data;
  const currentUrl = window.location.href;
  const matchesMobile = useMediaQuery('mobile');

  return (
    <>
      <div
        className={styles['blog-details-top']}
        style={{ backgroundImage: `url(${img_title})` }}
      >
        <div className={styles['blog-details-top__content']}>
          <div className={styles['blog-details-top__content_header']}>
            <p className={styles['blog-details-top__content_header_tags']}>
              {tags.map((t) => (
                <Link
                  key={t.id}
                  to={`/blogs/${t.slug}`}
                  className={
                    styles['blog-details-top__content_header_tags_tag']
                  }
                >
                  #{t.name}{' '}
                </Link>
              ))}
            </p>
            <p className={styles['blog-details-top__content_header_date']}>
              {date_publication}
            </p>
          </div>
          <h3 className={styles['blog-details-top__content_title']}>{name}</h3>
          {!matchesMobile && (
            <p className={styles['blog-details-top__content_description']}>
              {annotation}
            </p>
          )}
        </div>
        <div className={styles['blog-details-top__share']}>
          <a
            href={`https://vk.com/share.php?url=${currentUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={VkLogo} alt="" />
          </a>
          <a
            href={`https://telegram.me/share/url?url=${currentUrl}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={TelegramLogo} alt="" />
          </a>
        </div>
      </div>
      {matchesMobile && <p className={styles['description']}>{annotation}</p>}
    </>
  );
};

export default BlogDetailsTop;
