import { ITextWithBgBlock } from '../../types/blockTypes';

import styles from './TextWithBgBlock.module.scss';

interface TextWithBgBlockProps {
  block: ITextWithBgBlock;
}

const TextWithBgBlock = ({ block }: TextWithBgBlockProps) => {
  const { heading, more, background_image } = block.item;

  return (
    <div className={styles['text-with-bg-block__container']}>
      <img
        src={background_image}
        alt=""
        className={styles['text-with-bg-block__container_bgImage']}
      />
      <div className={styles['text-with-bg-block__container_wrapper']}>
        <h3 className={styles['text-with-bg-block__container_title']}>
          {heading}
        </h3>
        <p className={styles['text-with-bg-block__container_content']}>
          {more}
        </p>
      </div>
    </div>
  );
};

export default TextWithBgBlock;
