import { Link } from 'react-router-dom';
import { CaseDetails } from '@/store/caseDetails/interfaces';
import externalResource from '@/assets/icons/externalResource.svg';
import arrowDiagonal from '@/assets/icons/arrowDiagonalWithoutBorder.svg';

import styles from './CaseDetailsTop.module.scss';

interface CaseDetailsTopProps {
  data: CaseDetails;
}

const CaseDetailsTop = ({ data }: CaseDetailsTopProps) => {
  const { name, more, tags, img_title, url_to } = data;
  return (
    <div
      className={styles['case-details-top']}
      style={{ backgroundImage: `url(${img_title})` }}
    >
      <div className={styles['case-details-top__content']}>
        <p className={styles['case-details-top__content_tags']}>
          {tags.map((t) => (
            <Link
              key={t.id}
              to={`/cases/${t.slug}`}
              className={styles['case-details-top__content_tags_tag']}
            >
              #{t.name}{' '}
            </Link>
          ))}
        </p>
        <h3 className={styles['case-details-top__content_title']}>{name}</h3>
        <p className={styles['case-details-top__content_description']}>
          {more}
        </p>
      </div>
      <div className={styles['case-details-top__ref']}>
        <a
          className={styles['case-details-top__ref__button']}
          target="_blank"
          href={url_to}
          rel="noreferrer"
        >
          <div className={styles['case-details-top__ref__button_circle']}>
            <img src={arrowDiagonal} alt="" />
          </div>
          <img
            src={externalResource}
            alt=""
            className={styles['case-details-top__ref__button_animation']}
          />
        </a>
      </div>
    </div>
  );
};

export default CaseDetailsTop;
