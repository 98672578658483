import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from '@/store';
import { RefsContext, SwiperInstancesContext } from '@/providers';
import { useJump, useMediaQuery } from '@/hooks';
import SwiperClass from 'swiper/types/swiper-class';
import {
  AnimatedBlock,
  AnimatedTitle,
  ConditionalWrapper,
  Accordion,
} from '@/components';

import styles from './Benefits.module.scss';
import { Animation, AnimationType } from './BenefitsAnimation';

export const Benefits: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { benefits } = useSelector((store) => store.home.ru);
  const { benefitsRef } = useContext(RefsContext);
  const matchesTablet = useMediaQuery('tablet');
  const [lastUpdate, setLastUpdate] = useState(Date.now());
  const [block, setBlock] = useState(false);
  const [animation, setAnimation] = useState<AnimationType>({
    step: 0,
    substep: 0,
  });
  const { benefitsSwiper, setBenefitsSwiper } = useContext(
    SwiperInstancesContext
  );
  const jump = useJump();
  const onlyBlock = matchesTablet || block;

  const clear = () => {
    setLastUpdate(Date.now());

    setAnimation((a) => ({
      step: onlyBlock ? a.step : 0,
      substep: 0,
    }));
  };

  const handleOpen = (step: number) => {
    setBlock(true);
    setLastUpdate(Date.now());
    setAnimation({
      step,
      substep: 0,
    });
  };

  useEffect(() => {
    function slideChange(swiper: SwiperClass) {
      if (matchesTablet) {
        setLastUpdate(Date.now());
        setAnimation({
          step: swiper.realIndex,
          substep: 0,
        });
      }
    }

    !benefitsSwiper?.destroyed &&
      benefitsSwiper?.on('slideChange', slideChange);

    return () => {
      !benefitsSwiper?.destroyed &&
        benefitsSwiper?.off('slideChange', slideChange);
    };
  }, [matchesTablet, benefitsSwiper]);

  return (
    <div className={clsx(styles.benefits)} ref={benefitsRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['benefits__title'])}>
          Аутстаффинг
          <span
            className={styles['benefits__title_note']}
            onClick={() => {
              jump('contacts', true);
            }}
          >
            *
          </span>
        </h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <div className={clsx(styles['benefits__block'])}>
          <div className={clsx(styles['benefits__block-icon'])}>
            <Animation
              key={lastUpdate}
              animation={animation}
              setAnimation={setAnimation}
              onlyBlock={onlyBlock}
              clear={clear}
            />
          </div>
          <div className={clsx(styles['benefits__block-list'])}>
            <Accordion
              data={benefits.map((b, i) => ({
                id: i,
                title: b.name,
                body: '',
                list: b.list.map((l) => l.name),
                className: clsx(styles['benefits__block-list-accordion'], {
                  [styles['benefits__block-list-accordion-active']]:
                    animation.step === i,
                }),
              }))}
              handleOpen={handleOpen}
              variant={{
                type: matchesTablet ? 'slider' : 'list',
                meta: {
                  setSwiper: setBenefitsSwiper,
                },
              }}
            />
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};
