import React, { useContext, useRef, useState } from "react";
import { useSelector } from "@/store";
import hh from "@/assets/icons/hhEmail.svg";
import resume from "@/assets/icons/resume.svg";
import { RefsContext } from "@/providers";
import { useBlockScroll, useMediaQuery, useRefRect } from "@/hooks";
import {
  CircleButton,
  SendResume,
  Accordion,
  ConditionalWrapper,
  AnimatedTitle,
  AnimatedBlock,
  Top,
} from "@/components";

import styles from "./Jobs.module.scss";
import { Footer } from "@/layouts";

export const Jobs: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const isScrollBlock = useBlockScroll();
  const matchesTablet = useMediaQuery("tablet");
  const { jobsRef } = useContext(RefsContext);
  const circleButtonRef = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(false);
  const rect = useRefRect(circleButtonRef, [animationComplete]);
  const [isSendResume, setSendResume] = useState(false);
  const { jobs } = useSelector((store) => store.home.ru);

  return (
    <div className={styles.jobs} ref={jobsRef}>
      <SendResume
        rect={rect}
        opened={isSendResume}
        onClose={() => setSendResume(false)}
      />
      <div className={styles.jobs__container}>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
          )}
        >
          <h2 className={styles.jobs__title}>ВАКАНСИИ</h2>
        </ConditionalWrapper>
        <ConditionalWrapper
          condition={!!animate}
          wrap={(wrappedChildren) => (
            <AnimatedBlock
              className={styles["jobs__anim-block"]}
              onAnimationComplete={() => setAnimationComplete(true)}
            >
              {wrappedChildren}
            </AnimatedBlock>
          )}
        >
          <div className={styles.jobs__content}>
            <div>
              <Accordion
                data={jobs.map((j, i) => ({
                  id: i + 1,
                  title: j.name,
                  stack: matchesTablet ? [] : j.list.map((l) => l.name),
                  body: "",
                  unopeneble: true,
                  link: j.url,
                }))}
                lastRow={{
                  title: "Показать все",
                  onClick: () => window.open(process.env.REACT_APP_HH_URL),
                }}
              />
              <div className={styles.jobs__buttons}>
                <div
                  ref={circleButtonRef}
                  className={styles.jobs__resume}
                  onClick={() => setSendResume(true)}
                >
                  <img
                    className={styles["jobs__resume-animation"]}
                    src={resume}
                    alt=""
                  />
                  <CircleButton className={styles["jobs__resume-btn"]}>
                    ОТПРАВИТЬ РЕЗЮМЕ
                  </CircleButton>
                </div>

                <div className={styles.jobs__questions}>
                  <span className={styles.jobs__any}>
                    ОСТАЛИСЬ ВОПРОСЫ? ПИШИТЕ
                  </span>
                  <a className={styles.jobs__hh} href="mailto:hr@evapps.ru">
                    <img alt="" src={hh} />
                  </a>
                </div>
              </div>
            </div>
            {isScrollBlock && (
              <div className={styles.jobs__footer}>
                <Footer />
                <Top className={styles["jobs__footer-top"]} />
              </div>
            )}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  );
};
